import styled from '@emotion/styled';

export const Main = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  .node {
    height: 100vh;
    width: 100%;
  }
`;
