import styled from '@emotion/styled';

export const ImageContainerContainer = styled.div`
  width: 80%;
  height: 13%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-around;

  
  

  .cadastro {
    width: 45%;
    height: 90%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .row {
      width: 90%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      width: 90%;
      height: 50px;
      padding: 10px;
      border: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    select {
      width: 200px;
      height: 50px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: #8080;
      outline: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &:focus {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }
    }

    .mensagem {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-top: 12px;
      cursor: pointer;
      font-size: 18px;
      user-select: none;
    }

    .checkbox-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #eee;
      border-radius: 50%;
    }

    .checkbox-container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    .checkbox-container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .checkbox-container input:checked ~ .checkmark:after {
      display: block;
    }

    .checkbox-container .checkmark:after {
      left: 50%;
      top: 50%;
      width: 3px;
      height: 6px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .gerenciar {
    width: 45%;
    height: 90%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

    .content {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      overflow-y: auto;
      .comunicado {
        width: 80%;
        height: 10%;
        margin: 1rem;
        display: flex;
        box-shadow: 0px 0px 10px 0px #ccc;
        transition: 0.4s;
        cursor: pointer;
        &:hover {
          transform: scale(1.02);
        }
        .main {
          background: #fff;
          width: 80%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 1rem;
        }
        
      }
    }
  }


  .buttons  {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: space-evenly;
 

  svg {
    cursor: pointer;
    color: #303b92;
    transition: 0.4s;
    &:hover {
      transform: scale(1.02);
      color: #000;
    }
  }

}

`;

export const Button = styled.button`
  width: 30%;
  height: 10%;
  border-radius: 5px;
  border: none;
  background-color:#007bff;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
 
  margin-top: 1rem;
  
  &:hover {
    background-color:#0056b3;
  }
`;
