import React, { useEffect, useState } from 'react';
import { AiFillBell, AiOutlinePicture, AiOutlineSearch, AiOutlineUser } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { sidePageAction } from '../../../redux/actions/index.action';
import { RootState } from '../../../redux/stores';
import { getArquivos, getBanners, getCalls, getComunicados, getFotos, getFranqueados, getLogs, getMidia, getNews, getPesquisas, getTreinamentos, getUsuarios, getfaq } from '../../../services/api';
import { ShowCard } from './ShowCard';
import { CardWrapper, TopContainer } from './styles';
import './styles.css';
import { IoCallSharp } from 'react-icons/io5';
import { BsCameraVideo, BsNewspaper, BsPersonWorkspace } from 'react-icons/bs';
import { BiSolidFileArchive } from 'react-icons/bi';
import { MdAnnouncement, MdQuestionAnswer } from 'react-icons/md';
import { HiUserGroup } from 'react-icons/hi';
import { TbReport } from "react-icons/tb";
import { CiCircleList } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

export function AdminHome() {
  const [open, setOpen] = useState(false);
  const [activeCall, setActiveCall] = useState<any>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  const { user } = useSelector((state: RootState) => state.userReducer);

  const { data: logs } = useQuery('logs', () => getLogs().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  // Filtra os logs para contar apenas os de usuários que não têm nível de acesso 'admin'
  const nonAdminLogsCount = logs ? logs.filter((log) => log.usuario?.acesso !== 'admin').length : 0;

  const { data: arquivos } = useQuery('arquivos', () => getArquivos().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: fotos } = useQuery('fotos', () => getFotos().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: Faq } = useQuery('Faq', () => getfaq().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: franqueados } = useQuery('franqueados', () => getFranqueados().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: chamados } = useQuery('chamados', () => getCalls().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: noticias } = useQuery('noticias', () => getNews().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: usuarios } = useQuery('usuarios', () => getUsuarios().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: midias } = useQuery('midias', () => getMidia().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: banners } = useQuery('banners', () => getBanners().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: treinamentos } = useQuery('treinamentos', () => getTreinamentos().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: pesquisas } = useQuery('pesquisas', () => getPesquisas().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { data: comunicados } = useQuery('comunicados', () => getComunicados().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const logoutFunc = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openModal = (call) => {
    setActiveCall(call);
    setOpen(true);
  };

  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);

  return (
    <AdminPageContainer className='admin' padding="0px">
      <TopContainer>
        <div className="right">
          <div className="search">
            <input type="text" placeholder="Pesquise aqui.." />
            <AiOutlineSearch />
          </div>
          <div className="notification">
            {notification > 0 && <div className="badge">{notification}</div>}
            <AiFillBell />
          </div>
        </div>
      </TopContainer>

      <CardWrapper className='dash'>
        <ShowCard title="Atendimentos" number={chamados && chamados.length} icon={<IoCallSharp />} />

        <ShowCard title="Logs" number={nonAdminLogsCount} icon={<CiCircleList onClick={() => navigate('/admin/logspage')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Notícias" number={noticias && noticias.length} icon={<BsNewspaper onClick={() => navigate('/admin/noticias')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Pesquisas" number={pesquisas && pesquisas.length} icon={<AiOutlineSearch onClick={() => navigate('/admin/pesquisas')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Fotos" number={fotos && fotos.length} icon={<AiOutlinePicture onClick={() => navigate('/admin/foto')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="FAQ" number={Faq && Faq.length} icon={<MdQuestionAnswer onClick={() => navigate('/admin/FAQ')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Usuários" number={usuarios && usuarios.length} icon={<AiOutlineUser onClick={() => navigate('/admin/usuarios')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Videos" number={midias && midias.length} icon={<BsCameraVideo onClick={() => navigate('/admin/midias')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Arquivos" number={arquivos && arquivos.length} icon={<BiSolidFileArchive onClick={() => navigate('/admin/arquivo')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Treinamentos" number={treinamentos && treinamentos.length} icon={<BsPersonWorkspace onClick={() => navigate('/admin/treinamentos')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Comunicados" number={comunicados && comunicados.length} icon={<MdAnnouncement onClick={() => navigate('/admin/comunicados')} style={{ cursor: "pointer" }} />} />

        <ShowCard title="Relatórios/Calls" number={chamados && chamados.length} icon={<TbReport onClick={() => navigate('/admin/relatorios')} style={{ cursor: "pointer" }} />} />
      </CardWrapper>
    </AdminPageContainer>
  );
}
