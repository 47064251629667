import { Route, Routes } from 'react-router-dom';

import { MyDocument } from '../components/MyDocument';
import { PrivateRoute } from '../components/PrivateRoute';
import { PrivateRouteAdmin } from '../components/PrivateRouteAdmin';
import { RouteLayout } from '../components/RoutesLayout';
import { BannersPage } from '../pages/admin/banners';
import { AdminChat } from '../pages/admin/chat';
import { ComunicadosAdminPage } from '../pages/admin/comunicados';
import { FranqueadosPage } from '../pages/admin/franqueados';
import { AdminHome } from '../pages/admin/home';
import { LogsPage } from '../pages/admin/logspage';
import { MidiasPage } from '../pages/admin/midias';
import { NoticiasPage } from '../pages/admin/noticias';
import { PesquisasPage } from '../pages/admin/pesquisas';
import { TreinamentosAdminPage } from '../pages/admin/treinamentos';
import { UsuariosPage } from '../pages/admin/usuarios';
import { ArquivosPage } from '../pages/arquivos';
import { ComunicadosPage } from '../pages/comunicados';
import { FAQ } from '../pages/FAQ';
import { Home } from '../pages/home';
import { Login } from '../pages/login';
import NewsPage from '../pages/news';
import { TreinamentosPage } from '../pages/treinamentos';
import VideosPage from '../pages/videos';
import { ArquivosAdminPage } from '../pages/admin/arquivo';
import { FAQAdminPage } from '../pages/admin/Faq';
import { FAQPage } from '../pages/duvidas';
import { FotosAdminPage } from '../pages/admin/foto';
import { FotosPage } from '../pages/fotos';
import { RelatorioPage } from '../pages/admin/relatorios';

export function AppRoutes() {
  return (
    <Routes>
      {/* Rota Login */}
      <Route path="/login" element={<Login />} />

      {/* Rotas usuario comum */}
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/news"
        element={
          <PrivateRoute>
            <NewsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos"
        element={
          <PrivateRoute>
            <VideosPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/iniciar"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/comunicados"
        element={
          <PrivateRoute>
            <ComunicadosPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/treinamentos"
        element={
          <PrivateRoute>
            <TreinamentosPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/arquivos"
        element={
          <PrivateRoute>
            <ArquivosPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/faq"
        element={
          <PrivateRoute>
            <FAQPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/faqs"
        element={
          <PrivateRoute>
            <FAQ />
          </PrivateRoute>
        }
      />

      <Route
        path="/fotos"
        element={
          <PrivateRoute>
            <FotosPage />
          </PrivateRoute>
        }
      />

      <Route path="/pdf/:pdfID" element={<MyDocument />} />

      {/* Rotas admin */}
      <Route
        path="/admin"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <AdminHome />
            </RouteLayout>
          </PrivateRouteAdmin>
        }


      />


      <Route
        path="/admin/relatorios"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <RelatorioPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }


      />


      <Route
        path="/admin/news"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <NewsPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />

      <Route
        path="/admin/logspage"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <LogsPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/chat"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <AdminChat />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />

      <Route
        path="/admin/arquivo"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <ArquivosAdminPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />

      <Route
        path="/admin/foto"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <FotosAdminPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />

      <Route
        path="/admin/Faq"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <FAQAdminPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />

      <Route
        path="/admin/banners"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <BannersPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/franqueados"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <FranqueadosPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/treinamentos"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <TreinamentosAdminPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/midias"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <MidiasPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/pesquisas"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <PesquisasPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/noticias"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <NoticiasPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/comunicados"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <ComunicadosAdminPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/usuarios"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <UsuariosPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />
      <Route
        path="/admin/logs"
        element={
          <PrivateRouteAdmin>
            <RouteLayout>
              <LogsPage />
            </RouteLayout>
          </PrivateRouteAdmin>
        }
      />

      {/* Rota notfound */}
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
