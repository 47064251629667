import React from 'react';
import { format } from 'date-fns';
import { ArquivosContainer } from './styles';

interface ArquivosProps {
  title: string;
  qntd: any;
  date: string;

}


function formatData(data) {
  const formattedDate = format(new Date(data), 'dd/MM');
  return formattedDate;
}




export function Arquivos({ title, date, qntd }: ArquivosProps) {
  return (
    <ArquivosContainer>
      <div className="card-news">
        <h3 className='pasta'>PASTA</h3> <h3 className='data'>DATA</h3>  {/*<h3 className='qntd'>QNTD</h3> */}



        {/* */}
        <p className='arquivos' ></p>

        <p className='datas' >{formatData(date)}</p>

        <p className='title-' >{title}</p>






      </div>









    </ArquivosContainer>
  );
}
