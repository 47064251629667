import styled from '@emotion/styled';

export const ChatWindowContainer = styled.div<{ author?: boolean }>`
  width: 100%;
  height: 100%;
  flex-direction: column;

  .title-container {
    background: #303b92;
    width: 100%;
    height: 10%;
    text-align: center;
    color: #fff;
  }
  .chat-container {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .input-container {
    width: 100%;
    height: 10%;
    display: flex;
    border: 0.5px solid #303b92;
  }
 
`;
