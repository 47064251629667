import React, { useState, useEffect } from 'react';
import { Navbar } from '../../components/Navbar';
import { PageContainer } from './styles';
import './styles.css';
import { useQuery } from 'react-query';
import { getNoticias } from '../../services/api';
import { Infos } from '../../infos';
import { format } from 'date-fns';
import ReactQuill from 'react-quill';
import { ChatComponent } from '../../components/Chat';
import socket from '../../services/socket';
import { RootState } from '../../redux/stores';
import { useSelector } from 'react-redux';

interface NoticiaItem {
  id: number;
  imageURL: string;
  titulo: string;
  data: string;
  conteudo: string;
}



export default function NewsPage() {
  const { data: noticias } = useQuery('noticias', async () => {
    const response = await getNoticias();
    const data = response?.data;

    if (data) {
      data.sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime());
    }

    return data;
  }, {
    refetchOnWindowFocus: false,
  });

  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<NoticiaItem[]>([]);
  const [selectedNoticia, setSelectedNoticia] = useState<NoticiaItem | null>(null);
  

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);

  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlight})`, 'gi'); 
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white;font-size:25px">${match}</span>`);
};

useEffect(() => {

  window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
}, []);


  useEffect(() => {
    if (noticias && noticias.length > 0) {
      const filteredResults = noticias.filter((noticia) =>
        noticia.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        noticia.conteudo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [noticias, searchTerm]);

  const openPopup = (noticia: NoticiaItem) => {
    setSelectedNoticia(noticia);
  };

  const closePopup = () => {
    setSelectedNoticia(null);
    window.scrollTo({
      top: 1 ,
      behavior: 'smooth'
    });
  };




  
  return (
    <PageContainer>
      <Navbar />
      <ChatComponent />
      <div className="title">
        <h1>Notícias</h1>
      </div>

      <section>
        <input
          type="text"
          className="Search"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchInput}
          style={{ border: '2px solid #c0c0c0' }}
        />
        <div className="content">
          {searchResults.length > 0 ? (
            searchResults.map((noticia) => (
              <div className="card" key={noticia.id}>
                <div className="box" />
                <div key={noticia.id} className="">
                  <img src={noticia.imageURL} alt="" />
                  <h2 dangerouslySetInnerHTML={{__html:highlightText(noticia.titulo,searchTerm)}}/>
                  <p>{format(new Date(noticia.data), 'dd/MM/yyyy')}</p>
                  <button id="read" onClick={() => openPopup(noticia)}>
                  Leia a notícia na íntegra
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="content">
              <div className="title-2">
                <h1>Não há notícias no momento</h1>
              </div>
            </div>
          )}
        </div>
        {selectedNoticia && (
          <div className='pop-up-containerr'  >
            <div className="pop-upp" >
              <div className="popup-contentt" >
              <h2 className='titulo-popup'>{selectedNoticia.titulo} </h2>
              {/* <h5 className='data-titulo'>{format(new Date(selectedNoticia.data),'dd/MM/yyyy')}</h5>*/}
                <img  className="img-popup"src={selectedNoticia.imageURL}  />
                
                <ReactQuill
  className='texto-popup'
  style={{ height: '300px', width: '99.8%' }}
  value={selectedNoticia.conteudo}
  readOnly={true}
  modules={{ toolbar: false }}
  formats={[
    'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
    'blockquote', 'list', 'bullet', 'indent', 'color', 'link', 'image'
  ]}
/>

              </div>
              <button onClick={closePopup}>Fechar</button>
            </div>
          </div>
        )}
      </section>

      <footer>
        <Infos />
        <p className='p'>© 2023 Óticas Mercadótica. Todos os direitos reservados</p>
      </footer>
    </PageContainer>
  );
}






