import styled from '@emotion/styled';

export const PageContainer = styled.div`
  background-image: url('assets/banner\ 6.6.jpg');
 background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 14%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
    width: 100%;
    
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
        margin-top: 2%;
        
   .card {
  width: calc(28.30% - 8px);
  height: auto;
  margin: 1rem;
  display: flex;
  box-shadow: 10px 10px 1rem #ccc;
  transition: 0.3s;
  background-color: #303b92;
  position: relative; 
    box-sizing: border-box;
 flex-direction: column;
 
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
  }



      .arquivos {
        padding: 0.5rem;
        p {
          color: rgba(240, 248, 255, 0.616);
          font-size: 20px;
          font-family: 'Poppins', sans-serif;
         
        }
            h2{
          color: white;
          font-size: 25px;
          font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        h5{
          color: white;
          font-size: 10px;
          font-family: 'Poppins',sans-serif;
        }

        span{
          color: white;
          font-size: 10px;
        }

      }
    }



    
      #read{
      
         width: 100%;
        border: none;
        background-color: white;
        font-size: 15px;
        font-family: bold;
       color:#303b92;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        
      }

       #read:hover{
       background-color: #303b92;
       color: white;
       transition: all 0.2s ease-in-out;
      }
            

      .icon-arq{
        color: yellow;
        display: flex;
        font-size: 25px;
        margin-top: 5%;
        flex-direction: column;
      
       
      }
      
      .icon-arq2{
        display: flex;
        margin-left: 12%;
        margin-top: -11%;
        margin-bottom: 10%;
        font-size: 20px;
          &:hover{
            color:grey;
          }
      }

      .name-arq{
        color: yellow;
        text-decoration: none;
        &:hover{
            color:#808000;
          }
          font-size: 15px;
       
        
        
      }
    

 

  }

  .Search{
        font-size: 17px;
  margin-top: 14%;
  margin-left: 40%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      }

 
      .content{
      
      margin-bottom:200%
      }
`;
