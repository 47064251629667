import { NOTIFICATION } from '../actions/actions.type';

const INITIAL_STATE = {
  notification: 0,
};

function notificationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
}

export default notificationReducer;
