import styled from '@emotion/styled';

export const PageContainer = styled.div`

background-image: url('assets/banner\ 0.jpg ');
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;

background-image: url('assets/banner\ 9.jpg ');

  width: 100%;
 min-height: 100vh;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 13%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4%;
    transition: 0.3s;
     &:hover {
        transform: scale(1.0);
      }
    .card {
      
      width: calc(28.30% - 8px);
      height: 100%;
      margin: 1rem;
      display: flex;
      box-shadow: 10px 10px 1rem #ccc;
      transition: 0.3s;
    
      background-color: ghostwhite;
      justify-content: center;
        position: relative; 
      
      border-radius: 6px;
      overflow: hidden;
       &:hover {
        transform: scale(1.2);
      }
     
      .box {
        box-sizing: border-box;
        width: 40%;
        height: 100%;
        background-color: #303b92;
      }

      .comunicado {
        padding: 0.5rem;
        p {
          color: #303b92;
          font-size: 20px;
          font-family: 'Poppins', sans-serif;
         
        }
        h2{
          color: #303b92;
          font-size: 25px;
          font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        h5{
          color: #303b92;
          font-size: 10px;
          font-family: 'Poppins',sans-serif;
        }

       
       
        

      }
    }

     
  

      #read{
        
         width: 99%;
        border: none;
        background-color:#303b92 ;
        font-size: 15px;
        font-family: bold;
       color:white;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

       #read:hover{
       background-color: white;
       color: #303b92;
       transition: all 0.2s ease-in-out;
      }

      
    }

    .Search{
        font-size: 17px;
  margin-top: 10%;
  margin-left: 40%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);


      }
    
`;
