import axios, { AxiosInstance } from 'axios';
import { ambient } from '../constants/ambient';

const basePath = ambient === "production" ? 'https://intranet-backend-mercadotica.herokuapp.com/' : 'http://localhost:5000';

export const client: AxiosInstance = axios.create({
  baseURL: basePath,
  headers: {
    'Content-Type': 'application/json',
  },
});
