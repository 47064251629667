import React from 'react';

interface ScrollLinkProps {
  to: string;
  children: React.ReactNode;
}

export const ScrollSmooth: React.FC<ScrollLinkProps> = ({ to, children }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    event.preventDefault();
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <a href={`#${to}`} onClick={(event) => handleClick(event, to)} style={{textDecoration: 'none'}}>
      {children}
    </a>
  );
};