import styled from '@emotion/styled';

export const PageContainer = styled.div`
  background-image: url('assets/banner\ 8.jpg');
 background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 14%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
    width: 100%;
   flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
        margin-top: 4%;
        margin-bottom: 200%;
    .card {
     width: calc(28.30% - 8px);
      display: flex;
    
      height: auto;
      margin: 4rem 0.5rem;
      display: flex;
      box-shadow: 10px 10px 1rem #ccc;
      transition: 0.3s;
      background-color: ghostwhite;
      flex-direction: column;
  
  border-radius: 8px;
  overflow: hidden;



      
      &:hover {
        transform: scale(1.01);
      }
  
      .box {
        box-sizing: border-box;
        width: 40%;
        height: 100%;
        background-color: #ccc;
      }

      .arquivos {
        padding: 0.5rem;
        p {
          color: rgba(240, 248, 255, 0.616);
          font-size: 20px;
          font-family: 'Poppins', sans-serif;
         
        }
            h2{
          color: #303b92;
          font-size: 25px;
          font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        h5{
          color: #303b92;
          font-size: 10px;
          font-family: 'Poppins',sans-serif;
        }

        span{
          color: #303b92;
          font-size: 10px;
        }

      }
    }
    @media (max-width: 1366px) {
    
    .img-capa{
        width: 365px;
        height: 370px;
        object-fit: cover;
    }
}

@media (min-width: 1367px) and (max-width: 1921px) {
    .img-capa{
        width: 600px;
        height: 460px;
        object-fit: cover;
    }



}

    @media (max-width: 1366px) {
    
    .img-capa{
        width: 365px;
        height: 370px;
        object-fit: cover;
    }
}

@media (min-width: 1367px) and (max-width: 1921px) {
    .img-capa{
        width: 600px;
        height: 460px;
        object-fit: cover;
    }



}

    img{
        width: 100%;
        height: 100%;

           &:hover {
            cursor: pointer;
        transform: scale(1.00);
   
      }
        
      }

    
      #read{
      
         width: 100%;
         height: 50%;
        border: none;
        background-color: #303b92;
        font-size: 15px;
        font-family: bold;
       color:white;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

       #read:hover{
       background-color:white;
       color: #303b92;
       transition: all 0.2s ease-in-out;
      }
            

      .icon-arq{
        color: white;
        display: flex;
        font-size: 60px;
        margin-top: 5%;
        flex-direction: column;
       
      }
      
      .icon-arq2{
        display: flex;
        margin-left: 12%;
        margin-top: -11%;
        margin-bottom: 10%;
        font-size: 20px;
          &:hover{
            color:grey;
          }
      }

      .name-arq{
        color: white;
        text-decoration: none;
       
        
        
      }
  

  }

  .Search{
        font-size: 17px;
  margin-top: 14%;
  margin-left: 40%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);


      }


  
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background-color: #fff;
    width: 50%;
    height: 20rem;
    border-radius: 5px;
  }
`;
