import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { CustomButton } from '../../../components/CustomButton';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getMidia } from '../../../services/api';
import { storage } from '../../../services/firebase';
import { client } from '../../../services/instance';
import { ImageContainer } from './styles';
import './styles.css';
import { AiFillFolderOpen } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';


// Componente para lidar com o upload de imagens
function ImageUpload({ onImageUpload }) {
  const [progress, setProgress] = useState(0);

  const handleImage = (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) return;
    const storageRef = ref(storage, `midia/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const newProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(newProgress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          onImageUpload(url);
        });
      }
    );
  };

  return (
    <div className="input-group-2">
      <label htmlFor="midia">Thumbnail:</label>
      <input type="file" name="midia" onChange={handleImage} style={{ width: '200%' }} />
      <h5>(apenas fotos com resoluções entre 1024x1024 e 1080x1080 )</h5>
      {progress > 0 && <progress value={progress} max="100" />}
    </div>
  );
}   




interface Midias {
  id: number,
  titulo: string,
  link: string,
  categoria: string,
  status: string,
  data: Date,
  thumbnail: string[],
  conteudo: string,
  nomeOriginalArquivo: string[],
}



export function MidiasPage() {
  const [values, setValues] = useState({
    id: 0,
    titulo: '',
    link: '',
    categoria: '',
    status: 'ativo',
    data: new Date(),
    thumbnail: [''],
    conteudo: '',
    nomeOriginalArquivo: [''],
  });
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState(['']);
  const [editedItem, setEditedItem] = useState<Midias | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { notification } = useSelector((state: RootState) => state.notificationReducer)


  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Midias | null>(null);





  const { data: midia, refetch } = useQuery('midia', () =>
    getMidia().then((response) => response?.data),
    {
      refetchOnWindowFocus: false,
    }
  );


  // Ordenar os vídeos por data, do mais recente para o mais antigo
  const sortedMidias = midia
    ? [...midia].sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
    : [];


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleDateChange = (date) => {
    setValues({ ...values, data: date });
  };

  const onImageUpload = (url) => {
    setImageUrl(url);
  };

  const handleFileUploadProgress = (snapshot: any) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress);
  };


  const handleImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...values.thumbnail];
    const updatedNomeOriginalArquivo = [...values.nomeOriginalArquivo];

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `midia/${values.titulo}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));
      setValues({
        ...values,
        thumbnail: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

    } catch (error) {
      alert(error);
    }
  };


  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);

  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = values;
    data.thumbnail = imageUrl;





    client
      .post('/midia/create', {
        ...values,
        thumbnail: imageUrl,
        data,
      })
      .then((res) => {
        alert('Video cadastrado com sucesso!');
        refetch();
      });
  };

  const onDelete = (id: number) => {
    client.delete(`/midia/delete/${id}`).then((res) => {
      console.log(res);
      alert('Mídia deletada com sucesso!');
      refetch();
    });
  };


  // Novo método para excluir um arquivo da pasta selecionada
  const deleteFile = (indexToDelete: number) => {
    if (selectedFolder) {
      const updatedUrlArquivo = [...selectedFolder.thumbnail];
      const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];

      // Remova o arquivo da lista com base no índice
      updatedUrlArquivo.splice(indexToDelete, 1);
      updatedNomeOriginalArquivo.splice(indexToDelete, 1);

      // Atualize o estado da pasta selecionada com as listas atualizadas
      setSelectedFolder({
        ...selectedFolder,
        thumbnail: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });


    }
  };

  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        titulo: values.titulo,
        link: values.link,
        categoria: values.categoria,
        status: values.status = 'true' ? 'ativo' : 'inativo',
        data: values.data,
        thumbnail: values.thumbnail,
        conteudo: values.conteudo,
        nomeOriginalArquivo: selectedFolder.nomeOriginalArquivo,
      };




      try {

        const data = values;
        data.thumbnail = imageUrl;


        editedData.thumbnail = imageUrl;
        const response = await client.put(`/midia/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };


  const handleEdit = (midia: Midias) => {
    setIsEditing(true);
    setEditedItem(midia);

    // Preencha o formulário de edição com os dados do arquivo selecionado

    setValues({
      ...values,
      titulo: midia.titulo,
      link: midia.link,
      categoria: midia.categoria,
      status: midia.status ? 'true' : 'false',
      data: new Date(midia.data),
      thumbnail: midia.thumbnail,
      conteudo: midia.conteudo,
      nomeOriginalArquivo: midia.nomeOriginalArquivo,
    });


    // Defina a pasta selecionada
    setSelectedFolder(midia);
     // Scrolla a página para o topo de forma suave
     window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    // Limpe os campos do formulário
    setValues({
      id: 0,
      titulo: '',
      link: '',
      categoria: '',
      status: 'ativo',
      data: new Date(),
      thumbnail: [''],
      conteudo: '',
      nomeOriginalArquivo: [''],
    });

    // Limpe a pasta selecionada
    setSelectedFolder(null);
  };







  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar Vídeo</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="titulo">Título:</label>
                  <input
                    type="text"
                    name="titulo"
                    value={values.titulo}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="resumo">Link:</label>
                  <input
                    type="text"
                    name="link"
                    value={values.link}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.data}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>

              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="conteudo">Conteúdo: </label>
                  <input
                    placeholder="exemplo: assista aqui"
                    type="text"
                    name="conteudo"
                    value={values.conteudo}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="titulo">Título:</label>
                  <input
                    type="text"
                    name="titulo"
                    value={values.titulo}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="resumo">Link:</label>
                  <input
                    type="text"
                    name="link"
                    value={values.link}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.data}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <ImageUpload onImageUpload={onImageUpload} />
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="conteudo">Conteúdo: </label>
                  <input
                    placeholder="exemplo: assista aqui"
                    type="text"
                    name="conteudo"
                    value={values.conteudo}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <CustomButton type="submit" text="Cadastrar" />
            </form>
          )}
        </section>
        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Arquivos em : {selectedFolder.titulo}</h2>
            <ul className='lista'>
              {selectedFolder.nomeOriginalArquivo.slice(0).map((fileUrl, index) => (
                <ul key={index}>
                  <BsFillTrashFill
                    className='lixo'
                    size={20}
                    onClick={() => deleteFile(index)}
                  />
                  <AiFillFolderOpen className='icon-arq' />
                  <a
                    className='arqui'
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedFolder.nomeOriginalArquivo[index]}
                  </a>
                </ul>
              ))}
            </ul>


            <div className="input-group-">
              <ImageUpload onImageUpload={onImageUpload} />
            </div>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}


        <section className="gerenciar-2">
          <h1>Gerenciar Vídeos</h1>
          {sortedMidias?.map((midia) => (
            <ImageContainer key={midia.id} onClick={() => console.log(midia)} className="video">
              <div className="image">
                <img src={midia.thumbnail} alt="logo" />
              </div>
              <div className="info">
                <h2 className="titulo">{midia.titulo}</h2>
              </div>
              <div className="status-3">
                <div className={midia.status ? 'ativo' : 'inativo'}>{midia.status ? 'Ativo' : 'Inativo'}</div>
              </div>
              <div className="buttons">
                <BsFillTrashFill size={20} onClick={() => onDelete(midia.id)} />
                <BsFillPencilFill size={20} onClick={() => handleEdit(midia)} />
              </div>
            </ImageContainer>
          ))}
        </section>
      </div>
    </AdminPageContainer>
  );
}
