import React, { useEffect, useState } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { getComunicados } from '../../services/api';
import { useQuery } from 'react-query';
import ReactQuill from 'react-quill';
import "./styles.css";




const ComunicadosComponent = ({ user, comunicados }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [notifiedUsers, setNotifiedUsers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);



  useEffect(() => {
    const notifiedComunicadosString = localStorage.getItem('notifiedComunicados') || '{}';
    const notifiedComunicados = JSON.parse(notifiedComunicadosString);

    const hasNewComunicados = comunicados?.some(comunicado => {
      const userNotified = notifiedComunicados[user.id];
      return (
        !userNotified ||
        !userNotified.ids.includes(comunicado.id.toString())
      ) && comunicado.tipo === 'Urgente';
    });

    if (hasNewComunicados) {
      const lastComunicado = comunicados.find(comunicado => {
        const userNotified = notifiedComunicados[user.id];
        return (
          !userNotified ||
          !userNotified.ids.includes(comunicado.id.toString())
        ) && comunicado.tipo === 'Urgente';
      });

      if (lastComunicado) {
        const userNotified = notifiedComunicados[user.id] || { ids: [], names: [] };

        const hasUserBeenNotified = userNotified.ids.includes(lastComunicado.id.toString());

        if (!hasUserBeenNotified) {
          // Exibir o pop-up
          setShowPopup(true);

          // Armazenar o ID e nome do último comunicado notificado para o usuário
          userNotified.ids.push(lastComunicado.id.toString());
          userNotified.names.push(user.nome);

          notifiedComunicados[user.id] = userNotified;
          localStorage.setItem('notifiedComunicados', JSON.stringify(notifiedComunicados));

          // Atualizar a lista de usuários notificados
          setNotifiedUsers(userNotified.names);
        }
      }
    }
  }, [comunicados, user.id, user.nome]);



  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };




  const closePopup = () => {
    // Fechar o pop-up
    setShowPopup(false);
  };


  const handleCheckboxChange = () => {
    // Atualizar o estado do checkbox
    setIsChecked(!isChecked);
  };


  return (
    <div>
      {showPopup && (
        <div className='pop-up-container'>
          <div className="pop-up">
            <div className="popup-content-">
              <h3 style={{ color: "white", backgroundColor: "#007bff", borderRadius: "8px" }}>Novo Comunicado disponível</h3><br></br>
              {comunicados.slice(-1).map(comunicado => (
                <div key={comunicado.id}>
                  <h5 style={{ marginLeft: "0px" }}>{comunicado.titulo}</h5>
                  <h5 style={{ color: "gray", marginLeft: "0px" }}>{formatDate(new Date(comunicado.dataEnvio))}</h5>
                  <ReactQuill
                    style={{ height: '300px', width: '100%' }}
                    value={comunicado.mensagem}
                    readOnly={true}
                    modules={{ toolbar: false }}
                  />
                </div>
              ))}

              <label>
                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
              </label>
              {isChecked && <h6 style={{ color: 'gray' }}> Confirmação de notificação recebida</h6>}
            </div>

            {isChecked && <button onClick={closePopup}>fechar</button>}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComunicadosComponent;

