import React, { ChangeEvent, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getCalls } from '../../../services/api';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { Container, MessageContent, MessageContainer, ChatFeed } from './styles';
import './styles.css';
import { client } from '../../../services/instance';
import { FaFilePdf, FaFileArchive, FaFileWord, FaFileExcel, FaFileDownload, FaRegStar, FaStar } from 'react-icons/fa';
import { MessageInterface } from '../../../interfaces/MessageInterface';
import { RefetchOptions } from 'react-query/core';
import { MdPassword } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';

interface ConversaItem {
  id: number;
  autor: string;
  mensagens: string;
  date: string;
  createdAt: string;
}

interface AvaliacaoItem {
  id: number;
  userName: string;
  assessment: number;
  dateTime: string;
}

export function RelatorioPage() {
  const [password, setPassword] = useState('');
  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);
  const [isPasswordstarPopupOpen, setIsPasswordstarPopupOpen] = useState(false);
  const { data: chamadas, refetch } = useQuery('relatorios', () =>
    getCalls().then((resposta) => resposta?.data)
  );
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ConversaItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedConversationId, setSelectedConversationId] = useState<number | null>(null);
  const [selectedAvaliacaoId, setSelectedAvaliacaoId] = useState<number | null>(null);
  const username = 'Suporte';
  const [isStarPopupOpen, setIsStarPopupOpen] = useState(false);
  const [avaliacoes, setAvaliacoes] = useState<AvaliacaoItem[]>([]);



  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);




  const onDelete = (id: number) => {
    setIsPasswordPopupOpen(true);
    setSelectedConversationId(id);
  };

  const onDeletestar = (id: number) => {
    setIsPasswordstarPopupOpen(true);
    setSelectedAvaliacaoId(id); // Definindo o ID da avaliação para exclusão
  };

  //função para apagar a avaliação(normalmente devrá ser ultilizada após apagar a conversa que corresponder a tal avaliação)
  const handleDeleteStarConfirmation = () => {
    if (password === 'Mercadotic@') {
      const updatedAvaliacoes = avaliacoes.filter((avaliacao) => avaliacao.id !== selectedAvaliacaoId);
      setAvaliacoes(updatedAvaliacoes);
      localStorage.setItem('assessments', JSON.stringify(updatedAvaliacoes)); // Atualizar o localStorage
      alert('Avaliação deletada com sucesso!');
      setIsPasswordPopupOpen(false);
      setPassword('');
      setSelectedAvaliacaoId(null);
    } else {
      alert('Senha incorreta. Tente novamente.');
    }
  };
  //função para deletar a conversa
  const handleDeleteConfirmation = () => {
    if (password === 'Mercadotic@') {
      client.delete(`/chamados/delete/${selectedConversationId}`).then((res) => {
        console.log(res);
        alert('Conversa deletada com sucesso!');
        refetch();
        setSelectedConversationId(null);   //veiiii
        setIsPasswordPopupOpen(false);
        setPassword('');
        setSearchTerm('');
      });
    } else {
      alert('Senha incorreta. Tente novamente.');
    }
  };
  //funçao para selecionar a conversa
  const handleConversationClick = (conversationId: number) => {
    setSelectedConversationId(conversationId);
  };

  const calculateContainerHeight = () => {
    const minHeight = 300;
    const estimatedHeight = chamadas ? chamadas.length * 53 : minHeight;
    return Math.max(estimatedHeight, minHeight);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0);
  };
  //deletar apertando o botao enter
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleDeleteConfirmation();
    }
  };
  //efeito do component de busca das conversas
  useEffect(() => {
    if (chamadas && chamadas.length > 0) {
      const filteredResults = chamadas.filter((conversa) =>
        conversa.autor.toLowerCase().includes(searchTerm.toLowerCase()) ||
        new Date(conversa.createdAt).toLocaleString().toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setCurrentIndex(0);
    }
  }, [chamadas, searchTerm]);

  const jumpTo = () => {
    if (searchResults.length) {
      const element = document.getElementById(`conversaItem-${currentIndex}`);
      if (element) {
      }
    }
  };

  useEffect(() => {
    jumpTo();
  }, [currentIndex, searchResults]);


  //destacar  o texto
  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: orange">${match}</span>`);
  };


  //verificar e converter a mensagem em um link clicavel
  const convertUrlsToLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className='linkss'>
          {part}
        </a>
      ) : (
        part
      )
    );
  };



  const renderMessages = (conversa: ConversaItem) => {
    const messages: MessageInterface[] = JSON.parse(conversa.mensagens);

    return (
      <MessageContainer>
        <div className="message-container">
          {messages.map((message, index) => (
            <MessageContent key={index} author={username === message.author} className='message-content'>
              <div className='mensagem'>
                <p>{convertUrlsToLinks(message.message)}</p>
                <p style={{
                  fontSize: '11px',
                  textAlign: message.author === 'Suporte' ? 'right' : 'left',
                  color: message.author === 'Suporte' ? 'white' : 'black'
                }}>{message.time}</p>
                {message.images &&
                  message.images.map((image, imageIndex) => (
                    <a
                      key={imageIndex}
                      href={image}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <img
                        key={imageIndex}
                        src={image}
                        alt={`Imagem ${imageIndex + 1}`}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100px',
                          borderRadius: '8px',
                          marginTop: '-3px',
                        }}
                      />
                    </a>
                  ))}
                {message.files &&
                  message.files.map((file, fileIndex) => (
                    <div key={fileIndex}>
                      <a
                        className='file'
                        href={file}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '45px' }}
                      >
                        {file.endsWith('.pdf') && <FaFilePdf />}
                        {file.endsWith('.rar') && <FaFileArchive />}
                        {file.endsWith('.doc') && <FaFileWord />}
                        {file.endsWith('.docx') && <FaFileWord />}
                        {file.endsWith('.xls') && <FaFileExcel />}
                        {file.endsWith('.xlsx') && <FaFileExcel />}
                        {!file.endsWith('.pdf') && !file.endsWith('.rar') && !file.endsWith('.doc') &&
                          !file.endsWith('.docx') && !file.endsWith('.xls') && !file.endsWith('.xlsx') && <FaFileDownload />}
                      </a>
                    </div>
                  ))}
              </div>
            </MessageContent>
          ))}
        </div>
      </MessageContainer>
    );
  };

  useEffect(() => {
    const storedAssessmentData = localStorage.getItem('assessments');
    if (storedAssessmentData) {
      const assessmentsData: AvaliacaoItem[] = JSON.parse(storedAssessmentData);
      setAvaliacoes(assessmentsData); // Atualizar o estado com todas as avaliações
    }
  }, []);

  return (
    <AdminPageContainer padding="0px" style={{ height: `110%`, width: '%' }}>
      <div className="main2">
        <div className="criar">
          <h1 className='relatorio'>Relatórios de Conversas </h1>
          <input
            type="text"
            className="Searchh"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchInput}
            style={{ border: '2px solid #c0c0c0' }}
          />

          <div className='avaliar'>
            <FaStar onClick={() => setIsStarPopupOpen(true)} />
          </div>

          {isStarPopupOpen && (
            <div className='pop-up-container-r'>
              <div className="pop-up-p">
                <h2 >Todas as Avaliações</h2>
                <div className="popup-content-t">

                  <ul>
                    {avaliacoes.map((avaliacao, index) => (
                      <div key={index}>
                        <strong>
                          <p className='conversa'>{avaliacao.userName} - {new Date(avaliacao.dateTime).toLocaleString()}, Avaliação = {avaliacao.assessment}</p>
                        </strong >
                        <div className='buttons--'>
                          <BsFillTrashFill size={20} onClick={() => onDeletestar(avaliacao.id)} />
                        </div>

                      </div>
                    )).reverse()}
                  </ul>
                </div>
                <button className="fechar" onClick={() => setIsStarPopupOpen(false)}>Fechar</button>
              </div>
            </div>
          )}

          {searchResults &&
            searchResults.map((conversa) => (
              <div key={conversa.id} onClick={() => handleConversationClick(conversa.id)}>
                <strong>
                  <p
                    className="conversa"
                    dangerouslySetInnerHTML={{
                      __html: `${highlightText(
                        `${conversa.autor} - ${new Date(conversa.createdAt).toLocaleString()}`,
                        searchTerm
                      )} `,
                    }}
                  />
                  <div className='buttons-'>
                    <BsFillTrashFill size={20} onClick={() => onDelete(conversa.id)} />
                  </div>
                </strong>
              </div>
            )).reverse()
          }
        </div>

        <div className="separator" />
        <div className="gerenciar">
          <h1>Histórico de Mensagens</h1>
          {selectedConversationId &&
            chamadas
              .filter((conversa) => conversa.id === selectedConversationId)
              .map((conversa) => (
                <div key={conversa.id}>
                  <div className='menssage'>
                    <h5 className='conversa-com'>Conversa com {conversa.autor}</h5>
                    <h6 style={{ marginRight: '22px' }}>ID-{conversa.id}</h6><br></br>
                    <p style={{ fontSize: '11px', color: 'grey' }}>
                      {new Date(conversa.createdAt).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))
          }

          <Container>
            {selectedConversationId &&
              chamadas
                .filter((conversa) => conversa.id === selectedConversationId)
                .map((conversa) => (
                  <div key={conversa.id}>
                    <div className='message'>
                      {renderMessages(conversa)}
                    </div>
                  </div>
                ))}
          </Container>
        </div>
      </div>

      {isPasswordPopupOpen && (
        <div className="pop-up-container">
          <div className='pop-up'>
            <div className='pop-up-content'>
              <input
                type="password"
                style={{ fontSize: "20px", border: "solid grey 2px" }}
                placeholder="Digite a senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete='off'
                onKeyPress={handleKeyPress}
              />
            </div>
            <button style={{ margin: '5px' }} onClick={handleDeleteConfirmation}>Confirmar</button>
            <button onClick={() => {
              setIsPasswordPopupOpen(false);
              setSearchTerm('');
            }}>Cancelar</button>
          </div>
        </div>
      )}





      {isPasswordstarPopupOpen && (
        <div className="pop-up-containerr">
          <div className='pop-up'>
            <div className='pop-up-content' >
              <input
                type="password"
                style={{ fontSize: "20px", border: "solid grey 2px" }}
                placeholder="Digite a senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete='off'
              />
            </div>
            <button style={{ margin: '5px' }} onClick={() => {
              handleDeleteStarConfirmation();
              setIsPasswordstarPopupOpen(false);
            }}>Confirmar</button>
            <button onClick={() => {
              setIsPasswordstarPopupOpen(false);
              setSearchTerm('');
            }}>Cancelar</button>
          </div>
        </div>
      )}

    </AdminPageContainer>
  );
}
