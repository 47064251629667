import ReactQuill from "react-quill";
import React, { useState, useEffect } from 'react';


function ComunicadoContentToggle({ comunicado }) {
    const [containerVisible, setContainerVisible] = useState(false); // Adicione um estado para controlar a visibilidade

    function toggleContainer() {
      setContainerVisible(!containerVisible); // Troque a visibilidade do container
    }

    return (
      <div>
        {containerVisible && (
          <ReactQuill
            style={{ height: '300px', width: '99.1%' }}
            readOnly={true} // Defina como somente leitura
            value={comunicado.mensagem}
            modules={{ toolbar: false }} // Desative a barra de ferramentas
          />
        )}

        <button id='read' onClick={toggleContainer}>
          {containerVisible ? '...Ler menos' : 'Ler mais...'}
        </button>

      </div>
    );
    
  }

  export default ComunicadoContentToggle;