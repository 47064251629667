import styled from '@emotion/styled';

export const RoomListContainer = styled.div`
  width: 20%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  scroll-y: auto;
`;

export const MessageContainer = styled.div`
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;



export const ChatFeed = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;

  button {
    border: none;
    background: red;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 5px;
    height: 5%;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    position: flex;
    top: 1rem;
    right: 1rem;
    color: #fff;
  }
`;

export const ChatInput = styled.div`
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 60%;
    height: 50%;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
  button {
    margin-left: 5px;
    border: none;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 5px;
    height: 50%;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    &:hover{
      background: #cccccc;
    }
  }
`;

export const MessageContent = styled.div<{ author?: boolean  }>`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: ${({ author }) => (author ? 'flex-end' : 'flex-star')};
  .message {
    width: fit-content;
    padding: 1rem;
    border-radius: 10px;
    background: #d3d3d3;
    box-shadow: 0 0 5px 0 grey;
    color: #282a36;
  }
`;



