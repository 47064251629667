import styled from '@emotion/styled';

export const FotoContainer = styled.div`
  /* display: flex;
  width: 50%;
  overflow-x: auto;
  margin: 0 auto;
  margin-top: 5rem; */
.card-foto {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 5rem;
  transition: 0.3s;
   &:hover {
      
      transform:scale(1.05) ;
   }

  img {
    margin-top: 10px;
    max-width: 100%;
    border-radius: 0px;
    transition: filter 0.3s; /* Adicionada transição para suavizar a animação */

    &:hover {
      
      transform:scale(1.05) ;
     
    }

    
  }


  .foto-titulo{
       position: absolute;
      margin-left: 0;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
       margin-right: 0%;
       color: #fff;
       padding: 5px;
       text-align: center;
       justify-content: center;
       margin-left: 5%;
       margin-top: 30%;
       font-size: 19PX;
       @media (min-width: 1367px) and (max-width: 1921px) {
        margin-left: 13%;
        font-size: 22PX;
       }
  }
}


`;
