import React, { useState } from 'react';
import './styles.css';

function TreinoContentToggle({ treinamento }) {
  const [containerVisible, setContainerVisible] = useState(false);

  function toggleContainer() {
    setContainerVisible(!containerVisible);
  }

  const isVideoLink = (url) => {
    return (
      url.includes('youtube') ||
      url.includes('vimeo') ||
      (url.includes('list=') && url.includes('youtube'))
    );
  };

  const getEmbedUrl = (url) => {
    if (url.includes('youtube') && url.includes('list=')) {
      // YouTube playlist
      const playlistId = url.split('list=')[1].split('&')[0];
      return `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
    } else if (url.includes('youtube') && url.includes('watch?v=')) {
      // Regular YouTube video
      const videoId = url.split('v=')[1].split('&')[0];
      return `https://www.youtube-nocookie.com/embed/${videoId}`;
    } else {
      return url;
    }
  };
  
  return (
    <div>
      {containerVisible && (
        <div id="conteudo" className='video-'>
          {/* Link externo 1 */}
          {treinamento.linkExterno1 && (
            isVideoLink(treinamento.linkExterno1) ? (
              <iframe
                className='embed'
                title="Playlist"
                src={getEmbedUrl(treinamento.linkExterno1)}
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <div>
                <a
                  className='name-video'
                  href={treinamento.linkExterno1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {treinamento.linkExterno1}
                </a>
              </div>
            )
          )}
          {/* Link externo 2 */}
          {treinamento.linkExterno2 && (
            isVideoLink(treinamento.linkExterno2) ? (
              <iframe
                className='embed'
                title="Playlist"
                src={getEmbedUrl(treinamento.linkExterno2)}
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <div>
                <a
                  className='name-video'
                  href={treinamento.linkExterno2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {treinamento.linkExterno2}
                </a>
              </div>
            )
          )}
          {/* Link externo 3 */}
          {treinamento.linkExterno3 && (
            isVideoLink(treinamento.linkExterno3) ? (
              <iframe
                className='embed'
                title="Playlist"
                src={getEmbedUrl(treinamento.linkExterno3)}
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <div>
                <a
                  className='name-video'
                  href={treinamento.linkExterno3}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {treinamento.linkExterno3}
                </a>
              </div>
            )
          )}
          {/* Link externo 4 */}
          {treinamento.linkExterno4 && (
            isVideoLink(treinamento.linkExterno4) ? (
              <iframe
                className='embed'
                title="Playlist"
                src={getEmbedUrl(treinamento.linkExterno4)}
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <div>
                <a
                  className='name-video'
                  href={treinamento.linkExterno4}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {treinamento.linkExterno4}
                </a>
              </div>
            )
          )}
          {/* Link externo 5*/}
          {treinamento.linkExterno5 && (
            isVideoLink(treinamento.linkExterno5) ? (
              <iframe
                className='embed'
                title="Playlist"
                src={getEmbedUrl(treinamento.linkExterno5)}
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <div>
                <a
                  className='name-video'
                  href={treinamento.linkExterno5}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {treinamento.linkExterno5}
                </a>
              </div>
            )
          )}
          {/* Link externo 6*/}
          {treinamento.linkExterno6 && (
            isVideoLink(treinamento.linkExterno6) ? (
              <iframe
                className='embed'
                title="Playlist"
                src={getEmbedUrl(treinamento.linkExterno6)}
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <div>
                <a
                  className='name-video'
                  href={treinamento.linkExterno6}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {treinamento.linkExterno6}
                </a>
              </div>
            )
          )}
        </div>
      )}
      <button id="read" onClick={toggleContainer}>
        {containerVisible ? 'Ocultar Treinamento' : 'Ver Treinamento'}
      </button>
    </div>
  );
}

export default TreinoContentToggle;
