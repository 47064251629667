import styled from '@emotion/styled';

export const Stripe = styled.div`
  height: 264px;
  width: 100%;
  position: relative;
  top: -54px;
  background: #303b92;

`;
