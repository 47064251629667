import { ROOMS } from '../actions/actions.type';

const INITIAL_STATE = {
  rooms: [],
};

function roomsReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case ROOMS:
      return {
        ...state,
        rooms: action.payload,
      };
    default:
      return state;
  }
}

export default roomsReducer;
