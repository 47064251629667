import { Link } from 'react-router-dom';
import './styles.css';
import { MdAccountCircle } from 'react-icons/md';
import { useState } from 'react';

export function Navbar() {
  const [logout, setLogout] = useState(false);

  const handleLogout = () => {
    setLogout((prev) => !prev);
  };

  const logoutFunc = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <nav className="Navbar">
      <Link to="/iniciar">
        <img
          src="assets/logo.png"
          alt="logo mercadotica"
          className="navbarlogo"
        />
      </Link>
      <div className="NavButtons">
        <Link to="/iniciar">Início</Link>
        <Link to="/videos">Vídeos</Link>
        <Link to="/fotos">Fotos</Link>
        <Link to="/comunicados">Comunicados</Link>
        <Link to="/treinamentos">Treinamentos</Link>
        <Link to="/news">Notícias</Link>
        <Link to="/arquivos">Arquivos</Link>
        <Link to="/FAQ">FAQ</Link>


      </div>
      <div className="logout-home">
        <MdAccountCircle size={35} style={{ cursor: 'pointer' }} onClick={handleLogout} />
        {logout && (
          <div className="logout-container" onClick={logoutFunc}>
            <p>Sair</p>
          </div>
        )}
      </div>
    </nav>
  );
}
