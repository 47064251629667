import styled from '@emotion/styled';

export const ChatWindowContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;

  .title-container {
    background: #303b92;
    width: 100%;
    height: 10%;
    text-align: center;
    color: #fff;
  }
  .chat-container {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .input-container {
    width: 100%;
    height: 10%;
    display: flex;
    border: 0.5px solid #303b92;
  }
`;

export const ChatBotContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  .message-container {
    width: fit-content;
    margin-left: 4px;
    padding: 8px;
    border-radius: 10px 10px 10px 0px;
    color: #fff;
    font-size: 1.2rem;
    box-shadow: 0px 0px 5px 0px #303b92;
    color: #111;
    .options {
      display: flex;
      flex-direction: column;

      p {
        text-decoration: none;
        color: #303b92;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          transform: scale(1.01);
        }
      }
    }
  }
`;
