import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/stores';
import { ChatContainer } from './styles';

interface MessageContainerProps {
  author: string;
  message: string;
  time?: string;
}

export function MessageContainer({ message, author, time }: MessageContainerProps) {
  const { user } = useSelector((state: RootState) => state.userReducer);

  return (
    <ChatContainer self={author === user.nome}>
      <div className="message-container">
        <div className="message-content">
          {message && <div className='mensagem'>{message}</div>}
          {time && <div className="message-time">{time}</div>}
        </div>
      </div>
    </ChatContainer>
  );
}

