import React, { useState, useEffect } from 'react';
import { Container } from './styles';
import socket from '../../services/socket';
import { join } from 'path';
import { Visibility } from '@mui/icons-material';

interface Room {
  name: string;
  unreadCount: number;
}

interface RoomItemComponentProps {
  onClick: () => void;
  room: Room;
}

export function RoomItemComponent({ onClick, room }: RoomItemComponentProps) {
  const [unreadText, setUnreadText] = useState('');
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const [inRoom, setInRoom] = useState<boolean>(false);



  useEffect(() => {
    const handleNewMessage = (data: any) => {
      // Verificar se a mensagem é para a sala atual
      if (data.room === room.name && data.author !== 'Suporte'  ) {
        // Incrementar o contador de mensagens não lidas
        setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
      } else if (data.room === room.name && data.author === 'Suporte') {
        // Se a mensagem é do Suporte, resetar o contador e o texto não lido
        setUnreadCount(0);
        setUnreadText('');
      }
    };
  
    socket.on('private_message', handleNewMessage);
  
    return () => {
      socket.off('private_message', handleNewMessage);
    };
  }, [room.name,]);

  useEffect(() => {
    if (unreadCount > 0) {
      setUnreadText(unreadCount === 1 ? '(1)' : `(${unreadCount})`);
    } else {
      setUnreadText('');
    }
  }, [unreadCount]);


  const handleClick = () => {
    setUnreadCount(0);
    setUnreadText('');
    onClick();
  };

  return (
    <Container onClick={handleClick}>
      <div className="room-info" >
        <h3>{room.name} {unreadText}</h3>
      </div>
    </Container>
  );
}
