import styled from '@emotion/styled';

export const Container = styled.div`
  width: 80%;
  height: 3rem;
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #282a36;
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .new-messages {
    height: 1.5rem;
    width: 1.5rem;
    background: red;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
