/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormEvent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';

import './styles.css';
import { getFranqueados } from '../../../services/api';
import { client } from '../../../services/instance';
import { FranqueadoContainer } from './styles';

import { useQuery } from 'react-query';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';


interface Franqueado {
  id: number,
  nomeUnidade: string,
  numUnidade: string,
  razaoSocial: string,
  nomeFantasia: string,
  cnpjCpf: string,
  inscricaoEstadual: string,
  inscricaoMunicipal: string,
  email: string,
  status: string,
  celular: string,
  telAlternativo: string,
  telComercial: string,
  endereco: string,
  cidade: string,
  estado: string,
  tipoFranquia: string,
  modalidade: string,
  porte: string,
  assinaturaContrato: Date,
  vencimentoContrato: Date,
  franqueadoDesde: Date,
  qtdColaboradores: string,
  responsavel: string,
  pontoComercial: string,
  grupoSocios: string,

}





export function FranqueadosPage() {
  const [values, setValues] = useState({
    id: 0,
    nomeUnidade: '',
    numUnidade: '',
    razaoSocial: '',
    nomeFantasia: '',
    cnpjCpf: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    email: '',
    status: 'true',
    celular: '',
    telAlternativo: '',
    telComercial: '',
    endereco: '',
    cidade: '',
    estado: '',
    tipoFranquia: '',
    modalidade: '',
    porte: '',
    assinaturaContrato: new Date(),
    vencimentoContrato: new Date(),
    franqueadoDesde: new Date(),
    qtdColaboradores: '',
    responsavel: '',
    pontoComercial: '',
    grupoSocios: '',
  });

  const [profile, setProfile] = useState('Pessoa Física');
  const [editedItem, setEditedItem] = useState<Franqueado | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  const { data: franqueados, refetch } = useQuery(
    'franqueados',
    () => getFranqueados().then((response) => response?.data),
    {
      refetchOnWindowFocus: false,
    }
  );



  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Franqueado | null>(null);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // Função para lidar com a seleção da data de assinaturaa
  const handleDateChange = (date) => {
    setValues({ ...values, assinaturaContrato: date });
  };


  // Função para lidar com a seleção da data de vencimento
  const handleDateChanger = (date) => {
    setValues({ ...values, vencimentoContrato: date });
  };

  // Função para lidar com a seleção da data de franqueamento
  const handleDateChangers = (date) => {
    setValues({ ...values, franqueadoDesde: date });
  };

  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    client.post('/franqueados/create', { ...values, profile, }).then((res) => {
      console.log(res);
      alert('Franqueado cadastrado com sucesso!');
    });

    console.log({
      ...values,
      profile,
    });
  };

  const onDelete = (id: number) => {
    client.delete(`/franqueados/delete/${id}`).then((res) => {
      console.log(res);
      alert('Franqueado excluído com sucesso!');
      refetch();
    });
  };


  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        nomeUnidade: values.nomeUnidade,
        numUnidade: values.numUnidade,
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpjCpf: values.cnpjCpf,
        inscricaoEstadual: values.inscricaoEstadual,
        inscricaoMunicipal: values.inscricaoMunicipal,
        email: values.email,
        status: values.status === 'true' ? 'ativo' : 'inativo',
        celular: values.celular,
        telAlternativo: values.telAlternativo,
        telComercial: values.telComercial,
        endereco: values.endereco,
        cidade: values.cidade,
        estado: values.estado,
        tipoFranquia: values.tipoFranquia,
        modalidade: values.modalidade,
        porte: values.porte,
        assinaturaContrato: values.assinaturaContrato,
        vencimentoContrato: values.vencimentoContrato,
        franqueadoDesde: values.franqueadoDesde,
        qtdColaboradores: values.qtdColaboradores,
        responsavel: values.responsavel,
        pontoComercial: values.pontoComercial,
        grupoSocios: values.grupoSocios,


      };



      try {
        const response = await client.put(`/franqueados/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };







  const handleEdit = (franqueados: Franqueado) => {
    setIsEditing(true);
    setEditedItem(franqueados);

    setValues({
      ...values,
      nomeUnidade: franqueados.nomeUnidade,
      numUnidade: franqueados.numUnidade,
      razaoSocial: franqueados.razaoSocial,
      nomeFantasia: franqueados.nomeFantasia,
      cnpjCpf: franqueados.cnpjCpf,
      inscricaoEstadual: franqueados.inscricaoEstadual,
      inscricaoMunicipal: franqueados.inscricaoMunicipal,
      email: franqueados.email,
      status: franqueados.status === 'true' ? 'ativo' : 'inativo',
      celular: franqueados.celular,
      telAlternativo: franqueados.telAlternativo,
      telComercial: franqueados.telComercial,
      endereco: franqueados.endereco,
      cidade: franqueados.cidade,
      estado: franqueados.estado,
      tipoFranquia: franqueados.tipoFranquia,
      modalidade: franqueados.modalidade,
      porte: franqueados.porte,
      assinaturaContrato: new Date(franqueados.assinaturaContrato),
      vencimentoContrato: new Date(franqueados.vencimentoContrato),
      franqueadoDesde: new Date(franqueados.franqueadoDesde),
      qtdColaboradores: franqueados.qtdColaboradores,
      responsavel: franqueados.responsavel,
      pontoComercial: franqueados.pontoComercial,
      grupoSocios: franqueados.grupoSocios,



    });

    setSelectedFolder(franqueados);
     // Scrolla a página para o topo de forma suave
     window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    setValues({
      id: 0,
      nomeUnidade: '',
      numUnidade: '',
      razaoSocial: '',
      nomeFantasia: '',
      cnpjCpf: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      email: '',
      status: 'true',
      celular: '',
      telAlternativo: '',
      telComercial: '',
      endereco: '',
      cidade: '',
      estado: '',
      tipoFranquia: '',
      modalidade: '',
      porte: '',
      assinaturaContrato: new Date(),
      vencimentoContrato: new Date(),
      franqueadoDesde: new Date(),
      qtdColaboradores: '',
      responsavel: '',
      pontoComercial: '',
      grupoSocios: '',

    });

    setSelectedFolder(null);
  };




  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content">
        <section className="cadastro-1">
          <h1>Cadastrar Franqueados</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form >
              <div className="radios">
                <div>
                  <label>Pessoa Física</label>
                  <input
                    type="radio"
                    name="profile"
                    value="Pessoa Física"
                    checked={profile === 'Pessoa Física'}
                    onChange={() => setProfile('Pessoa Física')}
                  />
                </div>
                <div>
                  <label>Pessoa Jurídica</label>
                  <input
                    type="radio"
                    name="profile"
                    value="Pessoa Jurídica"
                    checked={profile === 'Pessoa Jurídica'}
                    onChange={() => setProfile('Pessoa Jurídica')}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="nomeUnidade">Nome da Unidade:</label>
                  <input
                    type="text"
                    name="nomeUnidade"
                    value={values.nomeUnidade}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="numUnidade">Nº da Unidade:</label>
                  <input
                    type="text"
                    name="numUnidade"
                    value={values.numUnidade}
                    onChange={handleInputChange}
                    style={{ width: '80%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="razaoSocial">Razão Social:</label>
                  <input
                    type="text"
                    name="razaoSocial"
                    value={values.razaoSocial}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="nomeFantasia">Nome Fantasia:</label>
                  <input
                    type="text"
                    name="nomeFantasia"
                    value={values.nomeFantasia}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="cnpjCpf">CNPJ/CPF:</label>
                  <input
                    type="text"
                    name="cnpjCpf"
                    value={values.cnpjCpf}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="inscricaoEstadual">Inscrição Estadual:</label>
                  <input
                    type="text"
                    name="inscricaoEstadual"
                    value={values.inscricaoEstadual}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="inscricaoMunicipal">Inscrição Municipal:</label>
                  <input
                    type="text"
                    name="inscricaoMunicipal"
                    value={values.inscricaoMunicipal}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="nomeFantasia">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="celular">Celular:</label>
                  <input
                    type="text"
                    name="celular"
                    value={values.celular}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="telAlternativo">Tel Alternativo:</label>
                  <input
                    type="text"
                    name="telAlternativo"
                    value={values.telAlternativo}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="telComercial">Tel Comercial:</label>
                  <input
                    type="text"
                    name="telComercial"
                    value={values.telComercial}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="endereco">Endereço:</label>
                  <input
                    type="text"
                    name="endereco"
                    value={values.endereco}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="cidade">Cidade:</label>
                  <input
                    type="text"
                    name="cidade"
                    value={values.cidade}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="estado">Estado:</label>
                  <input
                    type="text"
                    name="estado"
                    value={values.estado}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="tipoFranquia">Tipo de Franquia:</label>
                  <input
                    type="text"
                    name="tipoFranquia"
                    value={values.tipoFranquia}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="modalidade">Modalidade:</label>
                  <input
                    type="text"
                    name="modalidade"
                    value={values.modalidade}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="porte">Porte:</label>
                  <input
                    type="text"
                    name="porte"
                    value={values.porte}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="assinaturaContrato">Assinatura do contrato:</label>
                  <DatePicker
                    selected={values.assinaturaContrato}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="vencimentoContrato">Vencimento do contrato:</label>
                  <DatePicker
                    selected={values.vencimentoContrato}
                    onChange={handleDateChanger}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="franqueadoDesde">Franqueado desde:</label>
                  <DatePicker
                    selected={values.vencimentoContrato}
                    onChange={handleDateChanger}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="qtdColaboradores">Qtde de Colaboradores:</label>
                  <input
                    type="text"
                    name="qtdColaboradores"
                    value={values.qtdColaboradores}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="responsavel">Responsável:</label>
                  <input
                    type="text"
                    name="responsavel"
                    value={values.responsavel}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="pontoComercial">Ponto Comercial:</label>
                  <input
                    type="text"
                    name="pontoComercial"
                    value={values.pontoComercial}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="grupoSocios">Grupo de Sócios:</label>
                  <input
                    type="text"
                    name="grupoSocios"
                    value={values.grupoSocios}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>

              </div>
            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="radios">
                <div>
                  <label>Pessoa Física</label>
                  <input
                    type="radio"
                    name="profile"
                    value="Pessoa Física"
                    checked={profile === 'Pessoa Física'}
                    onChange={() => setProfile('Pessoa Física')}
                  />
                </div>
                <div>
                  <label>Pessoa Jurídica</label>
                  <input
                    type="radio"
                    name="profile"
                    value="Pessoa Jurídica"
                    checked={profile === 'Pessoa Jurídica'}
                    onChange={() => setProfile('Pessoa Jurídica')}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="nomeUnidade">Nome da Unidade:</label>
                  <input
                    type="text"
                    name="nomeUnidade"
                    value={values.nomeUnidade}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="numUnidade">Nº da Unidade:</label>
                  <input
                    type="text"
                    name="numUnidade"
                    value={values.numUnidade}
                    onChange={handleInputChange}
                    style={{ width: '80%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="razaoSocial">Razão Social:</label>
                  <input
                    type="text"
                    name="razaoSocial"
                    value={values.razaoSocial}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="nomeFantasia">Nome Fantasia:</label>
                  <input
                    type="text"
                    name="nomeFantasia"
                    value={values.nomeFantasia}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="cnpjCpf">CNPJ/CPF:</label>
                  <input
                    type="text"
                    name="cnpjCpf"
                    value={values.cnpjCpf}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="inscricaoEstadual">Inscrição Estadual:</label>
                  <input
                    type="text"
                    name="inscricaoEstadual"
                    value={values.inscricaoEstadual}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="inscricaoMunicipal">Inscrição Municipal:</label>
                  <input
                    type="text"
                    name="inscricaoMunicipal"
                    value={values.inscricaoMunicipal}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="nomeFantasia">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="celular">Celular:</label>
                  <input
                    type="text"
                    name="celular"
                    value={values.celular}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="telAlternativo">Tel Alternativo:</label>
                  <input
                    type="text"
                    name="telAlternativo"
                    value={values.telAlternativo}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="telComercial">Tel Comercial:</label>
                  <input
                    type="text"
                    name="telComercial"
                    value={values.telComercial}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="endereco">Endereço:</label>
                  <input
                    type="text"
                    name="endereco"
                    value={values.endereco}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="cidade">Cidade:</label>
                  <input
                    type="text"
                    name="cidade"
                    value={values.cidade}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="estado">Estado:</label>
                  <input
                    type="text"
                    name="estado"
                    value={values.estado}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="tipoFranquia">Tipo de Franquia:</label>
                  <input
                    type="text"
                    name="tipoFranquia"
                    value={values.tipoFranquia}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="modalidade">Modalidade:</label>
                  <input
                    type="text"
                    name="modalidade"
                    value={values.modalidade}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="porte">Porte:</label>
                  <input
                    type="text"
                    name="porte"
                    value={values.porte}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="assinaturaContrato">Assinatura do contrato:</label>
                  <DatePicker
                    selected={values.assinaturaContrato}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="vencimentoContrato">Vencimento do contrato:</label>
                  <DatePicker
                    selected={values.vencimentoContrato}
                    onChange={handleDateChanger}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="franqueadoDesde">Franqueado desde:</label>
                  <DatePicker
                    selected={values.vencimentoContrato}
                    onChange={handleDateChanger}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="qtdColaboradores">Qtde de Colaboradores:</label>
                  <input
                    type="text"
                    name="qtdColaboradores"
                    value={values.qtdColaboradores}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="responsavel">Responsável:</label>
                  <input
                    type="text"
                    name="responsavel"
                    value={values.responsavel}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="pontoComercial">Ponto Comercial:</label>
                  <input
                    type="text"
                    name="pontoComercial"
                    value={values.pontoComercial}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="grupoSocios">Grupo de Sócios:</label>
                  <input
                    type="text"
                    name="grupoSocios"
                    value={values.grupoSocios}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <button type="submit">Cadastrar</button>
            </form>
          )}
        </section>
      {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
      {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'> Franqueado: {selectedFolder.nomeUnidade}</h2>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}

        <section className="gerenciar-1">
          <h1>Gerenciar Franqueados</h1>
          {franqueados?.map((franqueado) => (
            <FranqueadoContainer className='franqueado' key={franqueado.id}>
              <div className='status'>
                <p className='titulo'>Status</p>
                <p>{franqueado.status ? 'Ativo' : 'Inativo'}</p>
              </div>
              <div className='razao'>
                <p className='titulo'>Razão social</p>
                <p>{franqueado.razaoSocial}</p>
              </div>
              <span className='cidade'>
                <p className='titulo'>Cidade e Estado</p>
                {franqueado.cidade}/{franqueado.estado}
              </span>
              <div className='contato'>
                <p className='titulo'>Tel.Comercial</p>
                <p>{franqueado.telComercial}</p>
              </div>
              <div className="buttons">

                <BsFillTrashFill size={20} onClick={() => onDelete(franqueado.id)} />
                <BsFillPencilFill size={20} onClick={() => handleEdit(franqueado)} />
              </div>
            </FranqueadoContainer>
          ))}
        </section>
      </div>
    </AdminPageContainer>
  );
}
