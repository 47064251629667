import { useState } from 'react';
import { MdAccountCircle } from 'react-icons/md';

import { Container, LogoutButton, LogoutContainer } from './styles';

export function LogoutComponent() {
  const [logout, setLogout] = useState(false);

  const handleLogout = () => {
    console.log('logout');
    setLogout((prev) => !prev);
  };

  const logoutFunc = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <Container>
      <MdAccountCircle size={35} style={{ cursor: 'pointer' }} onClick={handleLogout} />
      {logout && (
        <LogoutContainer>
          <LogoutButton onClick={logoutFunc}>Sair</LogoutButton>
        </LogoutContainer>
      )}
    </Container>
  );
}
