import React, { useState } from 'react';
import { FaFileImage } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { BsFiletypeDoc } from "react-icons/bs";
import { FaFileVideo } from "react-icons/fa";
import { IoLibrary } from "react-icons/io5";
import { AiFillFolderOpen } from 'react-icons/ai';

function ArquivoContentToggle({ arquivo, userAccessLevel, setShowPopup }) {
  const [containerVisible, setContainerVisible] = useState(false);

  function toggleContainer() {
    // Nível "colaborador" pode acessar apenas arquivos de "franqueado/colaborador"
    if (userAccessLevel === 'colaborador' && arquivo.acesso === 'franqueado/colaborador/gerente') {
      setContainerVisible(!containerVisible);
    } else {
      setShowPopup(true);
    }
  }

  function toggleContainer3() {
    // Nível "gerente" pode acessar arquivos de "gerente" e "franqueado/colaborador/gerente"
    if (userAccessLevel === 'gerente' && (arquivo.acesso === 'gerente' || arquivo.acesso === 'franqueado/colaborador/gerente')) {
      setContainerVisible(!containerVisible);
    } else {
      setShowPopup(true);
    }
  }

  function toggleContainer2() {
    // Nível "franqueado" pode acessar arquivos de "franqueado", "franqueado/colaborador/gerente" e "gerente"
    if (userAccessLevel === 'franqueado' && (arquivo.acesso === 'franqueado' || arquivo.acesso === 'franqueado/colaborador/gerente' || arquivo.acesso === 'gerente')) {
      setContainerVisible(!containerVisible);
    } else {
      setShowPopup(true);
    }
  }

  function getIconByFileType(fileType) {
    switch (fileType) {
      case 'jpg':
      case 'jpeg':
        return <FaFileImage />;
      case 'png':
        return <FaFileImage />;
      case 'pdf':
        return <FaFilePdf />;
      case 'doc':
      case 'docx':
        return <BsFiletypeDoc />;
      case 'mp4':
      case 'mpeg':
        return <FaFileVideo />;
      case 'rar':
        return <IoLibrary />;
      default:
        return <AiFillFolderOpen />;
    }
  }

  return (
    <div>
      <div>
        <div>
          {containerVisible && (
            <div id="conteudo">
              {arquivo.urlArquivo.map((url, index) => {
                const isFileAvailableForDownload = arquivo.nomeOriginalArquivo[index];
                const fileType = isFileAvailableForDownload ? isFileAvailableForDownload.split('.').pop().toLowerCase() : '';

                if (isFileAvailableForDownload) {
                  return (
                    <div key={index} className='icon-arq'>
                      {getIconByFileType(fileType)}
                      <div>
                        <a
                          className='name-arq'
                          href={url}
                          target="_blank" rel="noopener noreferrer"
                          download={`${arquivo.nomeOriginalArquivo[index]}`}
                        >
                          {arquivo.nomeOriginalArquivo[index]}
                        </a>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>

        {userAccessLevel === 'franqueado' && (
          <button id="read" onClick={toggleContainer2}>
            {containerVisible ? 'Ocultar arquivos' : 'Ver arquivos'}
          </button>
        )}

        {userAccessLevel === 'gerente' && (
          <button id="read" onClick={toggleContainer3}>
            {containerVisible ? 'Ocultar arquivos' : 'Ver arquivos'}
          </button>
        )}

        {userAccessLevel === 'colaborador' && (
          <button id="read" onClick={toggleContainer}>
            {containerVisible ? 'Ocultar arquivos' : 'Ver arquivos'}
          </button>
        )}
      </div>
    </div>
  );
}

export default ArquivoContentToggle;

