import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
  overflow-y: auto;
`;

export const TreinamentoContainer = styled.div`
  margin-top:10%;
  display: flex;
  width: 85%;
  height: 18%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  justify-content: space-between;
  .treinamento-info {

    width: 120%;
    height: 120%;

    width:100%;
    width: 100%;

    display: flex;
   justify-content: space-evenly;
    
    .info {
      .title {
        font-size:14px;
        font-weight: bold;

        color: #000;

        color:black;
        margin-top: -1%;
        

      }
     
    }
  }

  .image{
    margin-right: 30px;
  }

  .buttons {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    svg {
      cursor: pointer;
      color: #303b92;
      transition: 0.4s;
      &:hover {
        transform: scale(1.02);
        color: #000;
      }
    }
  }

  
`;
