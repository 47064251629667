import { IconButton } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import mercadoticaLogo from '../../../public/assets/avatar.png';
import Alert from '../../components/Alert';
import { client } from '../../constants/client';
import { userAction } from '../../redux/actions/index.action';
import { Button, Input, LoginContainer } from './styles';

export function Login() {
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userLogged = JSON.parse(localStorage.getItem('userLoggedIntra') || '{}');
    if (userLogged.name) {
      dispatch(userAction(userLogged));
      navigate('/home');
    }
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    console.log('Submit');

    try {
      const response = await client.post('/usuarios/login', { usuario, senha });

      if (response) {
        console.log(response);
        dispatch(userAction(response.data));
        localStorage.setItem('userLoggedIntra', JSON.stringify(response.data));
        navigate('/home');
      } else {
        console.log(`Erro: ${response}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(`Erro: ${error}`);
      setOpenAlert(true);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <LoginContainer>
      {openAlert && <Alert title="Erro de login" description="Login ou senha incorretos" onClose={handleClose} />}
      <img src="assets/oculos.jpeg" alt="logo" className="logo" />
      <div className="login">
        <h2>Intranet</h2>
        <img
          src={mercadoticaLogo}
          style={{ width: '5%', height: '10%', position: 'absolute', top: '15px', right: '2%' }}
          alt="logo mercadotica"
          className='logo-mercadotica'
        />
        <form onSubmit={handleSubmit} className="container">
          <label htmlFor="name">Entre com usuário e senha para iniciar a sessão</label>
          <Input id="name" placeholder="Login" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
          <Input placeholder="Senha" type="password" value={senha} onChange={(e) => setSenha(e.target.value)} />
          <Button>{!loading ? 'Entrar' : <div className="loader" />}</Button>
        </form>
      </div>
    </LoginContainer>
  );
}
