import { BsFillArrowRightCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import ReactQuill from "react-quill";
import { useEffect, useState } from 'react';

function FAQContentToggle({ faq }) {
  const [containerVisible, setContainerVisible] = useState(false);

  function toggleContainer() {
    setContainerVisible(!containerVisible);
  }

  return (
    <div>
      <div>
        {containerVisible && (
          <div id="conteudo">
            {/* Exibindo a resposta escrita */}
            {faq.resposta && (
              <ReactQuill
                style={{ height: '100px', width: '50%' }}
                placeholder='Resposta escrita caso seja preciso'
                readOnly={true}
                value={faq.resposta}
                modules={{ toolbar: false }}
              />
            )}
            <br />

            {/* Iterando sobre os URLs dos arquivos */}
            {faq.urlArquivo.map((url, index) => {
              const isFileAvailableForDownload = faq.nomeOriginalArquivo[index];

              if (isFileAvailableForDownload) {
                return (
                  <div key={index}>
                    <a
                      className='name-arq'
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={`${faq.nomeOriginalArquivo[index]}`}
                    >
                       {faq.nomeOriginalArquivo[index]}<p className='cor-arqui'>.</p>
                    </a>
                  </div>
                );
              }
            })}
          </div>
        )}
        <button id="read" onClick={toggleContainer}>
          {containerVisible ? <BsFillArrowUpCircleFill className='seta-cima' /> : <BsFillArrowRightCircleFill className='seta-lado' />}
        </button>
      </div>
    </div>
  );
}

export default FAQContentToggle;

  