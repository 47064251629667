import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ChangeEvent, FormEvent, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery, useQueryClient } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';


import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { CustomButton } from '../../../components/CustomButton';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getFotos, getMidia } from '../../../services/api';
import { storage } from '../../../services/firebase';
import { client } from '../../../services/instance';
import { ImageContainer } from './styles';


import { BannerContainer, Container } from '../banners/styles';
import { AiFillFolderOpen } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';


interface Fotos {
  id: number,
  nomeAlbum: string,
  resumo: string,
  date: Date,
  status: string,
  urlArquivo: string[],
  descricao: string,
  nomeOriginalArquivo: string[],
}



export function FotosAdminPage() {
  const [values, setValues] = useState({
    id: 0,
    nomeAlbum: '',
    resumo: '',
    date: new Date(),
    status: 'ativo',
    descricao: '',
    urlArquivo: [''],
    nomeOriginalArquivo: [''],



  });



  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [pastaCounts, setPastaCounts] = useState<{ [key: string]: number }>({});
  const [editedItem, setEditedItem] = useState<Fotos | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Fotos | null>(null);




  const { data: fotos, refetch } = useQuery('foto', () => getFotos().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  // Ordenar os albuns por data, do mais recente para o mais antigo
  const sortedFotos = fotos
    ? [...fotos].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    : [];


    useEffect(() => {
      document.title = `(${notification}) Intranet - Mercadotica`;
    }, [notification]);
  

  useEffect(() => {
    // Inicialize o contador de arquivos para cada pasta
    const counts: { [key: string]: number } = {};
    if (fotos) {
      fotos.forEach((item) => {
        counts[item.nomeAlbum] = item.urlArquivo.length;
      });
    }
    setPastaCounts(counts);
  }, [fotos]);


  const handleFileUploadProgress = (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress);
  };



  // Função para formatar a data no formato "dd/MM/yyyy"
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // Função para lidar com a seleção da data
  const handleDateChange = (date) => {
    setValues({ ...values, date: date });
  };

  const handleImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...values.urlArquivo];
    const updatedNomeOriginalArquivo = [...values.nomeOriginalArquivo];

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `foto/${values.nomeAlbum}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));

      setValues({
        ...values,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });
    } catch (error) {
      alert(error);
    }
  };





  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = values;

    data.urlArquivo.push(imageUrl)
    console.log(data);
    client
      .post('/foto/create', {
        ...values,
        imageURL: imageUrl,
        data
      })
      .then((res) => {
        alert('album  cadastrado com sucesso!');

        refetch();
      });


  };






  const onDelete = (id: number) => {
    client.delete(`/foto/delete/${id}`).then((res) => {
      console.log(res);
      alert('album deletada com sucesso!');
      refetch();
    });
  };



  // Novo método para excluir um arquivo da pasta selecionada
  const deleteFile = (indexToDelete: number) => {
    if (selectedFolder) {
      const updatedUrlArquivo = [...selectedFolder.urlArquivo];
      const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];

      // Remova o arquivo da lista com base no índice
      updatedUrlArquivo.splice(indexToDelete, 1);
      updatedNomeOriginalArquivo.splice(indexToDelete, 1);

      // Atualize o estado da pasta selecionada com as listas atualizadas
      setSelectedFolder({
        ...selectedFolder,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

      // Atualize o contador de arquivos da pasta
      setPastaCounts({
        ...pastaCounts,
        [selectedFolder.nomeAlbum]: updatedUrlArquivo.length,
      });
    }
  };



  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        nomeAlbum: values.nomeAlbum,
        resumo: values.resumo,
        date: values.date,
        status: values.status = 'true' ? 'ativo' : 'inativo',
        descricao: values.descricao,
        urlArquivo: selectedFolder.urlArquivo,
        nomeOriginalArquivo: selectedFolder.nomeOriginalArquivo,
      };



      try {
        const response = await client.put(`/foto/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };

  const handleEdit = (foto: Fotos) => {
    setIsEditing(true);
    setEditedItem(foto);

    // Preencha o formulário de edição com os dados do arquivo selecionado

    setValues({
      ...values,
      nomeAlbum: foto.nomeAlbum,
      resumo: foto.resumo,
      date: new Date(foto.date),
      status: foto.status ? 'true' : 'false',
      descricao: foto.descricao,
      urlArquivo: foto.urlArquivo,
      nomeOriginalArquivo: foto.nomeOriginalArquivo,
    });


    // Defina a pasta selecionada
    setSelectedFolder(foto);

     // Scrolla a página para o topo de forma suave
     window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };





  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    // Limpe os campos do formulário
    setValues({
      id: 0,
      nomeAlbum: '',
      resumo: '',
      date: new Date(),
      status: 'ativo',
      descricao: '',
      urlArquivo: [''],
      nomeOriginalArquivo: [''],
    });

    // Limpe a pasta selecionada
    setSelectedFolder(null);
  };


  const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!selectedFolder || !e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...selectedFolder.urlArquivo];
    const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];
    setSelectedFolder

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `foto/${selectedFolder.nomeAlbum}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));

      // Atualize o estado da pasta selecionada com os novos arquivos
      setSelectedFolder({
        ...selectedFolder,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

      // Atualize o contador de arquivos da pasta
      setPastaCounts({
        ...pastaCounts,
        [selectedFolder.nomeAlbum]: updatedUrlArquivo.length,
      });

    } catch (error) {
      alert(error);
    }
  };







  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar álbum de fotos</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomeAlbum">Nome do Álbum:</label>
                  <input
                    type="text"
                    name="nomeAlbum"
                    value={values.nomeAlbum}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
            
              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.date}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomeAlbum">Nome do Álbum:</label>
                  <input
                    type="text"
                    name="nomeAlbum"
                    value={values.nomeAlbum}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              



              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.date}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="arquivo">Arquivo:</label>
                  <input

                    type="file"
                    name="arquivo"
                    onChange={handleImage}
                    style={{ width: '200%' }}

                    multiple />
                  <span>(50 arquivos no máximo)</span>
                  {progress > 0 && <progress value={progress} max="100" />}
                </div>
              </div>

              <div className='button'>
                <CustomButton type="submit" text="Cadastrar" />
              </div>


            </form>
          )}
        </section>

        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
{selectedFolder && (
  <section className='arq-att'>
    <h2 className='topo'>Arquivos em : {selectedFolder.nomeAlbum}</h2>
    <ul className='lista'>
      {selectedFolder.nomeOriginalArquivo.map((fileUrl, index) => (
        fileUrl && ( // Verifica se o fileUrl não é vazio
          <li key={index}>
            <BsFillTrashFill
              className='lixo'
              size={20}
              onClick={() => deleteFile(index)}
            />
            <AiFillFolderOpen className='icon-arq' />
            <a
              className='arqui'
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedFolder.nomeOriginalArquivo[index]}
            </a>
          </li>
        )
      ))}
    </ul>

    <div className="input-group-">
      <label htmlFor="arquivo">Adicionar novos arquivos:</label>
      <input
        type="file"
        name="arquivo"
        onChange={handleAddFiles}
        style={{ width: '200%' }}
        multiple
      />
      {progress > 0 && <progress value={progress} max="100" />}
    </div>
    <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
    <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>
  </section>
)}



        <section className="gerenciar-2">
          <h1>Gerenciar álbuns</h1>
          <Container >
            {sortedFotos?.map((foto) => (
              <BannerContainer key={foto.id} ativo={Boolean(foto.status)} className='arquivo'>
                <div className="row">
                  <div>
                    <h2 className='titulo'>{foto.nomeAlbum}</h2>
                    <span>{formatDate(new Date(foto.date))}</span><br></br>
                    <h3 className='numero-arq'>Número de fotos: {foto.nomeOriginalArquivo.length - 1}</h3>

                  </div>

                  <div className="status-2">
                    <div className={foto.status ? 'ativo' : 'inativo'}>{foto.status ? 'Ativo' : 'Inativo'}</div>
                  </div>

                  <div className="buttons">

                    <BsFillTrashFill size={20} onClick={() => onDelete(foto.id)} />
                    <BsFillPencilFill size={20} onClick={() => handleEdit(foto)} />
                  </div>
                </div>
              </BannerContainer>
            ))}
          </Container>
        </section>
      </div>
    </AdminPageContainer>
  );
}
