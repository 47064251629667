import styled from '@emotion/styled';

export const Button = styled.button`
  width: 80%;
  height: 3rem;
  background: #303b92;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .loader {
    border: 5px solid #f3f3f3; /* cor do loader */
    border-top: 5px solid #3498db; /* cor da parte de cima do loader */
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
