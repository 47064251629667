import { QUESTION } from '../actions/actions.type';

const INITIAL_STATE = {
  question: '',
};

function questionReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    default:
      return state;
  }
}

export default questionReducer;
