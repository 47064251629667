import React, { useState, useEffect } from "react";
import './styles.css'
import { RespostaInterface } from "../interfaces/RespostaInterface";
import { useSelector } from 'react-redux';
import { RootState } from "../redux/stores";

interface EnqueteProps {
  respostas: RespostaInterface[];
  pesquisaId: number;
  pergunta: string;
}

const Enquete = ({ respostas, pesquisaId, pergunta }: EnqueteProps) => {
  const [votoSelecionado, setVotoSelecionado] = useState<number | null>(null);
  const [respostaVotadaId, setRespostaVotadaId] = useState<number | null>(null);
  const [votos, setVotos] = useState<Record<number, number[]>>({});
  const [votoRegistrado, setVotoRegistrado] = useState<boolean>(false);
  const [usuarioJaVotouNaPesquisa, setUsuarioJaVotouNaPesquisa] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [showVotarPopup, setShowVotarPopup] = useState(false);
  const [showResultadoPopup, setShowResultadoPopup] = useState(false);
  const [showVotoPopup, setShowVotoPopup] = useState(false);
  const [porcentagens, setPorcentagens] = useState<string[]>([]);
  const [totalVotos, setTotalVotos] = useState<number>(0);

  useEffect(() => {
    const totalVotosCalculado = Object.values(votos).reduce((acc, v) => acc + v.length, 0);
    setTotalVotos(totalVotosCalculado);
  }, [votos]);

  useEffect(() => {

    const usuarioVotouNaPesquisa = localStorage.getItem(`votoRegistrado_${user.id}_${pesquisaId}`);
    setUsuarioJaVotouNaPesquisa(Boolean(usuarioVotouNaPesquisa));

    //metódo de armazenamento dos votos
    const votosArmazenados: Record<number, number[]> = {};
    for (let i = 0; i < respostas.length; i++) {
      const votoUsuario = localStorage.getItem(`opcaoVotada_${pesquisaId}_${i}`);
      if (votoUsuario !== null) {
        votosArmazenados[i] = JSON.parse(votoUsuario);
      }
    }
    setVotos(votosArmazenados);
  }, [user.id, pesquisaId, respostas.length]);

  useEffect(() => {
    const totalVotos = Object.values(votos).reduce((acc, v) => acc + v.length, 0);
    const novasPorcentagens = respostas.map((resposta, index) =>
      calcularPorcentagem(votos[index]?.length || 0, totalVotos)
    );
    setPorcentagens(novasPorcentagens);
  }, [votos, respostas]);

  const handleVoto = (respostaId: number) => {
    setVotoSelecionado(respostaId);
    setRespostaVotadaId(respostas[respostaId].id);
  };

  const handleVotar = () => {
    //metódo para armazenar o voto do usuário caso ele esteja votando pela primeira vez
    if (!votoRegistrado && votoSelecionado !== null && !usuarioJaVotouNaPesquisa) {
      console.log("Voto armazenado:", votoSelecionado);

      const novosVotos = { ...votos };
      novosVotos[votoSelecionado] = [...(novosVotos[votoSelecionado] || []), user.id];
      setVotos(novosVotos);

      setVotoRegistrado(true);
      setUsuarioJaVotouNaPesquisa(true);

      //essa linha armazena  a info de que o usuario com ID 'user.id' ja votou na 'pesquisaid'
      localStorage.setItem(`votoRegistrado_${user.id}_${pesquisaId}`, "true");
      //verifica se a opçao votada tem um id válido e armazena a opção votada na 'pesquisaid '
      if (respostaVotadaId !== null) {
        localStorage.setItem(`opcaoVotada_${pesquisaId}_${votoSelecionado}`, JSON.stringify(novosVotos[votoSelecionado]));
      }
      //caso seja um novo voto ,entao a mesagem do setshowvotopopup é passada como verdadeiro
      setShowVotoPopup(true);

      // Recalcular porcentagens após o voto
      const totalVotos = Object.values(novosVotos).reduce((acc, v) => acc + v.length, 0);
      const novasPorcentagens = respostas.map((resposta, index) =>
        calcularPorcentagem(novosVotos[index]?.length || 0, totalVotos)
      );
      setPorcentagens(novasPorcentagens);
    } else {
      setShowVotarPopup(true);
    }
  };

  const handleParcial = () => {
    setShowResultadoPopup(true);
  };

  const closeVotarPopup = () => {
    setShowVotarPopup(false);
  };

  const closeVotoPopup = () => {
    setShowVotoPopup(false);
  };

  const closeResultadoPopup = () => {
    setShowResultadoPopup(false);
  };

  const calcularPorcentagem = (votosOpcao: number, totalVotos: number) => {
    return totalVotos === 0 ? '0.00' : ((votosOpcao / totalVotos) * 100).toFixed(0);
  };

  return (
    <div>
      <form>
        {respostas && respostas.map((resposta, index) => (
          <div key={resposta.id} className="respostaItem">
            <div className="radios">
              <input
                type="radio"
                name="opcoes"
                onChange={() => handleVoto(index)}
                checked={votoSelecionado === index}
                disabled={votoRegistrado || usuarioJaVotouNaPesquisa}
                id={`${resposta.id}`}
              />
            </div>
            <div className="labels">
              <label>- {resposta.resposta}</label>
            </div>
          </div>
        ))}
        <input type="button" value='VOTAR' className="buttonVoto" onClick={handleVotar} />
        <input type="button" value='PARCIAL' className="buttonParcial" onClick={handleParcial} />
      </form>

      { /*metodo para que a apareça a porcentagem de votos ao clicar em parcial */}
      {showResultadoPopup && (
        <div className='pop-up-container'>
          <div className="pop-up">
            <div className="popup-content">
              <h2>ENQUETE</h2>
              <h5>{pergunta}</h5>
              {respostas.map((resposta, index) => (
                <h5 key={resposta.id} className="respostas">
                  {resposta.resposta}: {porcentagens[index]}%
                  
                </h5>
                
              ))}
              <h5 style={{textAlign:'center', marginTop:'10%'}}> Votos : {totalVotos}</h5>
            </div>
            <button onClick={closeResultadoPopup}>fechar</button>
          </div>
        </div>
      )}
      { /*metodo para que a apareça a mensagem caso o usuário clique em votar e a mesma já tenha votado  */}
      {showVotarPopup && (
        <div className='pop-up-container'>
          <div className="pop-up">
            <div className="popup-content">
              <p>Você já votou nessa pesquisa!</p>
            </div>
            <button onClick={closeVotarPopup}>fechar</button>
          </div>
        </div>
      )}
      { /*metodo para que a apareça a mensagem  após o usuário clicar em votar e a mesma ainda não tivesse votado */}
      {showVotoPopup && (
        <div className='pop-up-container'>
          <div className="pop-up">
            <div className="popup-content">
              <p>Voto computado com sucesso!</p>
            </div>
            <button onClick={closeVotoPopup}>fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Enquete;
