import styled from '@emotion/styled';

export const PageContainer = styled.div`
background-image: url('assets/banner\ 12.jpg');
 background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  
  position: relative;
  min-height: 100vh;
  .title {
    margin-top: 13%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 250%;
    
   
   
    .card {
      width: calc(25.25% - 5px);
      height: 130%;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 10px 10px 1rem #ccc;
      transition: 0.3s;
     
      background-color: ghostwhite;
      
      &:hover {
        transform: scale(1.01);
      }
      .box {
        width: 40%;
        height: 100%;
        background-color: #ccc;
      }

      .treinamento {
        padding: 0.5rem;
        
      }

       img{
        width: 100%;
        height: 100%;
    
           &:hover {
        transform: scale(1.00);
      }
        
      }

      p {
          color: #808080;
          text-align: center;
        }

  a{
        color: black;
         text-decoration:none;
         text-align: center;
            &:hover {
       color:#303b92;
       
       
      }
      }
   h2{
        text-align: center;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      }
     
  }
  .rodape {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.coress{
  text-align: center;
}

}

.Search{
        font-size: 17px;
  margin-top: 10%;
  margin-left: 40%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);


      }
`;
