import styled from '@emotion/styled';

export const Container = styled.div<{ author?: boolean }>`
  width: 100%;
  height: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;



  


.message{
 display: flex;
  width: fit-content;
  padding: 1rem;
  border-radius: 0px;
  box-shadow: 0 -5px 5px -5px black;
  color: #282a36;
 }



`;

export const MessageContent = styled.div<{ author?: boolean }>`
  margin-top: 1rem;
  display: flex;
  width: ${({ author }) => (author ? '50%' : '24%')}; /* Ajuste as porcentagens conforme necessário */


  @media (max-width: 1366px) {
    width: ${({ author }) => (author ? '56%' : '30%')}; /* Ajuste as porcentagens para telas pequenas */
  }

  justify-content: ${({ author }) => (author ? 'flex-end' : 'flex-start')};


  .message {
    display: flex;
    width: fit-content;
    padding: 1rem;
    border-radius: 10px;
    background: #d3d3d3;
    box-shadow: 0 0 5px 0 grey;
    color: #282a36;
   
    
  }


  .mensagem{
  width: fit-content;
  padding: 1rem;
  border-radius: 10px;
  background: ${({ author }) => (author ? '#303b92' : '#d3d3d3')};
  box-shadow: 0 0 5px 0 grey;
  color:  ${({ author }) => (author ? 'white' : '#282a36')};
  margin-bottom: 30px;
  text-align: start;
 }
.linkss{
    color:  ${({ author }) => (author ? 'white' : '#282a36')};
}

 .file{
  color: ${({ author }) => (author ? '#d3d3d3' : '#303b92')};
 }
`;



export const MessageContainer = styled.div`
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const ChatFeed = styled.div`
  width: 400%;
  margin-left: -80%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;

  button {
    border: none;
    background: red;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 5px;
    height: 5%;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    position: flex;
    top: 1rem;
    right: 1rem;
    color: #fff;
  }
`;
