import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../services/firebase';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getTreinamentos } from '../../../services/api';
import { client } from '../../../services/instance';
import { Container, TreinamentoContainer } from './styles';
import './styles.css';
import { AiFillFolderOpen } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';


// Componente para lidar com o upload de imagens
function ImageUpload({ onImageUpload }) {
  const [progress, setProgress] = useState(0);

  const handleImage = (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) return;
    const storageRef = ref(storage, `treinamentos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const newProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(newProgress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          onImageUpload(url);
        });
      }
    );
  };

  return (
    <div className="input-group-2">
      <label htmlFor="midia">Thumb:</label>
      <input type="file" name="midia" onChange={handleImage} style={{ width: '200%' }} />
      <h5>(apenas fotos com resoluções maiores ou iguais a 1024x1024)</h5>
      {progress > 0 && <progress value={progress} max="100" />}
    </div>
  );
}



interface Treinamento {
  id: number,
  nomeTreinamento: string,
  tipo: string,
  instrutor: string,
  cargaHoraria: string,
  limiteParti: string,
  localTreinamento: string,
  visivelUnidades: string,
  linkExterno1: string,
  linkExterno2: string,
  linkExterno3: string,
  linkExterno4: string,
  linkExterno5: string,
  linkExterno6: string,
  dataLimite: Date,
  thumb: string[],
  nomeOriginalArquivo: string[]

}


export function TreinamentosAdminPage() {
  const [values, setValues] = useState({
    id: 0,
    thumb: [''],
    nomeOriginalArquivo: [''],
    nomeTreinamento: '',
    tipo: '',
    instrutor: '',
    cargaHoraria: '',
    limiteParti: '',
    localTreinamento: '',
    visivelUnidades: 'sim',
    linkExterno1: '',
    linkExterno2: '',
    linkExterno3: '',
    linkExterno4: '',
    linkExterno5: '',
    linkExterno6: '',
    dataLimite: new Date(),
  });

  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState(['']);
  const [editedItem, setEditedItem] = useState<Treinamento | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  const [searchResults, setSearchResults] = useState<Treinamento[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Treinamento | null>(null);


  const { data: treinamentos, refetch } = useQuery(
    'treinamentos',
    () => getTreinamentos().then((response) => response?.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Ordenar os treinos por data, do mais recente para o mais antigo
  const sortedTreinamentos = treinamentos
    ? [...treinamentos].sort((b, a) => new Date(b.dataLimite).getTime() - new Date(a.dataLimite).getTime())
    : [];


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);

  const handleDateChange = (date) => {
    setValues({ ...values, dataLimite: date });
  };

  const onImageUpload = (url) => {
    setImageUrl(url);
  };

  const handleFileUploadProgress = (snapshot: any) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress);
  };

  const handleImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...values.thumb];
    const updatedNomeOriginalArquivo = [...values.nomeOriginalArquivo];

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `treinamentos/${values.nomeTreinamento}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));
      setValues({
        ...values,
        thumb: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

    } catch (error) {
      alert(error);
    }
  };

  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = values;
    data.thumb = imageUrl;

    client
      .post('/treinamentos/create', {
        ...values,
        thumb: imageUrl,
        data,
      })
      .then((res) => {
        alert('Treinamento cadastrado com sucesso!');
        refetch();
      });
  };

  const onDelete = async (id: number) => {
    try {
      const response = await client.delete(`/treinamentos/delete/${id}`);
      console.log(response);
      alert('Treinamento deletado com sucesso!');
      refetch();
    } catch (error) {
      console.error(error);
      alert('Erro ao deletar treinamento.');
    }
  };

  // Novo método para excluir um arquivo da pasta selecionada
  const deleteFile = (indexToDelete: number) => {
    if (selectedFolder) {
      const updatedUrlArquivo = [...selectedFolder.thumb];
      const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];

      // Remova o arquivo da lista com base no índice
      updatedUrlArquivo.splice(indexToDelete, 1);
      updatedNomeOriginalArquivo.splice(indexToDelete, 1);

      // Atualize o estado da pasta selecionada com as listas atualizadas
      setSelectedFolder({
        ...selectedFolder,
        thumb: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });


    }
  };





  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        nomeTreinamento: values.nomeTreinamento,
        tipo: values.tipo,
        instrutor: values.instrutor,
        cargaHoraria: values.cargaHoraria,
        limiteParti: values.limiteParti,
        localTreinamento: values.localTreinamento,
        visivelUnidades: values.visivelUnidades,
        linkExterno1: values.linkExterno1,
        linkExterno2: values.linkExterno2,
        linkExterno3: values.linkExterno3,
        linkExterno4: values.linkExterno4,
        linkExterno5: values.linkExterno5,
        linkExterno6: values.linkExterno6,
        dataLimite: values.dataLimite,
        thumb: values.thumb,
        nomeOriginalArquivo: selectedFolder.nomeOriginalArquivo,
      };



      try {
        const data = values;
        data.thumb = imageUrl;


        editedData.thumb = imageUrl;
        const response = await client.put(`/treinamentos/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };

  const handleEdit = (treinamentos: Treinamento) => {
    setIsEditing(true);
    setEditedItem(treinamentos);

    setValues({
      ...values,
      nomeTreinamento: treinamentos.nomeTreinamento,
      instrutor: treinamentos.instrutor,
      cargaHoraria: treinamentos.cargaHoraria,
      limiteParti: treinamentos.limiteParti,
      localTreinamento: treinamentos.localTreinamento,
      visivelUnidades: 'sim',
      linkExterno1: treinamentos.linkExterno1,
      linkExterno2: treinamentos.linkExterno2,
      linkExterno3: treinamentos.linkExterno3,
      linkExterno4: treinamentos.linkExterno4,
      linkExterno5: treinamentos.linkExterno5,
      linkExterno6: treinamentos.linkExterno6,
      thumb: treinamentos.thumb,
      nomeOriginalArquivo: treinamentos.nomeOriginalArquivo,
      dataLimite: new Date(treinamentos.dataLimite),
      tipo: treinamentos.tipo ? 'urgente' : 'nurgente',
    });

    setSelectedFolder(treinamentos);
    // Scrolla a página para o topo de forma suave
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    setValues({
      id: 0,
      thumb: [''],
      nomeOriginalArquivo: [''],
      nomeTreinamento: '',
      tipo: '',
      instrutor: '',
      cargaHoraria: '',
      limiteParti: '',
      localTreinamento: '',
      visivelUnidades: 'sim',
      linkExterno1: '',
      linkExterno2: '',
      linkExterno3: '',
      linkExterno4: '',
      linkExterno5: '',
      linkExterno6: '',
      dataLimite: new Date(),

    });

    setSelectedFolder(null);
  };


  const formatDate = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);


    if (term === '') {
      setSearchResults(treinamentos); // Mostrar todos os treinamentos se não houver termo de busca
    } else {
      const filteredResults = treinamentos?.filter((treinamento) =>
        treinamento.nomeTreinamento.toLowerCase().includes(term.toLowerCase())

      );
      setSearchResults(filteredResults || []);


    }

  };


  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white;font-size:15px">${match}</span>`);
  };






  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastro de Treinamentos</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomeTreinamento">Nome do treinamento:</label>
                  <input
                    type="text"
                    name="nomeTreinamento"
                    value={values.nomeTreinamento}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="tipo">Tipo</label>

                  <div style={{ width: '200%' }}>
                    <select name="tipo" id="tipo" onChange={handleInputChange} style={{ width: '20%', border: '1px solid #C0C0C0', borderRadius: '5px', }}>
                      <option value="urgente">Urgente</option>
                      <option value="nurgente">Não urgente</option>
                    </select>
                  </div>

                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="instrutor">Instrutor:</label>
                  <input
                    type="text"
                    name="instrutor"
                    value={values.instrutor}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="cargaHoraria">Carga horária:</label>
                  <input
                    type="text"
                    name="cargaHoraria"
                    value={values.cargaHoraria}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="limiteParti"> N.ºparticipantes:</label>
                  <input
                    type="text"
                    name="limiteParti"
                    value={values.limiteParti}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="localTreinamento">Local de Treinamento:</label>
                  <input
                    type="text"
                    name="localTreinamento"
                    value={values.localTreinamento}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>

                <div className="input-group-2">
                  <label htmlFor="visivelUnidades">Visível :</label>
                  <select
                    name="visivelUnidades"
                    id="visivelUnidades"
                    value={values.visivelUnidades}
                    onChange={handleInputChange}
                  >
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="dataLimite">Data:</label>
                  <DatePicker
                    selected={values.dataLimite}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                  <label htmlFor="linkExterno1">Link Externo 1:</label>
                  <input
                    type="text"
                    name="linkExterno1"
                    value={values.linkExterno1}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno2">Link Externo 2:</label>
                  <input
                    type="text"
                    name="linkExterno2"
                    value={values.linkExterno2}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno3">Link Externo 3:</label>
                  <input
                    type="text"
                    name="linkExterno3"
                    value={values.linkExterno3}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno4">Link Externo 4:</label>
                  <input
                    type="text"
                    name="linkExterno4"
                    value={values.linkExterno4}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno5">Link Externo 5:</label>
                  <input
                    type="text"
                    name="linkExterno5"
                    value={values.linkExterno5}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno6">Link Externo 6:</label>
                  <input
                    type="text"
                    name="linkExterno6"
                    value={values.linkExterno6}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>

              </div>

            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomeTreinamento">Nome do treinamento:</label>
                  <input
                    type="text"
                    name="nomeTreinamento"
                    value={values.nomeTreinamento}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="tipo">Tipo</label>

                  <div style={{ width: '200%' }}>
                    <select name="tipo" id="tipo" onChange={handleInputChange} style={{ width: '20%', border: '1px solid #C0C0C0', borderRadius: '5px', }}>
                      <option value="urgente">Urgente</option>
                      <option value="nurgente">Não urgente</option>
                    </select>
                  </div>

                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="instrutor">Instrutor:</label>
                  <input
                    type="text"
                    name="instrutor"
                    value={values.instrutor}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="cargaHoraria">Carga horária:</label>
                  <input
                    type="text"
                    name="cargaHoraria"
                    value={values.cargaHoraria}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="limiteParti">N.ºparticipantes:</label>
                  <input
                    type="text"
                    name="limiteParti"
                    value={values.limiteParti}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">

                  <label htmlFor="localTreinamento">Local de Treinamento:</label>
                  <input
                    type="text"
                    name="localTreinamento"
                    value={values.localTreinamento}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>

                <div className="input-group-2">

                  <label htmlFor="visivelUnidades">Visível para as unidades:</label>
                  <select
                    name="visivelUnidades"
                    id="visivelUnidades"
                    value={values.visivelUnidades}
                    onChange={handleInputChange}
                  >
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="dataLimite">Data:</label>
                  <DatePicker
                    selected={values.dataLimite}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                  <label htmlFor="linkExterno1">Link Externo 1:</label>
                  <input
                    type="text"
                    name="linkExterno1"
                    value={values.linkExterno1}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />




                  <label htmlFor="linkExterno2">Link Externo 2:</label>
                  <input
                    type="text"
                    name="linkExterno2"
                    value={values.linkExterno2}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno3">Link Externo 3:</label>
                  <input
                    type="text"
                    name="linkExterno3"
                    value={values.linkExterno3}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno4">Link Externo 4:</label>
                  <input
                    type="text"
                    name="linkExterno4"
                    value={values.linkExterno4}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno5">Link Externo 5:</label>
                  <input
                    type="text"
                    name="linkExterno5"
                    value={values.linkExterno5}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="linkExterno6">Link Externo 6:</label>
                  <input
                    type="text"
                    name="linkExterno6"
                    value={values.linkExterno6}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />




                </div>




              </div>
              <div>
                <ImageUpload onImageUpload={onImageUpload} />
              </div>

              <button type="submit">Cadastrar</button>
            </form>
          )}
        </section>
        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Arquivos em : {selectedFolder.nomeTreinamento}</h2>
            <ul className='lista'>
              {selectedFolder.nomeOriginalArquivo.slice(0).map((fileUrl, index) => (
                <ul key={index}>
                  <BsFillTrashFill
                    className='lixo'
                    size={20}
                    onClick={() => deleteFile(index)}
                  />
                  <AiFillFolderOpen className='icon-arq' />
                  <a
                    className='arqui'
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedFolder.nomeOriginalArquivo[index]}
                  </a>
                </ul>
              ))}
            </ul>


            <div className="input-group-">
              <ImageUpload onImageUpload={onImageUpload} />
            </div>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}

        <section className="gerenciar-2">
          <h1>Gerenciar Treinamentos</h1>

          <Container>
            <input
              type="text"
              className="Search"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleSearchInput}
              style={{ border: ' 2px solid #c0c0c0' }}
            />

            {searchResults.length > 0 ? (
              searchResults.map((treinamento) => (
                <TreinamentoContainer key={treinamento.id} className="quest-container">

                  <div className="treinamento-info">
                    <div className="info">
                      <p className='title' dangerouslySetInnerHTML={{ __html: highlightText(treinamento.nomeTreinamento, searchTerm) }} />
                      <p className='date-treino'> {formatDate(new Date(treinamento.dataLimite))} </p>
                    </div>
                  </div>
                  <div className="buttons">
                    <BsFillTrashFill size={20} onClick={() => onDelete(treinamento.id)} />
                    <BsFillPencilFill size={20} onClick={() => handleEdit(treinamento)} />
                  </div>
                </TreinamentoContainer>
              )).reverse()
            ) : (
              <p>Nenhum arquivo encontrado</p>
            )}
          </Container>
        </section>
      </div>
    </AdminPageContainer>
  );
}      
