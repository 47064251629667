import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleChatAction } from '../../redux/actions/index.action';
import { RootState } from '../../redux/stores';
import { AvatarComponent } from './ChatAvatar';
import { ChatBot } from './ChatBot';
import { ChatEnded } from './ChatEnded';
import { ChatWindow } from './ChatWindow';
import { QuestionWindow } from './QuestionWindow';

import './styles.css';

export function ChatComponent() {
  const [isVisible, setIsVisible] = useState(false);
  const [isChating, setIsChating] = useState(false);
  const [isSuport, setIsSuport] = useState(false);
  const { question } = useSelector((state: RootState) => state.questionReducer);
  const { chatStatus } = useSelector((state: RootState) => state.chatReducer);

  const dispatch = useDispatch();

  const onClick = () => {
    setIsVisible((prevState) => !prevState);
    if (isVisible) {
      setIsChating(false);
      setIsSuport(false);
      dispatch(handleChatAction(''));
    }
  };

  const handleChatting = () => {
    setIsSuport(true);
  };

  const handleSupport = () => {
    setIsChating(true);
  };

  return (
    <div className="chat"
  >
      {isVisible && (
        <div className="chatWindow" style={{ display: 'block', }}>
          {chatStatus === 'ended' ? (
            <ChatEnded onClick={onClick} />
          ) : isSuport ? (
            isChating ? (
              <ChatWindow />
            ) : (
              <ChatBot handleSupport={handleSupport} onclick={onClick}/>
            )
          ) : (
            <QuestionWindow handleChatting={handleChatting} />
          )}
        </div>
      )}

      <AvatarComponent onClick={onClick} />
    </div>
  );
}
