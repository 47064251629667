import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from './redux/stores';
import { AppRoutes } from './routes';

export function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </BrowserRouter>
  );
}
