import { USER } from '../actions/actions.type';

const INITIAL_STATE = {
  user: {},
};

function userReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}

export default userReducer;
