import { CURRENTCHAT } from '../actions/actions.type';

const INITIAL_STATE = {
  currentChat: '',
};

function currentChatReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CURRENTCHAT:
      return {
        ...state,
        currentChat: action.payload,
      };
    default:
      return state;
  }
}

export default currentChatReducer;
