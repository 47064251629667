import { ReactNode } from 'react';

import { SidebarComponent } from '../Sidebar';
import { Main } from './styles';

interface RouteLayoutProps {
  children: ReactNode;
}

export function RouteLayout({ children }: RouteLayoutProps) {
  return (
    <Main>
      <SidebarComponent />
      <div className="node">{children}</div>
    </Main>
  );
}
