import { client } from './instance';

export async function getCalls(): Promise<any> {
  return client.get('/chamados/all');
}

export async function getNews(): Promise<any> {
  return client.get('/noticia/all');
}

export async function getComunicados(): Promise<any> {
  return client.get('/comunicados/all');
}

export async function getBanners(): Promise<any> {
  return client.get('/banners/all');
}

export async function getFranqueados(): Promise<any> {
  return client.get('/franqueados/all');
}

export async function getTreinamentos(): Promise<any> {
  return client.get('/treinamentos/all');
}

export async function getUsuarios(): Promise<any> {
  return client.get('/usuarios/all');
}

export async function getNoticias(): Promise<any> {
  return client.get('/noticia/all');
}

export async function getArquivos(): Promise<any> {
  return client.get('/arquivo/all');
}

export async function getArquivosById(id: number): Promise<any> {
  return client.get(`/arquivo/${id}`);
}

export async function getFotos(): Promise<any> {
  return client.get('/foto/all');
}

export async function getfaq(): Promise<any> {
  return client.get('/Faq/all');
}

export async function getMidia(): Promise<any> {
  return client.get('/midia/all');
}

export async function getPesquisas(): Promise<any> {
  return client.get('/pesquisa/all');


}


export async function getLogs(): Promise<any> {
  return client.get('/logs/all');


}

