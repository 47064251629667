
import { FormEvent, useState } from 'react';
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';

import './styles.css';
import { getPesquisas } from '../../../services/api';
import { client } from '../../../services/instance';

import { useQuery } from 'react-query';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';

interface Pesquisas {
  id: number,
  acesso: string,
  respostas: string[],
  pergunta: string,

}

export function PesquisasPage() {
  const [pergunta, setPergunta] = useState('');
  const [values, setValues] = useState({
    id: 0,
    acesso: '',
    respostas: [''],
    pergunta: '',
  });


  const [editedItem, setEditedItem] = useState<Pesquisas | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Pesquisas | null>(null);

  const { data: pesquisas, refetch } = useQuery('perguntas', () => getPesquisas().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const handleInputChange = (e, index?) => {
    if (e.target.name === 'resposta') {
      const respostas = [...values.respostas];
      respostas[index] = e.target.value;
      setValues({ ...values, respostas });
    } else {
      const { name, value } = e.target;
      setValues({ ...values, [name]: value });
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    client.post('/pesquisa/create', { pergunta, values }).then((res) => {
      console.log(res);
      alert('Pesquisa cadastrada com sucesso!');
      refetch();
    });

    console.log({ ...values });
    setPergunta('');
    setValues({ acesso: '', respostas: [''], id: 0, pergunta: '' });
    refetch();
    // console.log(values);
  };

  const addQuestion = () => {
    if (values.respostas[values.respostas.length - 1] !== '') {
      setValues({ ...values, respostas: [...values.respostas, ''] });
    }
  };

  const removeQuestion = (index: number) => {
    if (index !== 0) {
      const list = [...values.respostas];
      list.splice(index, 1);
      setValues({ ...values, respostas: list });
    }
  };

  const onDelete = (id: number) => {
    client.delete(`/pesquisa/delete/${id}`).then((res) => {
      console.log(res);
      alert(res.data.message);
      refetch();
    });
  };

  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }

    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        acesso: values.acesso,
        respostas: values.respostas,
        pergunta: pergunta,
      };

      try {
        const response = await client.put(`/pesquisa/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit();
          alert('Alterações da edição foram salvas com sucesso!');

        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
      refetch();
    }
  };



  const handleEdit = (pesquisas: Pesquisas) => {
    setIsEditing(true);
    setEditedItem(pesquisas);

    setPergunta(pesquisas.pergunta); // Preenche a pergunta no input
    setValues({
      id: pesquisas.id,
      acesso: pesquisas.acesso,
      respostas: pesquisas.respostas,
      pergunta: pesquisas.pergunta,
    });

    setSelectedFolder(pesquisas);
     // Scrolla a página para o topo de forma suave
     window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };


  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    setValues({
      id: 0,
      acesso: '',
      respostas: [''],
      pergunta: '',
    });

    setSelectedFolder(null);
  };








  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar pesquisa</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="titulo">Pergunta:</label>
                  <input
                    type="text"
                    name="titulo"
                    value={pergunta}
                    onChange={(e) => setPergunta(e.target.value)}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              {values.respostas &&
                values.respostas.map((resposta, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="ask-question" key={index}>
                    <div className="input-group-2">
                      <label htmlFor="resposta">Resposta:</label>
                      <div className="input">
                        <input
                          type="text"
                          name="resposta"
                          value={values.respostas[index]}
                          placeholder={`resposta nº ${index + 1} `}
                          onChange={(e) => handleInputChange(e, index)}
                          style={{ width: '200%' }}
                        />
                        <IoMdAddCircle onClick={addQuestion} />
                        <IoMdRemoveCircle onClick={() => removeQuestion(index)} />
                      </div>
                    </div>
                  </div>
                ))}


            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="titulo">Pergunta:</label>
                  <input
                    type="text"
                    name="titulo"
                    value={pergunta}
                    onChange={(e) => setPergunta(e.target.value)}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              {values.respostas &&
                values.respostas.map((resposta, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="ask-question" key={index}>
                    <div className="input-group-2">
                      <label htmlFor="resposta">Resposta:</label>
                      <div className="input">
                        <input
                          type="text"
                          name="resposta"
                          value={values.respostas[index]}
                          placeholder={`resposta nº ${index + 1} `}
                          onChange={(e) => handleInputChange(e, index)}
                          style={{ width: '200%' }}
                        />
                        <IoMdAddCircle onClick={addQuestion} />
                        <IoMdRemoveCircle onClick={() => removeQuestion(index)} />
                      </div>
                    </div>
                  </div>
                ))}

              <button type="submit" style={{ marginTop: '1rem' }}>
                Cadastrar
              </button>
            </form>
          )}
        </section>
        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Pesquisa : {selectedFolder.pergunta}</h2>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}
        <section className="gerenciar-2">
          <h1>Gerenciar pesquisas</h1>
          {pesquisas?.map((pesquisa, index) => (
            <div key={index} className="quest-container" onClick={() => console.log(pesquisa)}>
              <div className="quest">
                <h2>{pesquisa.pergunta}</h2>
                <div className="quest-answers" />
              </div>
              <div className="buttons">

                <BsFillTrashFill size={20} onClick={() => onDelete(pesquisa.id)} />
                <BsFillPencilFill size={20} onClick={() => handleEdit(pesquisa)} />
              </div>
            </div>
          ))}
        </section>
      </div>
    </AdminPageContainer>
  );
}