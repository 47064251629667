/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { BsFillPersonFill } from 'react-icons/bs';
import { IoChevronForwardSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { sidePageAction } from '../../redux/actions/index.action';
import { RootState } from '../../redux/stores';
import Sidebutton from './Sidebutton';
import { Sidebar } from './styles';
import "./index.css"


export function SidebarComponent() {
  const [logout, setLogout] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.userReducer);

  const changePageReducer = (page: string) => {
    dispatch(sidePageAction(page));
  };

  const handleLogout = () => {
    setLogout((prev) => !prev);
  };

  // Quando o botão estiver checado colocar a cor #303B92

  const logoutFunc = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <Sidebar>
      <div className="top">
        <h1>Intranet</h1>
      </div>

      <section className="admin-buttons">
        <div className="buttons">
          <Sidebutton tittle="Dashboard" onClick={() => navigate('/admin/')} />

          <Sidebutton tittle="Logs" onClick={() => navigate('/admin/logs')} />

          <Sidebutton tittle="FAQ" onClick={() => navigate('/admin/Faq')} />

          <Sidebutton tittle="Arquivos" onClick={() => navigate('/admin/arquivo')} />

          <Sidebutton tittle="Fotos" onClick={() => navigate('/admin/foto')} />

          <Sidebutton tittle="Comunicados" onClick={() => navigate('/admin/comunicados')} />

          <Sidebutton tittle="Chamados" onClick={() => navigate('/admin/chat')} />

          <Sidebutton tittle="Treinamentos" onClick={() => navigate('/admin/treinamentos')} />

          <Sidebutton tittle="Videos" onClick={() => navigate('/admin/midias')} />

          <Sidebutton tittle="Pesquisas" onClick={() => navigate('/admin/pesquisas')} />

          <Sidebutton tittle="Noticias" onClick={() => navigate('/admin/noticias')} />

          <Sidebutton tittle="Usuários" onClick={() => navigate('/admin/usuarios')} />

          <Sidebutton tittle="Relatórios/Calls" onClick={() => navigate('/admin/relatorios')} />



        </div>
      </section>
      <div className="user-container">
        <div className="user-container-2" onClick={() => setLogout((prev) => !prev)}>
          <div className="user-avatar">
            <BsFillPersonFill />
          </div>
          {user.nome}
        </div>
        {logout && (
          <div className="side-logout" onClick={logoutFunc}>
            <BiLogOut />
            Sair
          </div>
        )}
      </div>
    </Sidebar>
  );
}
