import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 90%;
`;

export const FranqueadoContainer = styled.div`
  width: 80%;
  height: 10%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .treinamento-info {
    width: 80%;
    height: 100%;
    display: flex;
  }
  .buttons {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    svg {
      cursor: pointer;
      color: #303b92;
      transition: 0.4s;
      &:hover {
        transform: scale(1.02);
        color: #000;
      }
    }
  }
`;
