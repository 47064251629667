import { USER, QUESTION, SIDE_PAGE, CURRENTCHAT, CHAT, NOTIFICATION, ROOMS,MESSAGE_HISTORY, } from './actions.type';
import { MessageInterface } from '../../interfaces/MessageInterface';

export const userAction = (user: any) => {
  return {
    type: USER,
    payload: user,
  };
};

export const questionAction = (question: string) => {
  return {
    type: QUESTION,
    payload: question,
  };
};

export const currentChatAction = (current: string) => {
  return {
    type: CURRENTCHAT,
    payload: current,
  };
};

export const handleChatAction = (chatStatus: string) => {
  return {
    type: CHAT,
    payload: chatStatus,
  };
};

export const sidePageAction = (page: string) => {
  return {
    type: SIDE_PAGE,
    payload: page,
  };
};

export const notificationAction = (count: number) => {
  return {
    type: NOTIFICATION,
    payload: count,
  };
};

export const roomsAction = (rooms: any) => {
  return {
    type: ROOMS,
    payload: rooms,
  };
};


export const saveMessageHistoryAction = (messageHistory: { [key: string]: MessageInterface[] }) => {
  return {
    type: MESSAGE_HISTORY,
    payload: messageHistory,
  };
};