import { FotoContainer } from './styles';
import React, { useState } from 'react';
import './styles.css';

interface FotosProps {
  title: string;
  description?: string;
  image: string[];
  date: string;
}

export function Fotos({ title, date, image }: FotosProps) {
  const [isTitleVisible, setTitleVisible] = useState(false);

  return (
    <div
      className='container-imagem'
      onMouseEnter={() => setTitleVisible(true)}
      onMouseLeave={() => setTitleVisible(false)}
      style={{ position: 'relative' }}
    >

      <FotoContainer>
        <div className="card-foto">
          {image.length > 0 && (
            <img
            className='img-capa'
              src={image[1]}
              alt={title}
              title={title}
              style={{
                filter: isTitleVisible ? 'brightness(0.5)' : 'brightness(1)',
                transition: 'filter 0.3s', transform: 'scale(1.05)',
                
              }}
            />
          )}
          {isTitleVisible && (
            <div className='foto-titulo' >
              {title}
              <div>
                {date}
              </div>
            </div>
          )}
        </div>
      </FotoContainer>

    </div>

  );
}

