/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormEvent, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';

import './styles.css';
import { ModalOverlay } from '../../../components/Modal/styles';
import { getUsuarios } from '../../../services/api';
import { client } from '../../../services/instance';

import { useQuery } from 'react-query';

import { Container } from '../treinamentos/styles';
import { UsuariosContainer } from './styles';





interface Usuarios {
  id: number,
  admin: boolean,
  usuario: string,
  nome: string,
  senha: string,
  loja: string,
  email: string,
  status: string,
  telefone: string,
  celular: string,
  acesso: string,

}

export function UsuariosPage() {
  const [values, setValues] = useState({
    id: 0,
    admin: true,
    usuario: '',
    nome: '',
    senha: '',
    loja: '',
    email: '',
    status: 'ativo',
    telefone: '',
    celular: '',
    acesso: 'admin',
  });



  const [editedItem, setEditedItem] = useState<Usuarios | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchResults, setSearchResults] = useState<Usuarios[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Usuarios | null>(null);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };


  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white;">${match}</span>`);
  };



  useEffect(() => {
    if (usuarios && usuarios.length > 0) {
      const filteredResults = usuarios.filter((usuarios) =>
        usuarios.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        usuarios.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [, searchTerm]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'admin' && value === 'admin') {
      // Se a opção selecionada for 'admin', defina 'acesso' como 'admin'
      setValues({ ...values, [name]: value, acesso: 'admin' });
    } else {
      // Para outras opções, defina 'acesso' de acordo
      setValues({ ...values, [name]: value, acesso: value });
    }
  };



  const { data: usuarios, refetch } = useQuery('usuarios', () => getUsuarios().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const data = values;
    data.admin = Boolean(data.acesso === "admin")

    client.post('/usuarios/create', data).then((res) => {
      console.log(res);
      alert('Usuário cadastrado com sucesso!');
      setValues({
        id: 0,
        admin: true,
        usuario: '',
        nome: '',
        senha: '',
        loja: '',
        email: '',
        status: 'ativo',
        telefone: '',
        celular: '',
        acesso: 'admin',
      });
      refetch();
    });

    console.log(values);
  };

  const onDelete = (id: number) => {
    client.delete(`/usuarios/delete/${id}`).then((res) => {
      console.log(res);
      alert('Usuário deletado com sucesso!');
      refetch();
    });
  };


  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        email: values.email,
        status: values.status === 'true' ? 'ativo' : 'inativo',
        celular: values.celular,
        usuario: values.usuario,
        nome: values.nome,
        senha: values.senha,
        loja: values.loja,
        telefone: values.telefone,
        acesso: values.acesso,
        admin: values.admin,



      };


      // Atualize o valor de admin com base no valor de acesso
      editedData.admin = editedData.acesso === "admin";
      try {
        const response = await client.put(`/usuarios/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };


  const handleEdit = (usuarios: Usuarios) => {
    setIsEditing(true);
    setEditedItem(usuarios);

    setValues({
      ...values,
      usuario: usuarios.usuario,
      nome: usuarios.nome,
      senha: usuarios.senha,
      loja: usuarios.loja,
      email: usuarios.email,
      status: usuarios.status === 'true' ? 'ativo' : 'inativo',
      telefone: usuarios.telefone,
      celular: usuarios.celular,
      acesso: usuarios.acesso,
    });

    setSelectedFolder(usuarios);

    // Scrolla a página para o topo de forma suave
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adiciona rolagem suave
    });
  };


  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    setValues({
      id: 0,
      admin: true,
      usuario: '',
      nome: '',
      senha: '',
      loja: '',
      email: '',
      status: 'true',
      telefone: '',
      celular: '',
      acesso: 'admin',


    });

    setSelectedFolder(null);
  };










  return (
    <AdminPageContainer padding="0px">
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar usuário</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nome">Nome:</label>
                  <input
                    type="text"
                    name="nome"
                    value={values.nome}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="loja">Loja:</label>
                  <input
                    type="text"
                    name="loja"
                    value={values.loja}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="telefone">Telefone:</label>
                  <input
                    type="text"
                    name="telefone"
                    value={values.telefone}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="celular">Celular:</label>
                  <input
                    type="text"
                    name="celular"
                    value={values.celular}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="usuario">Usuario:</label>
                  <input
                    type="text"
                    name="usuario"
                    value={values.usuario}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="senha">Senha:</label>
                  <input
                    type="text"
                    name="senha"
                    value={values.senha}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '100%' }}>
                    <option value="ativo">Ativo</option>
                    <option value="inativo">Inativo</option>
                  </select>
                </div>

                <div className="input-group-2">
                  <label htmlFor="admin">Nivel de acesso:</label>
                  <select name="admin" value={values.acesso} onChange={handleInputChange} style={{ width: '100%' }}>
                    <option value=".">.</option>
                    <option value="admin">Admin</option>
                    <option value="franqueado">Franqueado</option>
                    <option value="colaborador">Colaborador</option>
                    <option value="gerente">Gerente</option>
                  </select>
                </div>
              </div>

            </form>

          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nome">Nome:</label>
                  <input
                    type="text"
                    name="nome"
                    value={values.nome}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="loja">Loja:</label>
                  <input
                    type="text"
                    name="loja"
                    value={values.loja}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="telefone">Telefone:</label>
                  <input
                    type="text"
                    name="telefone"
                    value={values.telefone}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="celular">Celular:</label>
                  <input
                    type="text"
                    name="celular"
                    value={values.celular}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="usuario">Usuario:</label>
                  <input
                    type="text"
                    name="usuario"
                    value={values.usuario}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="senha">Senha:</label>
                  <input
                    type="text"
                    name="senha"
                    value={values.senha}
                    onChange={handleInputChange}
                    style={{ width: '250%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '100%' }}>
                    <option value="ativo">Ativo</option>
                    <option value="inativo">Inativo</option>
                  </select>
                </div>

                <div className="input-group-2">
                  <label htmlFor="admin">Nivel de acesso:</label>
                  <select name="admin" value={values.acesso} onChange={handleInputChange} style={{ width: '100%' }}>
                    <option value=".">.</option>
                    <option value="admin">Admin</option>
                    <option value="franqueado">Franqueado</option>
                    <option value="colaborador">Colaborador</option>
                    <option value="gerente">Gerente</option>
                  </select>
                </div>
              </div>

              <button type="submit" style={{ marginTop: '1rem' }}  >
                Cadastrar

              </button>
            </form>
          )}
        </section>
        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Usúario : {selectedFolder.usuario}</h2>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}
        <section className="gerenciar-2">
          <h1>Gerenciar Usúarios</h1>

          <Container>
            <input
              type="text"
              className="Search"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleSearchInput}
              style={{ border: '2px solid #c0c0c0' }}
            />
            {searchResults.length > 0 ? (
              searchResults
                .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os usuários alfabeticamente pelo nome
                .map((usuario) => (
                  <UsuariosContainer className="user" key={usuario.id}>
                    <div className="user-info">
                      <div className="info">
                        <p className="title">Nome:</p>
                        <h4 dangerouslySetInnerHTML={{ __html: highlightText(usuario.nome, searchTerm) }} /> <h4>
                          {usuario.loja}
                        </h4>

                      </div>
                      <div className="info">
                        <p className="title">Usuário:</p>
                        <h4>{usuario.usuario}</h4>
                      </div>
                      <div className="info">
                        <p className="title">Acesso:</p>
                        <h4>{usuario.acesso}</h4>
                      </div>
                    </div>

                    <div className="buttonss-">
                      <BsFillTrashFill size={20} onClick={() => onDelete(usuario.id)} />
                      <BsFillPencilFill size={20} onClick={() => handleEdit(usuario)} />
                    </div>
                  </UsuariosContainer>
                ))
            ) : (
              <p>Nenhum usuário encontrado</p>
            )}
          </Container>

        </section>

        {/* <ModalOverlay isOpen>jooj</ModalOverlay> */}
      </div>
    </AdminPageContainer>
  );
}
