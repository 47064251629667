import { useEffect, useState } from 'react';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';
import './styles.css';
import { getLogs } from '../../../services/api';
import { format } from 'date-fns';
import { client } from '../../../services/instance';
import { BsFillTrashFill } from 'react-icons/bs';
import { useQuery } from 'react-query';

interface LogEntry {
  id: number;
  nome: string;
  data: string;
}

export function LogsPage() {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [searchResults, setSearchResults] = useState<LogEntry[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const { refetch } = useQuery('logs', () =>
    getLogs().then((resposta) => resposta?.data),
    {
      onSuccess: (data) => {
        const fetchedLogs = data
          .filter((log: any) => log.usuario?.acesso !== 'admin') // Filtra logs para excluir admin
          .map((log: any) => ({
            id: log.id,
            nome: log.usuario?.nome || 'Desconhecido',
            data: format(new Date(log.createdAt), 'dd/MM/yyyy HH:mm'),
            createdAt: log.createdAt, // Armazena o campo `createdAt` para ordenação
          }))
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()); // Ordena por data, do mais recente ao mais antigo

        setLogs(fetchedLogs);
        setSearchResults(fetchedLogs);
      }
    }
  );





  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (logs && logs.length > 0) {
      const filteredResults = logs.filter((log) =>
        log.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.data.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [logs, searchTerm]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };
  useEffect(() => {
    if (logs && logs.length > 0) {
      // Normalize a string removendo acentos e transformando para minúsculas
      const normalizeString = (str) => 
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  
      const filteredResults = logs.filter((LogEntry) =>
        normalizeString(LogEntry.nome).includes(normalizeString(searchTerm))||
      normalizeString(LogEntry.data).includes(normalizeString(searchTerm))
      );
  
      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [logs, searchTerm]);
  

  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white;">${match}</span>`);
  };

  const handleDeleteLog = (id: number) => {
    client.delete(`/logs/delete/${id}`).then((res) => {
      alert('Log deletado com sucesso!');

      const updatedLogs = logs.filter((log) => log.id !== id);
      setLogs(updatedLogs);
      setSearchResults(updatedLogs);
    }).catch(error => {
      console.error("Erro ao deletar log:", error);
    });
  };

  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-1">
        <section className="cadastro-1">
          <h1>Logs de Acesso</h1>
          <input
            type="text"
            className="Search"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchInput}
            style={{ border: '2px solid #c0c0c0' }}
          />
          <br />
          {searchResults.length > 0 ? (
            <ul>
              {searchResults.map((log) => (
                <div key={log.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <h5
                    style={{ width: "100%", marginLeft: "-14%" }}
                    className='conversa'
                    dangerouslySetInnerHTML={{
                      __html: `${highlightText(
                        `${log.nome} - ${log.data}`,
                        searchTerm
                      )} `,
                    }}
                  ></h5>
                  <BsFillTrashFill
                    size={20}
                    className='buttons-2'
                    onClick={() => handleDeleteLog(log.id)}
                  />
                </div>
              ))}
            </ul>
          ) : (
            <p>Nenhum log registrado.</p>
          )}
        </section>
      </div>
    </AdminPageContainer>
  );
}
