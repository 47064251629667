import styled from '@emotion/styled';

export const PageContainer = styled.div`
  background-image: url('assets/banner\ 4.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
`;