import { useQuery } from 'react-query';
import { Navbar } from '../../components/Navbar';
import { getFotos } from '../../services/api';
import { PageContainer } from './styles';
import { Infos } from '../../infos';
import { useEffect, useState } from 'react';
import { AiFillFolderOpen, AiOutlineCloseCircle } from 'react-icons/ai';
import { CloudDownload } from '@mui/icons-material';
import './styles.css';
import { ChatComponent } from '../../components/Chat';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import ReactModal from 'react-modal';
import { ModalContainer } from '../admin/arquivo/styles';
import { FaTimes } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FotoContentToggle from '../../components/FotoContentToggle';
import socket from '../../services/socket';
import { RootState } from '../../redux/stores';
import { useSelector } from 'react-redux';



interface FotosItem {
  id: number;
  urlArquivo:string;
  nomeAlbum:string;
  date:string;
  nomeOriginalArquivo:string;
}




export function FotosPage() {
  const { data: fotos } = useQuery('fotos', () =>
    getFotos().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });

  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FotosItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };


  

  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);


  useEffect(() => {
    if (fotos && fotos.length > 0) {
      // Filter faq based on search term
      const filteredResults = fotos.filter((fotosItem) =>
        fotosItem.nomeAlbum.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [fotos, searchTerm]);


 



  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white;font-size:25px">${match}</span>`);
  };





  // Ordenar os albuns por data, do mais recente para o mais antigo
  const sortedFotos = fotos
    ? [...fotos].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    : [];

  const [capaAlbum, setCapaAlbum] = useState(null);

  useEffect(() => {
    if (sortedFotos.length > 0) {
      // Defina a primeira foto como capa do álbum quando a lista de fotos é carregada
      setCapaAlbum(sortedFotos[0]?.urlArquivo[0] || null);
    }
  }, [sortedFotos]);

  // Função para formatar a data no formato "dd/MM/yyyy"
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [primeiraFoto, setPrimeiraFoto] = useState(null);

  useEffect(() => {
    if (sortedFotos.length > 0) {
      // Defina a primeira foto quando a lista de fotos é carregada
      setPrimeiraFoto(sortedFotos[0]);
    }
  }, [sortedFotos]);


  

  return (
    <PageContainer>
      <Navbar />
      <ChatComponent />
      <div className="title">
        <h1>Fotos</h1>
         </div>
        <input
          type="text"
          className="Search"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchInput}
          style={{border:' 2px solid #c0c0c0'}}
        />
        <div className="content">

        {searchResults.length > 0 ? (
          searchResults.map((foto) => (
            <div className="card" key={foto.id}>
              <div className="box" />
              <div key={foto.id} className="arquivos">
                <img src={foto.urlArquivo[1]} alt={foto.nomeAlbum} className='img-capa' />
                <h2 dangerouslySetInnerHTML={{__html:highlightText(foto.nomeAlbum,searchTerm)}}/>
                <h5>{formatDate(new Date(foto.date))}</h5>
                <span> contém {foto.nomeOriginalArquivo.length - 1} fotos</span>
                <FotoContentToggle foto={foto} />
              </div>
            </div>
          ))
        ) : (
          <div className="content">
            <div className="title-2">
              <h1>Não há álbuns no momento</h1>
            </div>
          </div>
        )}
      </div>
      <Infos />
      <footer >
        <p className='p'>© 2023  Óticas Mercadótica.  Todos os direitos reservados</p>
      </footer>
    </PageContainer>
  );
}





