import { ReactNode } from 'react';

import { CardContainer } from './styles';

interface ShowCardProps {
  title: string;
  number: number;
  icon: ReactNode;
}

export function ShowCard({ title, number, icon }: ShowCardProps) {
  return (
    <CardContainer style={{ height: '80px' }}>
      <div className="icon">{icon}</div>
      <div className="desc">
        <h1>{title}</h1>
        <h2 style={{ color: 'black' }}>{number}</h2>
      </div>
    </CardContainer>
  );
}
