import { CHAT } from '../actions/actions.type';

const INITIAL_STATE = {
  chatStatus: '',
};

function chatReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CHAT:
      return {
        ...state,
        chatStatus: action.payload,
      };
    default:
      return state;
  }
}

export default chatReducer;
