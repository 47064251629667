import styled from '@emotion/styled';

export const VideoContainer = styled.div`
  /* display: flex;
  width: 50%;
  overflow-x: auto;
  margin: 0 auto;
  margin-top: 5rem; */
  .card-video {
    position: flex;
    display: flex; 
    margin-top: 15px;
    transition: 0.3s;
     margin-left: 5rem;

    &:hover {
      transform: scale(1.05);
    }

    img {
      margin-top: 10px;
      margin-right:20%;
      max-width: 130%;
      height: 100%;
      border-radius: 0px;
      transition: filter 0.3s;

      
      &:hover {
        transform: scale(1.00);
      }
      .video-titulo {
      position: relative;
      
      margin-left: 20%;
      color: blue;
      padding: 5px;
      text-align: center;
      justify-content: center;
      margin-top: 10%;
      font-size: 18px;
    }
    }

    
    .video-details {
    text-align: center;
 
    padding: 10px;
    border-radius: 0 0 4px 4px;
    width: 100%;
    margin-top: 105%;
    margin-left:-120%;
    box-sizing: border-box;
   
  }

  }
`;


