import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #222d32;
  justify-content: center;
  padding: 0 3rem;
  color: #fff;
`;

export const LogoutContainer = styled.div`
  display: flex;
  width: 5%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
  position: fixed;
  top: 4rem;
  right: 2rem;
  z-index: 999;
`;

export const LogoutButton = styled.button`
  background-color: #fff;
  width: 100%;
  height: 40px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
`;
