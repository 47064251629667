import { Button } from './styles';

interface CustomButtonProps {
  type?: 'button' | 'submit' | 'reset';
  text: string;
  isLoading?: boolean;
}

export function CustomButton({ text, isLoading, type }: CustomButtonProps) {
  return <Button type={type}>{isLoading ? <div className="loader" /> : text}</Button>;
}
