import React from 'react';
import { format } from 'date-fns';
import { NewsContainer } from './styles';

interface NewsProps {
  title: string;
  description?: string;
  image: string;
  date: string;

}





export function News({ title, description, image, date }: NewsProps) {
  return (
    <NewsContainer>
      <div className="card-news">
        <img src={image} alt="" />
        <p style={{ marginTop: 5, color: '#808080' }}>{date}</p>
        <h5 style={{ marginTop: 5, display: 'block' }}>{title}</h5>
      </div>
    </NewsContainer>
  );
}
