import styled from '@emotion/styled';

export const PageContainer = styled.div`
background-image: url('assets/banner\ 5..png');
 background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 13%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 50%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
     
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4%;
    margin-bottom:200%;
    .card {
      width: calc(25.25% - 5px);
      height: 100%;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;
      margin-bottom: 5%;
      box-shadow: 10px 10px 1rem #ccc;
      transition: 0.3s;
     background-color:white;
      
      &:hover {
        transform: scale(1.05);
      }

   
     



      .box {
        width: 40%;
        height: 100%;
        background-color: #ccc;
        
        
      }
      
      img{
        width: 100%;
        height: 100%;
           &:hover {
        transform: scale(1.00);
      }
        
      }

      p{
        color: #808080;
        text-align: center;
      }

      a{
        color: black;
         text-decoration:none;
            &:hover {
       color:#303b92;
       
      }
      }

      h2{
        text-align: center;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      }

       #conteudo{
        cursor: pointer;
        font-size: 16px;
        text-align: center;
        color:black;
       
           &:hover {
       color:#303b92;
      }

      }



      .comunicado {
        padding: 0.5rem;
        p {
          color: #808080;
        }
      }
    }
  }

  
  .Search{
        font-size: 17px;
  margin-top: 12%;
  margin-left: 40%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);


      }
`;
