import { CardStyled } from './styles';

interface CardProps {
  title: string;
  number?: number;
}

export function CustomCard({ title, number }: CardProps) {
  return (
    <CardStyled>
      <div className="top">
        <h1>{title}</h1>
      </div>

      <div className="number">{number}</div>
    </CardStyled>
  );
}
