import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ChangeEvent, FormEvent, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery, useQueryClient } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { CustomButton } from '../../../components/CustomButton';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getArquivos, getMidia, getfaq } from '../../../services/api';
import { storage } from '../../../services/firebase';
import { client } from '../../../services/instance';
import { ImageContainer } from './styles';
import './styles.css';
import ReactQuill from 'react-quill';

import { BannerContainer, Container } from '../banners/styles';
import { AiFillFolderOpen } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';


interface Faq {
  id: number;
  duvida: string;
  status: string;
  urlArquivo: string[];
  nomeOriginalArquivo: string[];
  nomeSolucao: string;
  resposta: string;

}




export function FAQAdminPage() {
  const [values, setValues] = useState({
    id: 0,
    duvida: '',
    status: 'ativo',
    urlArquivo: [''],
    nomeOriginalArquivo: [''],
    nomeSolucao: '',
    resposta: '',
  });

  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [pastaCounts, setPastaCounts] = useState<{ [key: string]: number }>({});
  const [editedItem, setEditedItem] = useState<Faq | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Faq | null>(null);


  const { data: faq, refetch } = useQuery('Faq', () =>
    getfaq().then((response) => response?.data),
    {
      refetchOnWindowFocus: false,
    }
  );


  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);



  useEffect(() => {
    // Inicialize o contador de arquivos para cada pasta
    const counts: { [key: string]: number } = {};
    if (faq) {
      faq.forEach((item) => {
        counts[item.duvida] = item.urlArquivo.length;
      });
    }
    setPastaCounts(counts);
  }, [faq]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleFileUploadProgress = (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress);
  };

  const handleImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...values.urlArquivo];
    const updatedNomeOriginalArquivo = [...values.nomeOriginalArquivo];

    try {
      await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(
            storage,
            `Faq/${values.duvida}/${file.name}`
          );

          const uploadTask = uploadBytesResumable(storageRef, file);
          uploadTask.on('state_changed', handleFileUploadProgress);

          await uploadTask;
          const url = await getDownloadURL(storageRef);

          updatedUrlArquivo.push(url);
          updatedNomeOriginalArquivo.push(file.name);
        })
      );

      setValues({
        ...values,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });
    } catch (error) {
      alert(error);
    }
  };

  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = values;

    data.urlArquivo.push(imageUrl);
    console.log(data);
    client
      .post('/Faq/create', {
        ...values,
        imageURL: imageUrl,
        data,
      })
      .then((res) => {
        alert('faq cadastrada com sucesso!');
        refetch();
      });
  };

  const onDelete = (id: number) => {
    client.delete(`/Faq/delete/${id}`).then((res) => {
      console.log(res);
      alert('faq deletada com sucesso!');
      refetch();
    });
  };



  // Novo método para excluir um arquivo da pasta selecionada
  const deleteFile = (indexToDelete: number) => {
    if (selectedFolder) {
      const updatedUrlArquivo = [...selectedFolder.urlArquivo];
      const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];

      // Remova o arquivo da lista com base no índice
      updatedUrlArquivo.splice(indexToDelete, 1);
      updatedNomeOriginalArquivo.splice(indexToDelete, 1);

      // Atualize o estado da pasta selecionada com as listas atualizadas
      setSelectedFolder({
        ...selectedFolder,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

      // Atualize o contador de arquivos da pasta
      setPastaCounts({
        ...pastaCounts,
        [selectedFolder.duvida]: updatedUrlArquivo.length,
      });

    }
  };

  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        duvida: values.duvida,
        status: values.status = 'true', 'ativo': 'inativo',
        nomeSolucao: values.nomeSolucao,
        urlArquivo: selectedFolder.urlArquivo,
        nomeOriginalArquivo: selectedFolder.nomeOriginalArquivo,
        resposta: values.resposta,
      };



      try {
        const response = await client.put(`/Faq/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };


  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    // Limpe os campos do formulário
    setValues({
      id: 0,
      duvida: '',
      nomeSolucao: '',
      resposta: '',
      status: 'ativo',
      urlArquivo: [''],
      nomeOriginalArquivo: [''],
    });

    // Limpe a pasta selecionada
    setSelectedFolder(null);
  };

  const handleEdit = (faq: Faq) => {
    setIsEditing(true);
    setEditedItem(faq);

    // Preencha o formulário de edição com os dados do arquivo selecionado

    setValues({
      ...values,
      duvida: faq.duvida,
      nomeSolucao: faq.nomeSolucao,
      status: faq.status ? 'true' : 'false',
      urlArquivo: faq.urlArquivo,
      nomeOriginalArquivo: faq.nomeOriginalArquivo,
      resposta: faq.resposta,
    });


    // Defina a pasta selecionada
    setSelectedFolder(faq);
    // Scrolla a página para o topo de forma suave
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };



  const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!selectedFolder || !e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...selectedFolder.urlArquivo];
    const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];
    setSelectedFolder

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `Faq/${selectedFolder.duvida}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));

      // Atualize o estado da pasta selecionada com os novos arquivos
      setSelectedFolder({
        ...selectedFolder,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });


      // Atualize o contador de arquivos da pasta
      setPastaCounts({
        ...pastaCounts,
        [selectedFolder.duvida]: updatedUrlArquivo.length,
      });


    } catch (error) {
      alert(error);
    }
  };







  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar FAQ</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="duvida">Dúvida:</label>
                  <input
                    type="text"
                    name="duvida"
                    value={values.duvida}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>

              {/* <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomeSolucao">Descrição do Link:</label>
                  <input
                    type="text"
                    name="nomeSolucao"
                    value={values.nomeSolucao}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div> */}


              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    value={values.status}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  >
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="resposta">Resposta Escrita:</label>
                  <ReactQuill className='quill-edit'

                    value={values.resposta}
                    placeholder='digite aqui o conteúdo...'
                    onChange={(content) => setValues({ ...values, resposta: content })}
                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                      'blockquote', 'list', 'bullet', 'indent', 'color',]}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                          { list: 'ordered' },
                          { list: 'bullet' },
                          { indent: '-1' },
                          { indent: '+1' },
                        ],

                        ['clean'],
                        [{ color: [] }],

                      ],
                    }}
                  />
                </div>
              </div>

            </form>

          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="duvida">Dúvida:</label>
                  <input
                    type="text"
                    name="duvida"
                    value={values.duvida}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>

              {/* <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomeSolucao">Descrição do Link:</label>
                  <input
                    type="text"
                    name="nomeSolucao"
                    value={values.nomeSolucao}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div> */}




              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select
                    name="status"
                    value={values.status}
                    onChange={handleInputChange}
                    style={{ width: '90%' }}
                  >
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>

                <div className="input-group-2">
                  <label htmlFor="arquivo">PDF com a solução:</label>
                  <input
                    type="file"
                    name="arquivo"
                    onChange={handleImage}
                    style={{ width: '200%' }}
                    multiple
                  />
                  {progress > 0 && <progress value={progress} max="100" />}
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="resposta">Resposta Escrita:</label>
                  <ReactQuill className='quill'

                    value={values.resposta}
                    placeholder='digite aqui o conteúdo...'
                    onChange={(content) => setValues({ ...values, resposta: content })}
                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                      'blockquote', 'list', 'bullet', 'indent', 'color',]}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                          { list: 'ordered' },
                          { list: 'bullet' },
                          { indent: '-1' },
                          { indent: '+1' },
                        ],

                        ['clean'],
                        [{ color: [] }],

                      ],
                    }}
                  />
                </div>
              </div>


              <div className="button" style={{ marginTop: '14%' }}>
                <CustomButton type="submit" text="Cadastrar" />
              </div>
            </form>
          )}
        </section>

        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Arquivos em : {selectedFolder.duvida}</h2>
            <ul className='lista'>
              {selectedFolder.nomeOriginalArquivo.slice(0).map((fileUrl, index) => (
                fileUrl && (
                  <li key={index}>
                    <BsFillTrashFill
                      className='lixo'
                      size={20}
                      onClick={() => deleteFile(index)}
                    />
                    <AiFillFolderOpen className='icon-arq' />
                    <a
                      className='arqui'
                      href={fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedFolder.nomeOriginalArquivo[index]}
                    </a>
                  </li>
                )
              ))}
            </ul>


            <div className="input-group-">
              <label htmlFor="arquivo">Adicionar novos arquivos:</label>
              <input
                type="file"
                name="arquivo"
                onChange={handleAddFiles}
                style={{ width: '200%' }}
                multiple
              />
              {progress > 0 && <progress value={progress} max="100" />}
            </div>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}




        <section className="gerenciar-2">
          <h1>Gerenciar FAQ</h1>
          <Container>
            {faq?.map((faq) => (
              <BannerContainer
                key={faq.id}
                ativo={Boolean(faq.status)}
                className="faq"
              >
                <div className="row">
                  <div>
                    <h2 >{faq.duvida}</h2>

                    <h2 className="numero-arq">
                      Número de arquivos: {faq.nomeOriginalArquivo.length - 1}
                    </h2>
                  </div>

                  <div className="status-2">
                    <div className={faq.status ? 'ativo' : 'inativo'}>
                      {faq.status ? 'Ativo' : 'Inativo'}
                    </div>
                  </div>

                  <div className="buttons">
                    <BsFillTrashFill size={20} onClick={() => onDelete(faq.id)} />
                    <BsFillPencilFill size={20} onClick={() => handleEdit(faq)} />

                  </div>
                </div>
              </BannerContainer>
            ))}
          </Container>
        </section>
      </div>
    </AdminPageContainer>
  );
}
