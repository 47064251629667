
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface PdfViewerProps {
  question: any;
}

export function PDFViewer({ question }: PdfViewerProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const gotoPDF = () => {
    // Assumindo que a página do PDF está na rota /pdf/:src
   window.open(`/pdf/${question.src}`);
  };

  return (
    <div className="question" role="button" onClick={gotoPDF}  >
      <h2>{question.msg}</h2>
    </div>
  );
}
