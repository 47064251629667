import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChatComponent } from '../../components/Chat';
import { Navbar } from '../../components/Navbar';
import { News } from '../../components/News';
import { Pesquisa } from '../../components/pesquisa';
import './styles.css';
import { RootState } from '../../redux/stores';
import { getNews, getNoticias, getComunicados, getFotos, getMidia, getPesquisas, getArquivos, getLogs } from '../../services/api';
import socket from '../../services/socket';
import { format } from 'date-fns';
import { PageContainer } from './styles';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { ComunicadosPage } from '../comunicados';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Infos } from '../../infos';
import Enquete from '../../enquete';
import { Arquivos } from '../../components/arquivo';
import axios from 'axios';
import ComunicadosComponent from '../../components/ComunicadosComponent';
import { Fotos } from '../../components/fotos';
import { Video } from '../../components/videos';

export function Home() {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const userAccessLevel = user?.acesso;

  // Função para registrar o log de login no banco de dados
  useEffect(() => {
    const logUserSession = async () => {
      try {
        const sessionKey = `log_${user?.nome}_session`;

        if (!sessionStorage.getItem(sessionKey)) {
          const newLog = {
            usuarioId: user?.id,
            atividade: `Login realizado por ${user?.nome} em ${new Date().toLocaleString()}`,
          };

          await getLogs(); // Usa getLogs para buscar ou registrar logs no banco
          sessionStorage.setItem(sessionKey, 'true');
        }
      } catch (error) {
        console.error("Erro ao registrar o log de login:", error);
      }
    };

    if (user) {
      logUserSession();
    }
  }, [user]);


  function formatData(data) {
    return format(new Date(data), 'dd/MM/yyyy');
  }

  function search(dataArray, searchTerm) {
    if (!searchTerm) {
      return dataArray;
    }
    return dataArray.filter((item) =>
      Object.values(item).some((value: any) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }



  const { data: noticias } = useQuery('noticias', () =>
    getNoticias().then((response) => response?.data),
    { refetchOnWindowFocus: false }
  );

  const { data: fotos } = useQuery('fotos', () =>
    getFotos().then((response) => response?.data),
    { refetchOnWindowFocus: false }
  );

  const { data: midias } = useQuery('midia', () =>
    getMidia().then((response) => response?.data),
    { refetchOnWindowFocus: false }
  );

  const { data: pesquisas, refetch } = useQuery('pesquisas', () =>
    getPesquisas().then((response) => response?.data),
    { refetchOnWindowFocus: false }
  );

  const { data: arquivo } = useQuery('arquivo', () =>
    getArquivos().then((response) => response?.data),
    { refetchOnWindowFocus: false }
  );

  const { data: comunicados } = useQuery('comunicados', async () => {
    const response = await getComunicados();
    return response?.data;
  }, { refetchOnWindowFocus: false });

  useEffect(() => {
    socket.auth = { username: user?.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sortedNoticias = noticias
    ? [...noticias].sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
    : [];

  return (
    <PageContainer>
      <div className='Home'>
        <Navbar />
        <section>
          <div className='Newsmaior'>
            {sortedNoticias && sortedNoticias.length > 0 && (
              <div key={sortedNoticias[0].id}>
                <div className="ContentLeft">
                  <h3 style={{ marginTop: '22rem', marginLeft: '-10%' }}>| DESTAQUE |
                    <p className='datanews'>{formatData(sortedNoticias[0].data)}</p>
                  </h3>
                  <div className="ImportantNews"></div>
                </div>
                <h1 className='tituloNews'>{sortedNoticias[0].titulo}</h1>
                <img className="imgNewss" src={sortedNoticias[0].imageURL} alt={sortedNoticias[0].titulo} />
                <div className='conteudoss'>
                  <ReactQuill
                    style={{ height: '530px', width: '100%', border: '1px solid transparent', boxShadow: 'none' }}
                    readOnly={true}
                    value={sortedNoticias[0].conteudo}
                    modules={{ toolbar: false }}
                  />
                </div>
              </div>
            )}
          </div>
          <ComunicadosComponent comunicados={comunicados} user={user} />
          <div className='outrasNews'>
            <Link to="/news" className='linkado'>
              <h3>ÚLTIMAS NOTÍCIAS</h3>
            </Link>
            <div className="containerr">
              {sortedNoticias &&
                sortedNoticias.slice(-3).map((noticia) => (
                  <News
                    title={noticia.titulo}
                    date={formatData(noticia.data)}
                    image={noticia.imageURL}
                  />
                ))}
            </div>
          </div>
          <h3 className='arq-recente'>
            <Link to='/arquivos' className='linkado'>ARQUIVOS RECENTES</Link>
          </h3>
          <div className="containerr-">
            <div className='card-arq'>
              {arquivo &&
                arquivo.slice(-2).map((arquivo) => {
                  const isFranqueado = arquivo.acesso !== 'franqueado/colaborador/gerente' || "gerente";
                  const isGerente = arquivo.acesso !== 'franqueado/colaborador/gerente' || "franqueado";
                  const nomePasta = isFranqueado === true
                    ? 'PASTA COM BLOQUEIO DE USER !'
                    : isGerente === true
                      ? 'PASTA COM BLOQUEIO DE USER !'
                      : arquivo.nomePasta;


                  const arquivoNomeoriginal = isFranqueado ? 'Ø' : arquivo.nomeOriginalArquivo.length - 1;
                  return (
                    <Arquivos
                      title={nomePasta}
                      date={arquivo.date}
                      qntd={arquivoNomeoriginal}
                    />
                  );
                }).reverse()
              }
            </div>
          </div>
          <div className="ContentRight">
            <h3>ENQUETE</h3>
            <div className="containerrr">
              {pesquisas &&
                pesquisas.slice(-1).map((item) => (
                  <Pesquisa title={item.pergunta} />
                ))}
            </div>
          </div>
          <div className='faixa'>
            <h3>Álbuns</h3>
            <Link to="/fotos" className='linkado'>
              <input type='button' value='VER GALERIA' className='button-galeria' />
            </Link>
          </div>
          <div className='container-' style={{ marginTop: '5px' }}>
            {fotos &&
              fotos.slice(-3).map((foto) => (
                <Fotos
                  image={foto.urlArquivo}
                  title={foto.nomeAlbum}
                  date={formatData(foto.date)}
                />
              ))}
          </div>
          <div className='faixa-'>
            <h3>Vídeos</h3>
            <Link to="/videos" className='linkado'>
              <input type='button' value='VER GALERIA' className='button-galeria-' />
            </Link>
          </div>
          <div className='contentt' style={{ marginTop: '5px' }}>
            {midias && midias.length > 0 ? (
              midias.slice(-3).map((midia) => (
                <div className='card' key={midia.id}>
                  <Video
                    image={midia.thumbnail}
                    title={midia.titulo}
                    date={formatData(midia.data)}
                  />
                </div>
              ))
            ) : (
              <div><h2>Não há vídeos</h2></div>
            )}
          </div>
          <ChatComponent />
          <div>
            {pesquisas &&
              pesquisas.slice(-1).map((item) => (
                <div className='enquete'>
                  <Enquete
                    pergunta={item.pergunta}
                    pesquisaId={item.id}
                    respostas={item.respostas}
                  />
                </div>
              ))}
          </div>
        </section>
        <footer className="rodapé">
          <Infos />
          <p className='p'>© 2023 Óticas Mercadótica. Todos os direitos reservados</p>
        </footer>
      </div>
    </PageContainer>
  );
}
