import React, { useEffect,useState } from 'react';
import { Infos } from '../../infos';
import { Navbar } from '../../components/Navbar';
import { PageContainer } from './styles';
import { format } from 'date-fns';

import './styles.css';
import { useQuery } from 'react-query';

import { getMidia } from '../../services/api';
import { ChatComponent } from '../../components/Chat';
import { Video } from '../../components/videos';
import socket from '../../services/socket';
import { RootState } from '../../redux/stores';
import { useSelector } from 'react-redux';








interface VideosItem {
  id: number;
  thumbnail:string;
  titulo:string;
  data:string;
 link:string;
}






export default function VideosPage() {
  const { data: midias } = useQuery('midia', () =>
    getMidia().then((response) => response?.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
  }, []);


  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<VideosItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };




  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);


  useEffect(() => {
    if (midias && midias.length > 0) {
      // Filter faq based on search term
      const filteredResults = midias.filter((midiasItem) =>
        midiasItem.titulo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [midias, searchTerm]);





  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color:  #303b92;color:white">${match}</span>`);
  };



  // Função para formatar a data no formato "dd/MM/yyyy"
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };

  // Ordenar os vídeos por data, do mais recente para o mais antigo
  const sortedMidias = midias
    ? [...midias].sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
    : [];


  return (
    <PageContainer>
      <Navbar />
      <ChatComponent />
      <section className='ger-video'>
        <div className="title">
          <h1>Vídeos</h1>
        </div>
        <input
          type="text"
          className="Search"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchInput}
          style={{border:' 2px solid #c0c0c0'}}
        />
        <div className="content">
          {searchResults.length > 0 ? (
            searchResults.map((midia) => (
              <div className="card" key={midia.id}>
                <div className="box" />
                <div key={midia.id} className="">
                  <img src={midia.thumbnail} alt="" />
                  <h2 dangerouslySetInnerHTML={{__html:highlightText(midia.titulo,searchTerm)}}/>
                  <p>{formatDate(new Date(midia.data))}</p>
                  {/* Tornar o link executável ao clicar */}
                  <a href={midia.link} target="_blank" rel="noopener noreferrer">
                    <h3 id="conteudo">ASSISTA AQUI</h3>
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div className="content">
              <div className="title-2">
                <h1>Não há vídeos no momento</h1>
              </div>
            </div>
          )}
        </div>
      </section>
      <Infos />
      <footer >
        <p className='p'>© 2023 Óticas Mercadótica. Todos os direitos reservados</p>
      </footer>
    </PageContainer>
  );
}
