import { useQuery } from 'react-query';
import { Navbar } from '../../components/Navbar';
import { getComunicados } from '../../services/api';
import { PageContainer } from './styles';
import { Infos } from '../../infos';
import './styles.css'
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { ChatComponent } from '../../components/Chat';
import ComunicadoContentToggle from '../../components/ComunicadoContentToggle';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/stores';
import socket from '../../services/socket';


interface ComunicadoItem {
  id: number;
  titulo: string;
  dataEnvio:string;
  tipo:string;
 
}


export function ComunicadosPage() {
  const { data: comunicados } = useQuery('comunicados', async () => {
    const response = await getComunicados();
    const data = response?.data;


    return data;
  }, {
    refetchOnWindowFocus: false,
  });

  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ComunicadoItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };

  useEffect(() => {
    if (comunicados&& comunicados.length > 0) {
      // Filter faq based on search term
      const filteredResults = comunicados.filter((comunicadosItem) =>
        comunicadosItem.titulo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [comunicados, searchTerm]);

  const jumpTo = () => {
    if (searchResults.length) {
      const element = document.getElementById(`faqItem-${currentIndex}`);
      if (element) {
      
      }
    }
  };

  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);






  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white">${match}</span>`);
  };


  // Ordenar os comunicados por data, do mais recente para o mais antigo
  const sortedComunicados = comunicados
    ? [...comunicados].sort((a, b) => new Date(b.dataEnvio).getTime() - new Date(a.dataEnvio).getTime())
    : [];

  useEffect(() => {
    window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
  }, []);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };



  return (
    <PageContainer className='page'>
      <Navbar />
      
      <div className="title">
        <h1>Comunicados</h1>
      </div>
      <section className='ger-comunicado'>
      <input
          type="text"
          className="Search"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchInput}
          style={{border:' 2px solid #c0c0c0'}}
        />
        <div className="content">
          {searchResults.length > 0 ? (
            searchResults?.map((comunicado) => (
              <div className="card" key={comunicado.id}>
                <div className="box" />
                <div key={comunicado.id} className="comunicado">
                  <h2 dangerouslySetInnerHTML={{__html:highlightText(comunicado.titulo,searchTerm)}}/>
                  <h5>{formatDate(new Date(comunicado.dataEnvio))}</h5>
                  <h5>{comunicado.tipo}</h5>
                  <ComunicadoContentToggle comunicado={comunicado} />
                </div>

              </div>

            ))
          ) : (
            <div className="content">
              <div className="title-2">
                <h1>Não há comunicados no momento</h1>
              </div>
            </div>

          )}
 
        </div>
        <ChatComponent />
      </section>
      <Infos />
      <footer >
        <p className='p'>© 2023  Óticas Mercadótica.  Todos os direitos reservados</p>
      </footer>
    </PageContainer>

  );

}
