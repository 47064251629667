import styled from '@emotion/styled';




export const UsuariosContainer = styled.div`
  display: flex;
  width: 90%;
  height: 10%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  justify-content: space-between;
  margin: 1rem 0px;
  .user-info {
    width: 80%;
    display: flex;
    height: 100%;
    justify-content: space-between;

    .info {
      padding: 0px 0.5rem;
      .title {
        font-weight: bold;
        font-size:12px;
        color:black;
        
      }
      p {
        font-size:12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; // Adicione essa linha
      }
    }
  }

  .buttonss- {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2%;

    svg {
      cursor: pointer;
      color: #303b92;
      transition: 0.4s;
      &:hover {
        transform: scale(1.02);
        color: #000;
      }
    }
  }


  
`;
