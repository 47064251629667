import { combineReducers } from '@reduxjs/toolkit';

import chatReducer from './chat.reducer';
import currentChatReducer from './currentChat.reducer';
import notificationReducer from './notification.reducer';
import questionReducer from './question.reducer';
import roomsReducer from './rooms.reducer';
import sidePageReducer from './sidepage.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  userReducer,
  questionReducer,
  sidePageReducer,
  currentChatReducer,
  chatReducer,
  notificationReducer,
  roomsReducer,
});

export default rootReducer;
