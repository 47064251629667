import { Avatar } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import avatarPng from '../../../../public/assets/avatar.png';
import { questionAction } from '../../../redux/actions/index.action';

import './styles.css';

interface QuestionWindowProps {
  handleChatting: () => void;
}

export function QuestionWindow({ handleChatting }: QuestionWindowProps) {
  const [question, setQuestion] = useState('');

  const dispatch = useDispatch();

  // function onEnter() {
  //   dispatch(questionAction(question));
  // }

  return (
    <div className="chat">
      <div style={{ height: 0 }}>
        <div className="stripe" />
      </div>
      <div className="emailFormWindow">
        <div
          className=""
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Avatar
            src={avatarPng}
            sx={{
              height: 70,
              width: 70,
              '&:hover': { border: '1px solid #fff' },
            }}
            style={{
              position: 'relative',
              left: 'calc(50% - 44px)',
              top: '10%',
              boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',

              transition: 'all 0.5s ease',
            }}
          />
          <div className="topText">Bem vindo ao suporte 🖐</div><br></br>
          <button type="button" onClick={handleChatting}>
            Chat
          </button>
          <div className="bottomText"><br></br>
            <span>Digite a sua dúvida</span>
          </div>
        </div>
      </div>
    </div>
  );
}
