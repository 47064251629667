import styled from '@emotion/styled';

export const CardStyled = styled.div`
  width: 14rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  .top {
    width: 100%;
    height: 30%;
    backgroud: #111;
    display: flex;
    background: #111;
    border-radius: 1rem 1rem 0 0;
    color: #fff;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.02);
  }

  .number {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 700;
    color: #111;
  }
`;
