import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getNoticias } from '../../../services/api';
import { storage } from '../../../services/firebase';
import { client } from '../../../services/instance';
import { BannerContainer, Container } from '../banners/styles';
import './styles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AiFillFolderOpen } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';
import { IoIosInformationCircleOutline } from 'react-icons/io';




function ImageUpload({ onImageUpload }) {
  const [progress, setProgress] = useState(0);

  const handleFileChange = async (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) return;
    const storageRef = ref(storage, `noticia/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const newProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(newProgress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          onImageUpload(url);
        });
      }
    );

  };

  return (
    <div className="input-group-2">
      <label htmlFor="foto">Foto:</label>
      <input type="file" name="foto" onChange={handleFileChange} style={{ width: '200%' }} />
      <h5>(apenas fotos com resoluções entre 1024x1024 e 1080x1080)</h5>
      {progress > 0 && <progress value={progress} max="100" />}
    </div>
  );
}


interface Noticias {
  id: number,
  titulo: string,
  data: Date,
  chamada: string,
  imageURL: string[],
  conteudo: string,
  status: string,
  nomeOriginalArquivo: string[]

}

type AcessType = 'Franqueado' | 'Colaborador';

export function NoticiasPage() {
  const [values, setValues] = useState({
    id: 0,
    titulo: '',
    data: new Date(),
    chamada: '',
    imageURL: [''],
    conteudo: '',
    status: 'ativo',
    nomeOriginalArquivo: [''],
  });



  const [acessType, setAcessType] = useState<AcessType[]>([]);
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  const [progress, setProgress] = useState(0);
  const [thumb, setThumb] = useState(['']);
  const [editedItem, setEditedItem] = useState<Noticias | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Noticias | null>(null);


  const { data: noticias, refetch } = useQuery('noticia', () =>
    getNoticias().then((response) => response?.data),
    {
      refetchOnWindowFocus: false,
    }
  );


  // Ordenar as notícias por data, do mais recente para o mais antigo
  const sortedNoticias = noticias
    ? [...noticias].sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
    : [];


  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);

  const handleCheckboxChange = (value: AcessType) => {
    if (acessType.includes(value)) {
      setAcessType(acessType.filter(type => type !== value));
    } else {
      setAcessType([...acessType, value]);
    }
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleDateChange = (date) => {
    setValues({ ...values, data: date });
  };

  const onImageUpload = (url) => {
    setThumb(url);
  };


  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Preparando os dados para a solicitação
      const requestData = {
        ...values,
        imageURL: thumb,
      };

      const response = await client.post('/noticia/create', requestData);
      if (response.status === 200 || response.status === 201) {
        alert('Notícia cadastrada com sucesso!');
        // Recarregando os dados ou realizando outras ações necessárias
        refetch();
      } else {
        // Tratando cenários onde a resposta não é bem-sucedida
        console.error('Erro ao cadastrar notícia:', response.data);
      }
    } catch (error) {
      // Tratando erros na solicitação
      console.error('Erro ao fazer a solicitação:', error);
      // Aqui você pode exibir uma mensagem de erro para o usuário se desejar
    }
  };

  const onDelete = (id: number) => {
    client.delete(`/noticia/delete/${id}`).then((res) => {
      console.log(res);
      alert('Notícia deletada com sucesso!');
      refetch();
    });
  };


  // Novo método para excluir um arquivo da pasta selecionada
  const deleteFile = (indexToDelete: number) => {
    if (selectedFolder) {
      const updatedUrlArquivo = [...selectedFolder.imageURL];
      const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];

      // Remova o arquivo da lista com base no índice
      updatedUrlArquivo.splice(indexToDelete, 1);
      updatedNomeOriginalArquivo.splice(indexToDelete, 1);

      // Atualize o estado da pasta selecionada com as listas atualizadas
      setSelectedFolder({
        ...selectedFolder,
        imageURL: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });


    }
  };

  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        titulo: values.titulo,
        data: values.data,
        chamada: values.chamada,
        imageURL: values.imageURL,
        conteudo: values.conteudo,
        status: values.status = 'true', 'ativo': 'inativo',
        nomeOriginalArquivo: selectedFolder.nomeOriginalArquivo,
      };




      try {
        const data = values;
        data.imageURL = thumb;


        editedData.imageURL = thumb;

        const response = await client.put(`/noticia/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };


  const handleEdit = (noticia: Noticias) => {
    setIsEditing(true);
    setEditedItem(noticia);

    // Preencha o formulário de edição com os dados do arquivo selecionado

    setValues({
      ...values,

      titulo: noticia.titulo,
      data: new Date(values.data),
      chamada: noticia.chamada,
      imageURL: noticia.imageURL,
      conteudo: noticia.conteudo,
      status: noticia.status ? 'true' : 'false',
      nomeOriginalArquivo: noticia.nomeOriginalArquivo,

    });


    // Defina a pasta selecionada
    setSelectedFolder(noticia);
    // Scrolla a página para o topo de forma suave
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    // Limpe os campos do formulário
    setValues({
      id: 0,
      titulo: '',
      data: new Date(),
      chamada: '',
      imageURL: [''],
      conteudo: '',
      status: 'ativo',
      nomeOriginalArquivo: [''],
    });

    // Limpe a pasta selecionada
    setSelectedFolder(null);
  };



  // Estado para armazenar a URL da imagem
  const [imageUrl, setImageUrl] = useState('');

  // Função para atualizar o estado da URL da imagem quando o usuário digita
  const handleImageUrlChange = (e) => {
    setImageUrl(e.target.value);
  };

  // Função para adicionar a imagem ao conteúdo da mensagem
  const addImageToMessage = () => {
    if (imageUrl) {
      const imgTag = `<img src="${imageUrl}" alt="Imagem do comunicado" />`;
      setValues({ ...values, conteudo: values.conteudo + imgTag });
      setImageUrl('');
    } else {
      alert('Por favor, insira uma URL válida para a imagem.');
    }
  };






  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar notícia</h1>
          {isEditing && selectedFolder ? (
            // Formulário de edição
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="titulo">Título:</label>
                  <input
                    type="text"
                    name="titulo"
                    value={values.titulo}
                    placeholder="Digite em capsLock"
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.data}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="chamada">Chamada:</label>
                  <input
                    type="text"
                    name="chamada"
                    value={values.chamada}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="imageUrl">URL da Imagem:</label>
                  <input
                    type="text"
                    name="imageUrl"
                    id="imageUrl"
                    value={imageUrl}
                    onChange={handleImageUrlChange}
                    placeholder="Insira a URL da imagem"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addImageToMessage();
                      }
                    }}
                    style={{ width: '100%' }}
                  />
                  <IoIosInformationCircleOutline
                 className='informationNewsedit'
                    onClick={() => window.open('https://www.aconvert.com/pt/image/', '_blank')}
                  />

                </div>
              </div><br></br>


              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="conteudo">Conteúdo: </label>
                  <ReactQuill
                    style={{ height: '220px', width: '200%' }}
                    value={values.conteudo}
                    placeholder='digite aqui o conteúdo...'
                    onChange={(content) => setValues({ ...values, conteudo: content })}
                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                      'blockquote', 'list', 'bullet', 'indent', 'color', 'link', 'image']}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                          { list: 'ordered' },
                          { list: 'bullet' },
                          { indent: '-1' },
                          { indent: '+1' },
                        ],


                        ['link'],
                        ['clean'],
                        [{ color: [] }],
                        ['image'],

                      ],
                    }}
                  />
                </div>
              </div>
              <div className="radio" style={{ marginTop: '50px' }}>
                <div className='franqueados' style={{ marginTop: '80%' }}>
                  <label>Franqueado</label>
                  <input
                    type="checkbox"
                    name="acessType"
                    value="Franqueado"
                    checked={acessType.includes('Franqueado')}
                    onChange={() => handleCheckboxChange('Franqueado')}
                  />
                </div>

              </div>
              <div className='colaborador' style={{ marginTop: '4%' }} >
                <label>Colaborador</label>
                <input
                  type="checkbox"
                  name="acessType"
                  value='Colaborador'
                  checked={acessType.includes('Colaborador')}
                  onChange={() => handleCheckboxChange('Colaborador')}
                />
              </div>
            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="titulo">Título:</label>
                  <input
                    type="text"
                    name="titulo"
                    value={values.titulo}
                    placeholder="Digite em capsLock"
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.data}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="chamada">Chamada:</label>
                  <input
                    type="text"
                    name="chamada"
                    value={values.chamada}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>


              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="imageUrl">URL da Imagem:</label>
                  <input
                    type="text"
                    name="imageUrl"
                    id="imageUrl"
                    value={imageUrl}
                    onChange={handleImageUrlChange}
                    placeholder="Insira a URL da imagem"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addImageToMessage();
                      }
                    }}
                    style={{ width: '100%' }}
                  />
                  <IoIosInformationCircleOutline
                   className='informationNewscad'
                    onClick={() => window.open('https://www.aconvert.com/pt/image/', '_blank')}
                  />

                </div>
              </div><br></br>
              <ImageUpload onImageUpload={onImageUpload} />

              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="conteudo">Conteúdo: </label>
                  <ReactQuill
                    style={{ height: '320px', width: '200%' }}
                    value={values.conteudo}
                    placeholder='digite aqui o conteúdo...'
                    onChange={(content) => setValues({ ...values, conteudo: content })}
                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                      'blockquote', 'list', 'bullet', 'indent', 'color', 'link', 'image']}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                          { list: 'ordered' },
                          { list: 'bullet' },
                          { indent: '-1' },
                          { indent: '+1' },
                        ],

                        ['link'],
                        ['clean'],
                        [{ color: [] }],
                        ['image'],

                      ],
                    }}
                  />
                </div>
              </div>
              <div className="radios">
                <div className='franqueados'>
                  <label>Franqueado</label>
                  <input
                    type="checkbox"
                    name="acessType"
                    value="Franqueado"
                    checked={acessType.includes('Franqueado')}
                    onChange={() => handleCheckboxChange('Franqueado')}
                  />
                </div>
                <div className='colaborador'>
                  <label>Colaborador</label>
                  <input
                    type="checkbox"
                    name="acessType"
                    value='Colaborador'
                    checked={acessType.includes('Colaborador')}
                    onChange={() => handleCheckboxChange('Colaborador')}
                  />
                </div>
              </div>
              <button className="buttonNews" type="submit">
                Cadastrar
              </button>
            </form>
          )}
        </section>
        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Arquivos em : {selectedFolder.titulo}</h2>
            <ul className='lista'>
              {selectedFolder.nomeOriginalArquivo.slice(0).map((fileUrl, index) => (
                <ul key={index}>
                  <BsFillTrashFill
                    className='lixo'
                    size={20}
                    onClick={() => deleteFile(index)}
                  />
                  <AiFillFolderOpen className='icon-arq' />
                  <a
                    className='arqui'
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedFolder.nomeOriginalArquivo[index]}
                  </a>
                </ul>
              ))}
            </ul>


            <div className="input-group-">
              <ImageUpload onImageUpload={onImageUpload} />
            </div>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}
        <section className="gerenciar-2">
          <h1>Gerenciar notícias</h1>
          <Container>
            {sortedNoticias?.map((noticia) => (
              <BannerContainer key={noticia.id} ativo={Boolean(noticia.status)} className="noticia">
                <div className="image">
                  <img src={noticia.imageURL} alt="logo" />
                </div>
                <div className="row">
                  <div>
                    <h2>{noticia.titulo}</h2>
                    <span>{formatDate(new Date(noticia.data))}</span>
                  </div>
                  <div className="status-2">
                    <div className={noticia.status ? 'inativo' : 'ativo'}>
                      {noticia.status ? 'Inativo' : 'Ativo'}
                    </div>
                  </div>
                  <div className="buttons">
                    <BsFillTrashFill size={20} onClick={() => onDelete(noticia.id)} />
                    <BsFillPencilFill size={20} onClick={() => handleEdit(noticia)} />
                  </div>
                </div>
              </BannerContainer>
            ))}
          </Container>
        </section>
      </div>
    </AdminPageContainer>
  );
}
