import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ChangeEvent, FormEvent, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery, useQueryClient } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import axios from 'axios';
import { getUsuarios } from '../../../services/api';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { CustomButton } from '../../../components/CustomButton';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getArquivos, getMidia } from '../../../services/api';
import { storage } from '../../../services/firebase';
import { client } from '../../../services/instance';
import { ImageContainer } from './styles';
import './styles.css';

import { BannerContainer, Container } from '../banners/styles';
import { AiFillFolderOpen } from 'react-icons/ai';
import EditFolderModal from './EditFolderModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';



interface Arquivo {
  acesso: string,
  id: number;
  nomePasta: string;
  resumo: string;
  date: Date;
  status: string;
  descricao: string;
  urlArquivo: string[];
  nomeOriginalArquivo: string[];
}

export function ArquivosAdminPage() {
  const [values, setValues] = useState<Arquivo>({
    id: 0,
    acesso: '.',
    nomePasta: '',
    resumo: '',
    date: new Date(),
    status: 'ativo',
    descricao: '',
    urlArquivo: [''],
    nomeOriginalArquivo: [''],
  });



  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [pastaCounts, setPastaCounts] = useState<{ [key: string]: number }>({});
  const [editedItem, setEditedItem] = useState<Arquivo | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchResults, setSearchResults] = useState<Arquivo[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const { notification } = useSelector((state: RootState) => state.notificationReducer);

  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Arquivo | null>(null);

  const { data: arquivo, refetch } = useQuery('arquivo', () =>
    getArquivos().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });


  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };


  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: #303b92;color:white;font-size:15px">${match}</span>`);
  };



  useEffect(() => {
    if (arquivo && arquivo.length > 0) {
      const filteredResults = arquivo.filter((arquivo) =>
        arquivo.nomePasta.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [, searchTerm]);


  // Ordenar os arquivos por data, do mais recente para o mais antigo
  const sortedArquivos = arquivo
    ? [...arquivo].sort((b, a) => new Date(b.date).getTime() - new Date(a.date).getTime())
    : [];


  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);


  useEffect(() => {
    // Inicialize o contador de arquivos para cada pasta
    const counts: { [key: string]: number } = {};
    if (arquivo) {
      arquivo.forEach((item) => {
        counts[item.nomePasta] = item.urlArquivo.length;
      });
    }
    setPastaCounts(counts);
  }, [arquivo]);

  const handleFileUploadProgress = (snapshot: any) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress);
  };

  const formatDate = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'franqueado' && value === 'franqueado') {
      setValues({ ...values, [name]: value, acesso: 'franqueado' });

    } else if (name === 'colaborador' && value === 'colaborador') {
      setValues({ ...values, [name]: value, acesso: 'colaborador' });

    }
    else if (name === 'gerente' && value === 'gerente') {
      setValues({ ...values, [name]: value, acesso: 'gerente' });

    }
    else {
      setValues({ ...values, [name]: value, acesso: value });
    }
  };


  const handleDateChange = (date: Date) => {
    setValues({ ...values, date: date });
  };

  const handleImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...values.urlArquivo];
    const updatedNomeOriginalArquivo = [...values.nomeOriginalArquivo];

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `arquivo/${values.nomePasta}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));

      setValues({
        ...values,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

    } catch (error) {
      alert(error);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = values;
    console.log(data)

    data.urlArquivo.push(imageUrl);
    console.log(data);
    client
      .post('/arquivo/create', {
        ...values,
        imageURL: imageUrl,
        data,

      })
      .then((res) => {
        alert('pasta cadastrada com sucesso!');

        refetch();
      });
  };

  const onDelete = (id: number) => {
    client.delete(`/arquivo/delete/${id}`).then((res) => {
      console.log(res);
      alert('pasta deletada com sucesso!');
      refetch();
    });
  };

  // Novo método para excluir um arquivo da pasta selecionada
  const deleteFile = (indexToDelete: number) => {
    if (selectedFolder) {
      const updatedUrlArquivo = [...selectedFolder.urlArquivo];
      const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];

      // Remova o arquivo da lista com base no índice
      updatedUrlArquivo.splice(indexToDelete, 1);
      updatedNomeOriginalArquivo.splice(indexToDelete, 1);

      // Atualize o estado da pasta selecionada com as listas atualizadas
      setSelectedFolder({
        ...selectedFolder,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

      // Atualize o contador de arquivos da pasta
      setPastaCounts({
        ...pastaCounts,
        [selectedFolder.nomePasta]: updatedUrlArquivo.length,
      });
    }
  };






  const saveChanges = async () => {
    if (!editedItem || !editedItem.id) {
      alert('Nenhum arquivo selecionado para edição.');
      return;
    }



    if (selectedFolder) {
      const editedData = {
        id: selectedFolder.id,
        nomePasta: values.nomePasta,
        resumo: values.resumo,
        date: values.date,
        status: values.status = 'true', 'ativo': 'inativo',
        descricao: values.descricao,
        acesso: values.acesso,
        urlArquivo: selectedFolder.urlArquivo,
        nomeOriginalArquivo: selectedFolder.nomeOriginalArquivo,
      };



      try {
        const response = await client.put(`/arquivo/edit/${editedItem.id}`, editedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          handleCancelEdit()
          alert('Alterações da edição foram salvas com sucesso!');
        } else {
          console.error('Erro ao editar arquivo:', response.statusText);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      } catch (error) {
        console.error('Erro ao editar arquivo:', error);
        alert('Erro ao editar arquivo. Verifique o console para mais informações.');
      }
    }

  };





  const handleEdit = (arquivo: Arquivo) => {
    setIsEditing(true);
    setEditedItem(arquivo);

    // Preencha o formulário de edição com os dados do arquivo selecionado

    setValues({
      ...values,
      nomePasta: arquivo.nomePasta,
      resumo: arquivo.resumo,
      date: new Date(arquivo.date),
      status: arquivo.status ? 'true' : 'false',
      descricao: arquivo.descricao,
      urlArquivo: arquivo.urlArquivo,
      acesso: arquivo.acesso,
      nomeOriginalArquivo: arquivo.nomeOriginalArquivo,
    });


    // Defina a pasta selecionada
    setSelectedFolder(arquivo);
    // Scrolla a página para o topo de forma suave
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedItem(null);

    // Limpe os campos do formulário
    setValues({
      id: 0,
      acesso: '.',
      nomePasta: '',
      resumo: '',
      date: new Date(),
      status: 'ativo',
      descricao: '',
      urlArquivo: [''],
      nomeOriginalArquivo: [''],
    });

    // Limpe a pasta selecionada
    setSelectedFolder(null);
  };



  const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!selectedFolder || !e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    const updatedUrlArquivo = [...selectedFolder.urlArquivo];
    const updatedNomeOriginalArquivo = [...selectedFolder.nomeOriginalArquivo];
    setSelectedFolder

    try {
      await Promise.all(files.map(async (file) => {
        const storageRef = ref(
          storage,
          `arquivo/${selectedFolder.nomePasta}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', handleFileUploadProgress);

        await uploadTask;
        const url = await getDownloadURL(storageRef);

        updatedUrlArquivo.push(url);
        updatedNomeOriginalArquivo.push(file.name);
      }));

      // Atualize o estado da pasta selecionada com os novos arquivos
      setSelectedFolder({
        ...selectedFolder,
        urlArquivo: updatedUrlArquivo,
        nomeOriginalArquivo: updatedNomeOriginalArquivo,
      });

      // Atualize o contador de arquivos da pasta
      setPastaCounts({
        ...pastaCounts,
        [selectedFolder.nomePasta]: updatedUrlArquivo.length,
      });

    } catch (error) {
      alert(error);
    }
  };

  const handleModal = true;



  return (
    <AdminPageContainer padding="0px">
      <LogoutComponent />
      {/* {handleModal && <EditFolderModal />} */}
      <div className="content-2">
        <section className="cadastro-2">
          <h1>Cadastrar arquivos</h1>
          {isEditing && selectedFolder ? (

            // Formulário de edição
            <form >
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomePasta">Nome da Pasta:</label>
                  <input

                    type="text"
                    name="nomePasta"
                    value={values.nomePasta}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="resumo">Resumo:</label>
                  <input
                    type="text"
                    name="resumo"
                    value={values.resumo}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="descricao">Descrição do arquivo: </label>
                  <input
                    placeholder='Dê uma descrição '
                    type="text"
                    name="descricao"
                    value={values.descricao}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.date}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="accessLevel">Nível de Acesso:</label>
                  <select
                    name="accessLevel"
                    value={values.acesso}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  >
                    <option value=".">.</option>
                    <option value="colaborador">Colaborador</option>
                    <option value="franqueado">Franqueado</option>
                    <option value="gerente">Gerente</option>
                    <option value="franqueado/colaborador/gerente">Franqueado/Colaborador/Gerente</option>
                  </select>
                </div>
              </div>

            </form>
          ) : (
            // Formulário de cadastro
            <form onSubmit={onSubmit}>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="nomePasta">Nome da Pasta:</label>
                  <input

                    type="text"
                    name="nomePasta"
                    value={values.nomePasta}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="resumo">Resumo:</label>
                  <input
                    type="text"
                    name="resumo"
                    value={values.resumo}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="descricao">Descrição do arquivo: </label>
                  <input
                    placeholder='Dê uma descrição '
                    type="text"
                    name="descricao"
                    value={values.descricao}
                    onChange={handleInputChange}
                    style={{ width: '200%' }}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <p className="input-group-2">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange} style={{ width: '90%' }}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </p>
                <div className="input-group-2">
                  <label htmlFor="data">Data:</label>
                  <DatePicker
                    selected={values.date}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="input-group-2">
                  <label htmlFor="arquivo">urlArquivo:</label>
                  <input
                    type="file"
                    name="arquivo"
                    onChange={handleImage}
                    style={{ width: '200%' }}
                    multiple
                  />
                  {progress > 0 && <progress value={progress} max="100" />}
                </div>
              </div>
              <div className="input-row-2">
                <div className="input-group-2">
                  <label htmlFor="accessLevel">Nível de Acesso:</label>
                  <select
                    name="accessLevel"
                    value={values.acesso}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  >
                    <option value=".">.</option>
                    <option value="colaborador">Colaborador</option>
                    <option value="gerente">Gerente</option>
                    <option value="franqueado">Franqueado</option>
                    <option value="franqueado/colaborador/gerente">Franqueado/Colaborador/Gerente</option>
                  </select>
                </div>
              </div>

              <div className='button'>
                <CustomButton type="submit" text="Cadastrar" />
              </div>
            </form>
          )}
        </section>
        {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
        {selectedFolder && (
          <section className='arq-att'>
            <h2 className='topo'>Arquivos em : {selectedFolder.nomePasta}</h2>
            <ul className='lista'>
              {selectedFolder.nomeOriginalArquivo.slice(0).map((fileUrl, index) => (
                fileUrl && (
                  <li key={index}>
                    <BsFillTrashFill
                      className='lixo'
                      size={20}
                      onClick={() => deleteFile(index)}
                    />
                    <AiFillFolderOpen className='icon-arq' />
                    <a
                      className='arqui'
                      href={fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedFolder.nomeOriginalArquivo[index]}
                    </a>
                  </li>
                )
              ))}
            </ul>


            <div className="input-group-">
              <label htmlFor="arquivo">Adicionar novos arquivos:</label>
              <input
                type="file"
                name="arquivo"
                onChange={handleAddFiles}
                style={{ width: '200%' }}
                multiple
              />
              {progress > 0 && <progress value={progress} max="100" />}
            </div>
            <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
            <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

          </section>
        )}



        <section className="gerenciar-2">
          <h1>Gerenciar arquivos</h1>
          <Container>
            <input
              type="text"
              className="Search"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleSearchInput}
              style={{ border: '2px solid #c0c0c0' }}
            /><br></br>
            {searchResults.length > 0 ? (
              searchResults.map((arquivo) => (

                <BannerContainer key={arquivo.id} ativo={Boolean(arquivo.status)} className='arquivo'>
                  <div className="row">
                    <div>
                      <h2 dangerouslySetInnerHTML={{ __html: highlightText(arquivo.nomePasta, searchTerm) }} />
                      <span><strong>{formatDate(new Date(arquivo.date))}</strong></span><br></br>
                      <span><strong>{arquivo.acesso}</strong></span>
                      <h3 className='numero-arq'>Número de arquivos: {pastaCounts[arquivo.nomePasta] - 2}</h3>
                    </div>
                    <div className="status-2">
                      <div className={arquivo.status ? 'ativo' : 'inativo'}>{arquivo.status ? 'Ativo' : 'Inativo'}</div>
                    </div>
                    <div className="buttons">
                      <BsFillTrashFill size={20} onClick={() => onDelete(arquivo.id)} />
                      <BsFillPencilFill size={20} onClick={() => handleEdit(arquivo)} />
                    </div>
                  </div>
                </BannerContainer>

              )).reverse()
            ) : (
              <p>Nenhum arquivo encontrado</p>
            )}
          </Container>
        </section>
      </div>
    </AdminPageContainer>
  );
}