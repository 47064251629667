import styled from '@emotion/styled';

export const NewsContainer = styled.div`
  /* display: flex;
  width: 50%;
  overflow-x: auto;
  margin: 0 auto;
  margin-top: 5rem; */
  .card-news {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 5rem;

    img {
      margin-top: 10;
      max-width: 170px;
    }
    
  }
`;
