import styled from '@emotion/styled';

export const PesquisaContainer = styled.div`
  /* display: flex;
  width: 50%;
  overflow-x: auto;
  margin: 0 auto;
  margin-top: 5rem; */
 

  .card-pesquisa{
    h1{
      font-size: 16px;
    }
  }
  
`;