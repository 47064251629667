/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FormEvent, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getComunicados } from '../../../services/api';
import { client } from '../../../services/instance';
import { Button, ImageContainerContainer } from './styles';
import './styles.css';
import { FaListAlt } from "react-icons/fa";
import { getUsuarios } from '../../../services/api';

import { BannerContainer, Container } from '../banners/styles';
import { Height } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import { userAction } from '../../../redux/actions/index.action';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';
import { IoIosInformationCircleOutline } from "react-icons/io";




interface Comunicados {
  id: number,
  titulo: string,
  status: string,
  dataEnvio: Date,
  dataLimite: Date,
  tipo: string,
  mensagem: string,
  acesso: string,
  anexo: string,

}


type AcessType = 'Franqueado' | 'Colaborador';


export function ComunicadosAdminPage() {
  const [values, setValues] = useState({
    id: 0,
    titulo: '',
    status: 'true',
    dataEnvio: new Date(),
    dataLimite: new Date(),
    tipo: 'urgente',
    mensagem: '',
    acesso: 'franqueado',
    anexo: '',
  });

  const [acessType, setAcessType] = useState<AcessType[]>([]);
  const [isFranqueadoChecked, setFranqueadoChecked] = useState(true);
  const [isColaboradorChecked, setColaboradorChecked] = useState(false);
  const [editedItem, setEditedItem] = useState<Comunicados | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showUserListPopup, setShowUserListPopup] = useState(false);
  const { notification } = useSelector((state: RootState) => state.notificationReducer);
  // Novo estado para controlar a pasta selecionada
  const [selectedFolder, setSelectedFolder] = useState<Comunicados | null>(null);
  const [notifiedUsers, setNotifiedUsers] = useState([]);
  // Novo estado para controlar a lista de usuários notificados
  const [userList, setUserList] = useState<string[]>([]);




  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);

  const closePopup = () => {
    setShowUserListPopup(false);
  };
  const openUserListPopup = (comunicado) => {
    // Lógica para obter os usuários notificados do local storage ou de onde quer que estejam armazenados
    const notifiedComunicadosIdsString = localStorage.getItem(`notifiedComunicados_${comunicado.id}`);
    const notifiedComunicadosIds = notifiedComunicadosIdsString ? JSON.parse(notifiedComunicadosIdsString) : [];

    const notifiedUsers = notifiedComunicadosIds.map((userId) => {
      // Substitua pela lógica real para obter o nome do usuário pelo ID
      const userName = `Usuário ${userId}`;
      return { id: userId, nome: userName };
    });

    setNotifiedUsers(notifiedUsers);
    setShowUserListPopup(true);
  };




  {



    const handleInputChange = (e) => {
      const { name, value } = e.target;

      // Verifica se o campo alterado é o campo 'tipo'
      if (name === 'tipo') {
        // Define o valor do tipo com base na opção selecionada
        const tipoSelecionado = value === 'Urgente' ? 'Urgente' : 'Não Urgente';

        // Atualiza os valores, incluindo o 'tipo' com a opção selecionada
        setValues({ ...values, [name]: value, tipo: tipoSelecionado });
      } else {
        // Para outros campos, mantenha o valor sem alterações
        setValues({ ...values, [name]: value });
      }
    };


    const { data: comunicados, refetch } = useQuery(
      'comunicados',
      () => getComunicados().then((response) => response?.data),
      {
        refetchOnWindowFocus: false,
      }
    );





    // Ordenar os comunicados por data, do mais recente para o mais antigo
    const sortedComunicados = comunicados
      ? [...comunicados].sort((a, b) => new Date(b.dataEnvio).getTime() - new Date(a.dataEnvio).getTime())
      : [];

    const handleCheckboxChange = (value: AcessType) => {
      if (acessType.includes(value)) {
        setAcessType(acessType.filter(type => type !== value));
      } else {
        setAcessType([...acessType, value]);
      }
    };

    const onSubmit = () => {
      // Função para formatar a data no formato "dd/MM/yyyy"
      const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
      };



      client.post('/comunicados/create', {
        ...values,

      }).then((res) => {
        console.log(res);
        alert('Comunicado cadastrado com sucesso!');
        setValues({
          id: 0,
          titulo: '',
          status: 'true',
          dataEnvio: new Date(),
          dataLimite: new Date(),
          tipo: 'urgente',
          mensagem: '',
          acesso: 'franqueado',
          anexo: '',
        });
      });

      console.log(values);
    };

    const handleFranqueadoChange = () => {
      setFranqueadoChecked(true);
      setColaboradorChecked(false);
      setValues({ ...values, acesso: 'franqueado' });
    };

    const handleColaboradorChange = () => {
      setColaboradorChecked(true);
      setFranqueadoChecked(false);
      setValues({ ...values, acesso: 'colaborador' });
    };

    const handleDateChange = (date, field) => {
      setValues({ ...values, [field]: date });
    };

    const onDelete = (id: number) => {
      client.delete(`/comunicados/delete/${id}`).then((res) => {
        console.log(res);
        alert('Comunicado deletado com sucesso!');
        refetch();
      });
    };



    const saveChanges = async () => {
      if (!editedItem || !editedItem.id) {
        alert('Nenhum arquivo selecionado para edição.');
        return;
      }



      if (selectedFolder) {
        const editedData = {
          id: selectedFolder.id,
          titulo: values.titulo,
          tipo: values.tipo,
          mensagem: values.mensagem,
          dataEnvio: values.dataEnvio,
          dataLimite: values.dataLimite,
          status: values.status === 'true' ? 'ativo' : 'inativo',
        };



        try {
          const response = await client.put(`/comunicados/edit/${editedItem.id}`, editedData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.status === 200) {
            handleCancelEdit()
            alert('Alterações da edição foram salvas com sucesso!');
          } else {
            console.error('Erro ao editar arquivo:', response.statusText);
            alert('Erro ao editar arquivo. Verifique o console para mais informações.');
          }
        } catch (error) {
          console.error('Erro ao editar arquivo:', error);
          alert('Erro ao editar arquivo. Verifique o console para mais informações.');
        }
      }

    };





    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    };


    const handleEdit = (comunicados: Comunicados) => {
      setIsEditing(true);
      setEditedItem(comunicados);

      setValues({
        ...values,
        titulo: comunicados.titulo,
        mensagem: comunicados.mensagem,
        dataEnvio: new Date(comunicados.dataEnvio),
        dataLimite: new Date(comunicados.dataLimite),
        status: comunicados.status ? 'true' : 'false',
        tipo: comunicados.tipo ? 'urgente' : 'nurgente',
      });

      setSelectedFolder(comunicados);
      // Scrolla a página para o topo de forma suave
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const handleCancelEdit = () => {
      setIsEditing(false);
      setEditedItem(null);

      setValues({
        id: 0,
        titulo: '',
        mensagem: '',
        dataEnvio: new Date(),
        dataLimite: new Date(),
        status: 'ativo',
        acesso: 'franqueado',
        anexo: '',
        tipo: 'urgente',
      });

      setSelectedFolder(null);
    };



    // Estado para armazenar a URL da imagem
    const [imageUrl, setImageUrl] = useState('');

    // Função para atualizar o estado da URL da imagem quando o usuário digita
    const handleImageUrlChange = (e) => {
      setImageUrl(e.target.value);
    };

    // Função para adicionar a imagem ao conteúdo da mensagem
    const addImageToMessage = () => {
      if (imageUrl) {
        const imgTag = `<img src="${imageUrl}" alt="Imagem do comunicado" />`;
        setValues({ ...values, mensagem: values.mensagem + imgTag });
        setImageUrl('');
      } else {
        alert('Por favor, insira uma URL válida para a imagem.');
      }
    };








    return (
      <AdminPageContainer padding="0px">
        <LogoutComponent />



        <div className="content-2">
          <section className="cadastro-2">
            <h1>Cadastrar Comunicado</h1>
            {isEditing && selectedFolder ? (
              // Formulário de edição
              <form>
                <div className="input-row-2">
                  <div className="input-group-2">
                    <label htmlFor="titulo">Título:</label>
                    <input type="text" name="titulo" id="titulo" value={values.titulo} onChange={handleInputChange}
                      style={{ width: '200%' }} />
                  </div>
                </div>
                <div className="input-row-2">
                  <p className='input-group-2'>
                    <label htmlFor="status" >Status:</label>
                    <div>
                      <select name="status" id="status" onChange={handleInputChange} style={{ width: '20%', border: '1px solid #C0C0C0', borderRadius: '5px' }}>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                      </select>
                    </div>
                  </p>
                </div>

                <div className='input-row-2'>
                  <p className="input-group-2" >
                    <label htmlFor="dataEnvio"  >Data de Envio: </label>
                    <div >
                      <DatePicker
                        selected={values.dataEnvio}
                        onChange={(date) => handleDateChange(date, 'dataEnvio')}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </p>
                </div>


                <div className='input-row-2'>
                  <div className="input-group-2" >
                    <label htmlFor="dataLimite" >Data Limite :</label>
                    <div >
                      <DatePicker
                        selected={values.dataLimite}
                        onChange={(date) => handleDateChange(date, 'dataLimite')}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="input-group-2">
                    <label htmlFor="Urgente" >Tipo:</label>

                    <div style={{ marginRight: '100px', marginTop: '10px', width: '400px' }}>
                      <select name="tipo" id="tipo" onChange={handleInputChange} style={{ width: '20%', border: '1px solid #C0C0C0', borderRadius: '5px', }}>
                        <option value=".">.</option>
                        <option value="Urgente">Urgente</option>
                        <option value="Sem Urgência">Sem Urgência</option>
                      </select>
                    </div>

                  </div>

                </div>

                <div className="input-row-2">
                  <div className="input-group-2">
                    <label htmlFor="imageUrl">URL da Imagem:</label>
                    <input
                      type="text"
                      name="imageUrl"
                      id="imageUrl"
                      value={imageUrl}
                      onChange={handleImageUrlChange}
                      placeholder="Insira a URL da imagem"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          addImageToMessage();
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                    <IoIosInformationCircleOutline
                     className='informationComedit'
                      onClick={() => window.open('https://www.aconvert.com/pt/image/', '_blank')}
                    />

                  </div>
                </div>

                <div className="mensagem">
                  <div className="input" style={{ marginTop: '30px' }}>
                    <label htmlFor="mensagem" >Mensagem:</label>
                    <ReactQuill
                      style={{ height: '100px', width: '100%', marginLeft: '0px' }}
                      value={values.mensagem}
                      placeholder='digite aqui o conteúdo...'
                      onChange={(content) => setValues({ ...values, mensagem: content })}
                      formats={[
                        'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                        'blockquote', 'list', 'bullet', 'indent', 'color', 'image'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: '1' }, { header: '2' }, { font: [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                          ],
                          ['clean'],
                          [{ color: [] }],
                          ['image']
                        ],
                      }}
                    />



                    <div className="row" style={{ marginTop: '40%', marginLeft: '40%' }}>
                      <label className="checkbox-container">
                        Franqueado
                        <input
                          type="checkbox"
                          checked={acessType.includes('Franqueado')}
                          onChange={() => handleCheckboxChange('Franqueado')}
                          value="Franqueado"
                          name='acessType'
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className='row' style={{ marginLeft: '40%' }}>
                      <div>
                        <label className="checkbox-container">
                          Colaborador
                          <input
                            type="checkbox"
                            value='Colaborador'
                            name='acessType'
                            checked={acessType.includes('Colaborador')}
                            onChange={() => handleCheckboxChange('Colaborador')}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
              </form>

            ) : (
              // Formulário de cadastro
              <form>
                <div className="input-row-2">
                  <div className="input-group-2">
                    <label htmlFor="titulo">Título:</label>
                    <input type="text" name="titulo" id="titulo" value={values.titulo} onChange={handleInputChange}
                      style={{ width: '200%' }} />
                  </div>
                </div>
                <div className="input-row-2">
                  <p className='input-group-2'>
                    <label htmlFor="status" >Status:</label>
                    <div>
                      <select name="status" id="status" onChange={handleInputChange} style={{ width: '20%', border: '1px solid #C0C0C0', borderRadius: '5px' }}>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                      </select>
                    </div>
                  </p>
                </div>




                <div className='input-row-2'>
                  <p className="input-group-2" >
                    <label htmlFor="dataEnvio"  >Data de Envio: </label>
                    <div >
                      <DatePicker
                        selected={values.dataEnvio}
                        onChange={(date) => handleDateChange(date, 'dataEnvio')}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </p>
                </div>


                <div className='input-row-2'>
                  <div className="input-group-2" >
                    <label htmlFor="dataLimite" >Data Limite :</label>
                    <div >
                      <DatePicker
                        selected={values.dataLimite}
                        onChange={(date) => handleDateChange(date, 'dataLimite')}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="input-group-2">
                    <label htmlFor="Urgente" >Tipo:</label>

                    <div style={{ marginRight: '100px', marginTop: '10px', width: '400px' }}>
                      <select name="tipo" id="tipo" onChange={handleInputChange} style={{ width: '20%', border: '1px solid #C0C0C0', borderRadius: '5px', }}>
                        <option value=".">.</option>
                        <option value="Urgente">Urgente</option>
                        <option value="Sem Urgência">Sem Urgência</option>
                      </select>
                    </div>

                  </div>
                </div>

                <div className="input-row-2">
                  <div className="input-group-2">
                    <label htmlFor="imageUrl">URL da Imagem:</label>
                    <input
                      type="text"
                      name="imageUrl"
                      id="imageUrl"
                      value={imageUrl}
                      onChange={handleImageUrlChange}
                      placeholder="Insira a URL da imagem"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          addImageToMessage();
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                    <IoIosInformationCircleOutline
                      className='informationComcad'
                      onClick={() => window.open('https://www.aconvert.com/pt/image/', '_blank')}
                    />
                  </div>
                </div>
                <div className="mensagem">
                  <div className="input" style={{ marginTop: '30px' }}>
                    <label htmlFor="mensagem" >Mensagem:</label>
                    <ReactQuill
                      style={{ height: '300px', width: '100%', marginLeft: '0px', }}
                      value={values.mensagem}
                      placeholder='adicione  aqui o conteúdo...'
                      onChange={(content) => setValues({ ...values, mensagem: content })}
                      formats={[
                        'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
                        'blockquote', 'list', 'bullet', 'indent', 'color', 'image'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: '1' }, { header: '2' }, { font: [] }],
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                          ],
                          ['clean'],
                          [{ color: [] }],
                          ['image'],

                        ],
                      }}
                    />



                    <div className="row" style={{ marginTop: '15%' }}>
                      <label className="checkbox-container">
                        Franqueado
                        <input
                          type="checkbox"
                          checked={acessType.includes('Franqueado')}
                          onChange={() => handleCheckboxChange('Franqueado')}
                          value="Franqueado"
                          name='acessType'
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className='row'>
                      <div>
                        <label className="checkbox-container">
                          Colaborador
                          <input
                            type="checkbox"
                            value='Colaborador'
                            name='acessType'
                            checked={acessType.includes('Colaborador')}
                            onChange={() => handleCheckboxChange('Colaborador')}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <Button type="button" onClick={onSubmit}   >
                  Cadastrar
                </Button>
              </form>
            )}
          </section>
          {/* Exibir a lista de arquivos quando uma pasta for selecionada */}
          {selectedFolder && (
            <section className='arq-att'>
              <h2 className='topo'>Titulo : {selectedFolder.titulo}</h2>
              <button className='button-edit-1' type="button" onClick={() => handleCancelEdit()}>Cancelar</button>
              <button className='button-edit-2' type="submit" onClick={() => saveChanges()}>Salvar</button>

            </section>
          )}

          {showUserListPopup && (
            <div className='pop-up-container'>
              <div className="pop-up">
                <div className="popup-content">
                  <p>Lista de usuários notificados:</p>
                  <ul>
                    {notifiedUsers.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </div>
                <button onClick={closePopup}>Fechar</button>
              </div>
            </div>
          )}


          <section className="gerenciar-2">
            <h2>Gerenciar Comunicados</h2>
            <Container>
              {sortedComunicados?.map((comunicado) => (
                <BannerContainer key={comunicado.id} ativo={Boolean(comunicados)} className='comunicado'>
                  <div className="row">
                    <span className='titulo'><h1>Título</h1>{comunicado.titulo}</span>

                    <span><h2>Data de Envio</h2>{formatDate(new Date(comunicado.dataEnvio))}</span>
                    <div className="buttons">

                      <BsFillTrashFill className='delete' size={20} onClick={() => onDelete(comunicado.id)} />
                      <BsFillPencilFill style={{ marginLeft: '5px' }} size={20} onClick={() => handleEdit(comunicado)} />
                      {/*   <FaListAlt style={{ marginLeft: '5px' }} size={20} onClick={() => openUserListPopup(comunicado)} /> */}


                    </div>
                  </div>

                </BannerContainer>
              ))}
            </Container>
          </section>
        </div>
      </AdminPageContainer>
    );
  }
}
