import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { useParams } from 'react-router-dom';
import './styles.css';

export function MyDocument() {
  const { pdfID } = useParams();

  return (
    <div className="main">
      <object data={`/assets/pdfs/${pdfID}.pdf`} type="application/pdf">
        <p>
          Parece que seu navegador não suporta PDF <a href="/pdf/sample.pdf">Clique aqui para baixar o arquivo</a>
        </p>
      </object>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  goBack: {
    textDecoration: 'none',
    color: '#fff',
  },
  viewer: {
    width: '100vw',
    height: '100vh',
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    position: 'relative',
  },
  section: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  logo: {
    width: 'auto',
    height: '50px',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowScreenData: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  screenDataItem: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
  },
  totalScreenData: {
    display: 'flex',
    width: '150px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
  },
  description: {
    display: 'flex',
    fontSize: 12,
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: 5,
    margin: '5px 0',
    textAlign: 'center',
    flex: 1,
  },
  textRowTitle: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    fontSize: 12,
    padding: 5,
  },
  textRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    padding: 5,
    paddingLeft: 20,
  },
  textRowScreenData: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flex: 1,
    fontSize: 12,
    padding: 5,
    marginBottom: 10,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '500px',
    maxWidth: '100%',
    border: '1px solid #aaa',
    fontSize: 14,
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeader: {
    backgroundColor: '#eee',
    width: '100%',
    textAlign: 'center',
    border: '1px solid #aaa',
    padding: '5px 0',
  },
  tableItem: {
    backgroundColor: '#fff',
    width: '100%',
    textAlign: 'center',
    border: '1px solid #aaa',
    padding: '5px 0',
  },
  footer: {
    width: '100%',
    height: '40px',
    backgroundColor: '#2d348c',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    fontSize: 10,
  },
  footerLogo: {
    width: '150px',
    height: 'auto',
  },
});
