import styled from '@emotion/styled';

export const ChatContainer = styled.div<{ self: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ self }) => (self ? 'flex-end' : 'flex-start')};
  padding: 5px;
  .message-container {
    width: fit-content;
    padding: 8px;
    border-radius: ${({ self }) => (self ? '10px 10px 0px 10px' : '10px 10px 10px 0px')};
    color: #fff;
    font-size: 1.2rem;
    ${({ self }) =>
    self
      ? 'background-image: linear-gradient(to bottom right, #303b92, #303b92, #005dad);'
      : ' box-shadow: 0px 0px 5px 0px #303b92; color: #111; background-color:#white'}


  }


  .message-time{
    font-size: 11px;
     ${({ self }) =>
    self
      ? 'color:white;'
      : 'color:black'}
  }

  .mensagem{
    font-size: 13px;
     ${({ self }) =>
    self
      ? 'color:white;'
      : 'color:black'}

  }

  .links{
     ${({ self }) =>
    self
      ? 'color:white;'
      : 'color:black'}
  }
`;
