import React, { useState, useEffect } from 'react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';
import { Stripe } from './styles';

interface ChatEndedProps {
  onClick: () => void;
}

interface AssessmentData {
  id: number;
  userName: string;
  assessment: number;
  dateTime: string;
}

export function ChatEnded({ onClick }: ChatEndedProps) {
  const [assessment, setAssessment] = useState(0);
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [assessmentIdCounter, setAssessmentIdCounter] = useState<number>(0);
  const [assessments, setAssessments] = useState<AssessmentData[]>([]);

  useEffect(() => {
    const lastId = parseInt(localStorage.getItem('lastAssessmentId') || '0');
    setAssessmentIdCounter(lastId);//teste

    const storedAssessments = localStorage.getItem('assessments');
    if (storedAssessments) {
      setAssessments(JSON.parse(storedAssessments));      
    }
  }, []);

  const handleStarClick = (star: number) => {
    setAssessment(star);
  };

  const handleFinish = () => {
    const newId = assessmentIdCounter + 1;

    const assessmentData: AssessmentData = {
      id: newId,
      userName: user?.nome || '',
      assessment: assessment,
      dateTime: new Date().toISOString(),
    };

    console.log("Informações da Avaliação:", assessmentData);

    localStorage.setItem('lastAssessmentId', newId.toString());

    const updatedAssessments = [...assessments, assessmentData];

    localStorage.setItem('assessments', JSON.stringify(updatedAssessments));
    setAssessments(updatedAssessments);

    onClick();
  };
  
  return (
    <div className="chat">
      <div style={{ height: 0 }}>
        <Stripe />
      </div>
      <div className="emailFormWindow">
        <div
          className=""
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <div
            className="topText"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              position: 'absolute',
              width: '100%',
              top: '25%',
              color: 'white',
              fontSize: '25px',
              fontWeight: '600',
              backgroundSize: '16px',
            }}
          >
            Chat finalizado 😃
          </div>

          <div
            className="bottomText"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              position: 'absolute',
              width: '100%',
              top: '60%',
              color: '#303b92',
              fontSize: '19px',
              fontWeight: '600',
              backgroundSize: '16px',
            }}
          >
            <p>O que achou? Nos avalie</p>
            <div className="assessment" style={{ display: 'flex', justifyContent: 'center' }}>
              {[1, 2, 3, 4, 5].map((star) => (
                <span key={star}>
                  {assessment >= star ? (
                    <AiFillStar onClick={() => handleStarClick(star)} />
                  ) : (
                    <AiOutlineStar style={{ cursor: 'pointer' }} onClick={() => handleStarClick(star)} />
                  )}
                </span>
              ))}
            </div>
            <div className="button" style={{ marginTop: '2rem' }}>
              <button type="button" style={{ width: '50%', background: '#303b92', color: '#fff' }} onClick={handleFinish}>
                Terminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
