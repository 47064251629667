import { VideoContainer } from './styles';
import React, { useState } from 'react';

interface FotosProps {
  title: string;
  description?: string;
  image: string;
  date: string;
}

export function Video({ title, date, image }: FotosProps) {
  const [isTitleVisible, setTitleVisible] = useState(false);

  return (
    <div
      className='container-imagem'
      onMouseEnter={() => setTitleVisible(true)}
      onMouseLeave={() => setTitleVisible(false)}
      style={{ position: 'relative' }}
    >

      <VideoContainer>
        <div className="card-video">

          <img
            src={image}
            alt={title}
            title={title}
            style={{
              filter: isTitleVisible ? 'brightness(0.5)' : 'brightness(1)',
              transition: 'filter 0.3s', transform: 'scale(1.05)'
            }}
          />
      {isTitleVisible && (
       <div className='video-details'>
        <h3 className='video-titulo'>{title}</h3>
        <p>{date}</p>
      </div>
         )}
  
        </div>
      </VideoContainer>

    </div>

  );
}