import { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/stores';

import './styles.css';

interface AvatarProps {
  onClick: () => void;
}

export function AvatarComponent({ onClick }: AvatarProps) {
  const { user } = useSelector((state: RootState) => state.userReducer);

  const [hovered, setHovered] = useState(false);
  return (
    <div className="avatar" style={{ position: 'fixed', bottom: '24px', right: '24px' }}>
      <div className="avatarHello" style={{ opacity: hovered ? '1' : '0', transition: 'all 0.33s ease' }}>
        Olá, {user.nome} 👍
      </div>
      <div
        className="chatWithMeButton"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
        style={{ border: hovered ? '1px solid #303B92' : '4px solid #fff' }}
      />
    </div>
  );
}
