import React from 'react';

import { PesquisaContainer } from './styles';

interface PesquisaProps {
  title: string;


}

export function Pesquisa({ title }: PesquisaProps) {
  return (
    <PesquisaContainer>
      <div className='card-pesquisa'>
        <h1>{title}</h1>


      </div>
    </PesquisaContainer>
  );
}
