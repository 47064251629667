import styled from '@emotion/styled';

export const PageContainer = styled.div`
  background-image: url('assets/banner\ 10.jpg');
 background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 14%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
    width: 100%;
  
    height: 1000%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
        margin-top: 4%;
     
    .card {
      margin-top: 10%;
      width: 80%;
      height: 1000%;
      margin: 1rem;
      display: flex;

      
   
  
      background-color: white;
      
   

      .arquivos {
        padding: 0.5rem;
     
            h2{
          color: black;
          font-size: 25px;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
      

      }
    }



    
      #read{
        margin-top: -20%;
        
      border-radius: 90%;
         width: 4%;
        border: none;
        background-color: ghostwhite;
        font-size: 15px;
        font-family: bold;
       color:#303b92;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

       #read:hover{
        border-radius: 90%;
      background-color: #303b92;
       color: ghostwhite;
       transition: all 0.3s ease-in-out;
      }
            .seta-lado{
              font-size: 30px;
              
            }

            .seta-cima{
              font-size: 30px;
            }

      .icon-arq{
        color: white;
        display: flex;
        font-size: 60px;
        margin-top: 5%;
        flex-direction: column;
       
      }
      
      .icon-arq2{
        display: flex;
        margin-left: 12%;
        margin-top: -11%;
        margin-bottom: 10%;
        font-size: 20px;
          &:hover{
            color:grey;
          }
      }

      .name-arq{
        color: steelblue;
        text-decoration: none;
      font-size: 20px;
      transition: 0.3s;
      &:hover{
        color: grey;
      }
       
        
        
      }

      .cor-arqui{
        color: ghostwhite;
        
        
      }

    

  }
  .Search{
        font-size: 17px;
  margin-top: 15%;
  margin-left: 41%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-width: 1px;
  transition: 0.4s;
  
      }

      

      .card-art{
      
        margin-left: 30%;
        margin-top:6%;
        width: 45%;
        color: #303b92;
  background-color: white;
  border-radius: 0px;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    border: 3px solid white;
  h2{
    margin-left: 3%;
  }
   
    transition: calc(0.3s);
    &:hover{
    
      
    }
    
    .mais{
      font-size: 30px;
      margin-left: -15%;
      &:hover{
        cursor: pointer;
        transition: calc(0.3s);
        transform: scale(1.2);
      }
    }
.menos{
  font-size: 27px;
  margin-left: -15%;
     &:hover{
        cursor: pointer;
        transition: calc(0.3s);
        transform: scale(1.2);
      }
  
 
}
    
    
      }

      .content{
      margin-bottom:700%}

`;