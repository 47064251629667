import styled from '@emotion/styled';

export const PageContainer = styled.div`
background-image: url('assets/banner\ 3.jpg');
 background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 13%;
    width: 100%;
    height: 20%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #B0C4DE;
    }
  }

  .title-2 {
    margin-top: 3%;
    width: 100%;
    height: 50%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      text-shadow: 10px 10px 1rem #303b92;
    }
  }

  .content {
     
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3%;
    .card {
      width: calc(28.30% - 8px);
      height: 100%;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;
      margin-bottom: 3%;
      box-shadow: 10px 10px 1rem #ccc;
      transition: 0.3s;
     background-color:white;
      
      &:hover {
        transform: scale(1.06);
      }

     
   
    
    



     

      .box {
        width: 40%;
        height: 100%;
        background-color: #ccc;
      
        
        
      }

      h2{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      }
      
      img{
        width: 100%;
        height: 100%;
      }

      img:hover{
        transform: scale(1.0);
      }

      p{
        color: #808080;
      }

      

      .comunicado {
        padding: 0.5rem;
        p {
          color: #808080;
        }
      }
    }
    img{
      width: 100%;
      height: 100%;
    }

       .SearchFAQ{
  font-size: 17px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  color: ghostwhite;
  margin-top: -52%;
  

      }
    
  }

  .Search{
        font-size: 17px;
  margin-top: 14%;
  margin-left: 40%;
  width: 20%;
  height: 20%;
  padding: 0 5px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);


      }



    .content{
    margin-bottom:200%}

`;
