import { useQuery } from 'react-query';

import { Navbar } from '../../components/Navbar';
import { getTreinamentos } from '../../services/api';
import { PageContainer } from './styles';
import { Infos } from '../../infos';
import './styles.css';
import React, { useEffect,useState } from 'react';
import { ChatComponent } from '../../components/Chat';
import socket from '../../services/socket';
import { RootState } from '../../redux/stores';
import { useSelector } from 'react-redux';
import TreinoContentToggle from '../../components/TreinoContentToggle';



interface TreinoItem {
  id: number;
  thumb: string;
  nomeTreinamento:string;
  dataLimite:string;
  linkExterno:string;
}



export function TreinamentosPage() {
  const { data: treinamentos } = useQuery('treinamentos', async () => {
    const response = await getTreinamentos();
    const data = response?.data;

    if (data) {
      // Ordenar as notícias pela data em ordem decrescente (mais recente primeiro)
      data.sort((a, b) => {
        const dateA = new Date(a.dataLimite).getTime(); // Usar dataLimite em vez de data
        const dateB = new Date(b.dataLimite).getTime(); // Usar dataLimite em vez de data
        return dateB - dateA; // Subtração de números para ordenar
      });
    }

    return data;
  }, {
    refetchOnWindowFocus: false,
  });

  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<TreinoItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };


  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);


  useEffect(() => {
    if (treinamentos && treinamentos.length > 0) {
      // Filter faq based on search term
      const filteredResults = treinamentos.filter((treinamentosItem) =>
        treinamentosItem.nomeTreinamento.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [treinamentos, searchTerm]);

  






  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color:  #303b92;color:white">${match}</span>`);
  };



  
  useEffect(() => {
    window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
  }, []);

  // Função para formatar a data no formato "dd/MM/yyyy"
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };

  return (
    <PageContainer className="page-container">
      <Navbar />
      <ChatComponent />
      <div className="title">
        <h1>Treinamentos</h1>
      </div>
      <section className='ger-treino'>
      <input
          type="text"
          className="Search"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchInput}
          style={{border:' 2px solid #c0c0c0'}}
        />
        <div className="content">
          {searchResults.length > 0 ? (
            searchResults.map((treinamento) => (
              <div className="card" key={treinamento.id}>
                <div className="box" />
                <div key={treinamento.id} className="">
                  <img src={treinamento.thumb} alt="" />
                  <div className="subtitulos">
                    <div className="treinos">
                      <h2 className="cores" dangerouslySetInnerHTML={{__html: highlightText(treinamento.nomeTreinamento,searchTerm)}}/>
                    </div>
                    <div className="datas">
                      <p className="cores">{formatDate(new Date(treinamento.dataLimite))}</p>
                    </div>
                    <TreinoContentToggle treinamento={treinamento}/>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="content">
              <div className="title-2">
                <h1>Não há treinamentos no momento</h1>
              </div>
            </div>
          )}
        </div>
      </section>
      <footer >
        <Infos />
        <p className='p'>© 2023 Óticas Mercadótica. Todos os direitos reservados</p>
      </footer>
    </PageContainer>
  );
}
