import { Link, useNavigate } from 'react-router-dom';

import { CustomCard } from '../../components/Card';
import { Navbar } from '../../components/Navbar';
import { PageContainer } from '../../components/PageContainer';
import { ScrollSmooth } from '../../components/ScrollSmooth';
import { PDFViewer } from './PDFViewer';
import { FAQList } from './questions';

import './styles.css';
import { useEffect, useState } from 'react';
import { Infos } from '../../infos';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';

export function FAQ() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFAQ, setFilteredFAQ] = useState(FAQList);




  {/*


  function search(dataArray, searchTerm) {
    if (!searchTerm) {
      return dataArray;
    }

    return dataArray.filter((item) => {
      return Object.values(item).some((value: any) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  useEffect(() => {
    setFilteredFAQ({
      Cadastro: search(FAQList.Cadastro, searchTerm),
      Guias: search(FAQList.Guias, searchTerm),
      Movimento: search(FAQList.Movimento, searchTerm),
      Nao: search(FAQList.Nao, searchTerm),
    });
  }, [searchTerm]);

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
  };
*/}

  useEffect(() => {
    window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
  }, []);
  return (
    <PageContainer display='inline-block' className='pages'>
      <Navbar />
      <div className="title">
        <h1>FAQ</h1>
      </div>
      
      <Link to='/faq' className='card-art'>
     <h2>VOLTAR PARA FAQ</h2> <BsFillArrowLeftCircleFill className='seta-lado'style={{fontSize:'27px'}} />
       </Link>

       {/*
      <div className='faq-input'>
        <input type="text" placeholder="Buscar..." value={searchTerm} onChange={handleSearchInput} />
      </div>
       */}
      <main className="faq">
        <ScrollSmooth to="cadastro">
          <CustomCard title="Cadastro" number={FAQList.Cadastro.length} />
        </ScrollSmooth>
        <ScrollSmooth to="movimento">
          <CustomCard title="Movimento" number={FAQList.Movimento.length} />
        </ScrollSmooth>
        <ScrollSmooth to="guia">
          <CustomCard title="Guia" number={FAQList.Guias.length} />
        </ScrollSmooth>
      </main>
      <div className="pdfs">



        <section id="cadastro" >
          <h1>Cadastro</h1>
          <div className="faq-list">
            {filteredFAQ.Cadastro.map((question, index) => {
              return <PDFViewer question={question} />;
            })}
          </div>
        </section>


        <section id="movimento" >
          <h1>Movimento</h1>
          <div className="faq-list" >
            {filteredFAQ.Movimento.map((question, index) => {
              return <PDFViewer question={question} />;
            })}
          </div>
        </section>



        <section id="guia">
          <h1>Guia</h1>
          <div className="faq-list" >
            {filteredFAQ.Guias.map((question, index) => {
              return <PDFViewer question={question} />;
            })}
          </div>
        </section>
      </div>

      <Infos />
      <footer className="p" >
        <p>© 2023  Óticas Mercadótica.  Todos os direitos reservados</p>
      </footer>
    </PageContainer>

  );

}