import { BsFillArrowRightCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import ReactQuill from "react-quill";
import { useEffect, useState } from 'react';
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import './styles.css';
import { ScrollSmooth } from "../ScrollSmooth";
import { CustomCard } from "../Card";
import { FAQList } from "../../pages/FAQ/questions";
import { PDFViewer } from "../../pages/FAQ/PDFViewer";



function MaisfaqContentToggle() {
    const [containerVisible, setContainerVisible] = useState(true);
    const [containerVisible2, setContainerVisible2] = useState(false);
    const [filteredFAQ, setFilteredFAQ] = useState(FAQList);
  
    function toggleContainer() {
      setContainerVisible(!containerVisible);
      
    }

    function toggleContainer2() {
      setContainerVisible2(!containerVisible2);
      
    }
  
    return (
      <div>
        
        <div>
        <div className='card-art'>
      <h2 className="art">ARTIGOS MAIS ACESSADOS 
        </h2>
       </div>
          {containerVisible && (
            <div className="conteudo">
              <h5 className="conteudo-plus">
              <div className="pdfs" >

<section id="cadastro" >
  <h1>CADASTRO </h1>  
  <div className="faq-list">
    {filteredFAQ.Cadastro.map((question, index) => {
      return <PDFViewer question={question} />;
    })}
  </div>
   

</section>

<section id="devoluçao" >
  <h1>DEVOLUÇÃO</h1>
  <div className="faq-list" >
    {filteredFAQ.Devolução.map((question, index) => {
      return <PDFViewer question={question} />;
    })}
  </div>
</section>

<section id="vendas">
  <h1>VENDAS</h1>
  <div className="faq-list" >
    {filteredFAQ.Vendas.map((question, index) => {
      return <PDFViewer question={question} />;
    })}
  </div>
</section>
</div>
              </h5>
             
            </div>
          )}
          
        </div>
       
      </div>
    );
  }

    
  export default MaisfaqContentToggle;