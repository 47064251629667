import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navbar } from '../../components/Navbar';
import { getfaq } from '../../services/api';
import { PageContainer } from './styles';
import { Infos } from '../../infos';
import { AiOutlineSearch } from 'react-icons/ai';
import { ChatComponent } from '../../components/Chat';
import FAQContentToggle from '../../components/FaqContentToggler';
import './styles.css';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import socket from '../../services/socket';
import { RootState } from '../../redux/stores';
import { useSelector } from 'react-redux';
import { FaPlusCircle } from "react-icons/fa";
import MaisfaqContentToggle from '../../components/MaisfaqContentToggle';




interface FaqItem {
  id: number;
  duvida: string;
  resposta:string;
  // Adicione outras propriedades conforme necessário
}

export function FAQPage() {
  const { data: faq } = useQuery<FaqItem[]>('duvidas', () =>
    getfaq().then((response) => response?.data as FaqItem[]),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { user } = useSelector((state: RootState) => state.userReducer);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FaqItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };



  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);


  useEffect(() => {
    if (faq && faq.length > 0) {
      // Filter faq based on search term
      const filteredResults = faq.filter((faqItem) =>
        faqItem.duvida.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [faq, searchTerm]);





  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color:  #303b92;color:white">${match}</span>`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
  }, []);


  return (
    <PageContainer>
      <Navbar />
      <div className="title">
        <h1>FAQ</h1>
      </div>
      <input
          type="text"
          className="Search"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchInput}
          style={{border:' 2px solid #c0c0c0'}}
        />
      
       <MaisfaqContentToggle/>

      <div className="content">
        {searchResults.length > 0 ? (
          searchResults.map((faqItem, index) => (
            <div className="card" id={`faqItem-${index}`} key={faqItem.id}>
              <div className="box" />
              <div className="arquivos">
              <h2 dangerouslySetInnerHTML={{ __html: highlightText(faqItem.duvida, searchTerm) }} />
                <br />
                <FAQContentToggle faq={faqItem} />
              </div>
            </div>
          ))
        ) : (
          <div className="content">
            <div className="title-2">
              <h1>Não há dúvidas no momento</h1>
            </div>
          </div>
          
        )}
       
        <ChatComponent />
       
      </div>
      <Infos />
      <footer>
        <p className="p">© 2023 Óticas Mercadótica. Todos os direitos reservados</p>
      </footer>
    </PageContainer>
  );
}




