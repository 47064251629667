// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDVnYEBWKQiOrXU-lhMI3FdA0c2pk2Y114',
  authDomain: 'intranet-8dc5f.firebaseapp.com',
  projectId: 'intranet-8dc5f',
  storageBucket: 'intranet-8dc5f.appspot.com',
  messagingSenderId: '1060464402835',
  appId: '1:1060464402835:web:648e42c5f4d575d7ad4866',
  measurementId: 'G-TDFV6W6H89',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
