import { useQuery } from 'react-query';
import { Navbar } from '../../components/Navbar';
import { getArquivos } from '../../services/api';
import { PageContainer } from './styles';
import { Infos } from '../../infos';
import { useEffect, useState } from 'react';
import { CloudDownload } from '@mui/icons-material';
import { ChatComponent } from '../../components/Chat';
import { getUsuarios } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { USER } from '../../redux/actions/actions.type';
import { RootState } from '../../redux/stores';
import ArquivoContentToggle from '../../components/ArquivoContentToggle';
import './styles.css'
import socket from '../../services/socket';






interface ArquivoItem {
  id: number;
  acesso: string;
  nomePasta: string;
  date: string;
  nomeOriginalArquivo: string;

}

export function ArquivosPage() {
  const { data: arquivos } = useQuery('arquivos', () =>
    getArquivos().then((response) => response?.data), {




    refetchOnWindowFocus: false,

  });

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ArquivoItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { user } = useSelector((state: RootState) => state.userReducer);
  const userAccessLevel = user?.acesso;
  const [showPopup, setShowPopup] = useState(false);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentIndex(0); // Reset currentIndex when input changes
  };
  useEffect(() => {
    if (arquivos && arquivos.length > 0) {
      // Normalize a string removendo acentos e transformando para minúsculas
      const normalizeString = (str) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      const filteredResults = arquivos.filter((ArquivoItem) =>
        normalizeString(ArquivoItem.nomePasta).includes(normalizeString(searchTerm))
      );

      setSearchResults(filteredResults);
      setCurrentIndex(0); // Reset currentIndex when search results change
    }
  }, [arquivos, searchTerm]);



  //para o funcionamento do chat na página 
  useEffect(() => {
    socket.auth = { username: user.nome };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.close();
    };
  }, []);





  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<span style="background-color: white;color: #303b92;font-size:25px">${match}</span>`);
  };



  // Função para formatar a data no formato "dd/MM/yyyy"
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };


  // Ordenar os arquivos por data, do mais recente para o mais antigo
  const sortedArquivos = arquivos
    ? [...arquivos].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    : [];


  useEffect(() => {

    window.scrollTo(0, 0); // Role a página para o topo quando a página for carregada
  }, []);

  return (
    <PageContainer>
      <Navbar />

      <div className="title">
        <h1>Arquivos</h1>
      </div>
      <input
        type="text"
        className="Search"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleSearchInput}
        style={{ border: '2px solid #c0c0c0' }}
      />
      <div className="content">
        {searchResults.length > 0 ? (
          searchResults.map((arquivo) => (
            <div className="card" key={arquivo.id}>
              <div className="box" />
              <div key={arquivo.id} className="arquivos">
                <h2
                  dangerouslySetInnerHTML={{
                    __html:
                      (userAccessLevel === 'franqueado' && arquivo.acesso === 'franqueado') ||
                        (userAccessLevel === 'gerente' && (arquivo.acesso === 'franqueado/colaborador/gerente' || arquivo.acesso === 'gerente')) ||
                        (userAccessLevel === 'franqueado' && (arquivo.acesso === 'franqueado' || arquivo.acesso === 'franqueado/colaborador/gerente' || arquivo.acesso === 'gerente')) ||
                        (userAccessLevel === 'colaborador' && arquivo.acesso === 'franqueado/colaborador/gerente')
                        ? highlightText(arquivo.nomePasta, searchTerm)
                        : arquivo.acesso === 'gerente'
                          ? 'Pasta destinada ao gerente!'
                          : 'Pasta destinada ao franqueado!'
                  }}
                />

                <h5>{formatDate(new Date(arquivo.date))}</h5>

                <span>
                  contém {(userAccessLevel === 'gerente' && (arquivo.acesso === 'gerente' || arquivo.acesso === 'franqueado/colaborador/gerente')) ||
                    (userAccessLevel === 'franqueado' && (arquivo.acesso === 'franqueado' || arquivo.acesso === 'franqueado/colaborador/gerente' || arquivo.acesso === 'gerente')) ||
                    (userAccessLevel === 'colaborador' && arquivo.acesso === 'franqueado/colaborador/gerente')
                    ? arquivo.nomeOriginalArquivo.length - 1
                    : 'Ø'} arquivos
                </span>

                <ArquivoContentToggle
                  arquivo={arquivo}
                  userAccessLevel={userAccessLevel}
                  setShowPopup={setShowPopup}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="content">
            <div className="title-2">
              <h1>Não há arquivos no momento</h1>
            </div>
          </div>
        )}
      </div>


      <ChatComponent />
      {showPopup && (
        <div className='pop-up-container'>
          <div className="pop-up">
            <div className="pop-up-content">
              <p>Você não tem permissão para acessar este arquivo!</p>
            </div>
            <button onClick={() => setShowPopup(false)}>Fechar</button>
          </div>
        </div>
      )}
      <Infos />
      <footer >
        <p className='p'>© 2023  Óticas Mercadótica.  Todos os direitos reservados</p>
      </footer>

    </PageContainer>
  );
}










