import react from 'react'
import { AiFillInstagram, AiFillFacebook, AiFillYoutube, AiOutlineMail, AiOutlineClockCircle, AiFillLinkedin } from 'react-icons/ai'
import { BiSolidMap } from 'react-icons/bi'
import { BsFillTelephoneFill, BsWhatsapp } from 'react-icons/bs'
import './styles.css'





export function Infos() {

  const handleClick = (url) => {
    window.open(url)
  }


  return (
    <div className='footer'>
      <h1>FUNCIONAMENTO</h1>
      <h1 className='h1'>CONTATO</h1>

      <div className='endereço' onClick={() => handleClick("https://goo.gl/maps/9Rnu54DBFfmq7t3P8")}>
        <p > <BiSolidMap className='cor' />Matriz: Av. Crescêncio Silveira, nº 36.Centro . Vitória da Conquista - Bahia</p>
      </div>

      <div className='telefone' >
        <p> <BsFillTelephoneFill className='cor' /> (77)3424-6488</p>
      </div>

      <div className='wpp' onClick={() => handleClick("https://wa.me/77988270635")}>
        <p> <BsWhatsapp className='cor' /> (77)988270635</p>
      </div>

      <div className='email' onClick={() => handleClick("mailto:contato@oticasmercadotica.com.br")}>
        <p > < AiOutlineMail className='cor' /> contato@oticasmercadotica.com.br</p>
      </div>





      <div className='dias'>
        <p >Segunda à Sexta /   8:00h - 18:00h </p>
      </div>

      <div className='dia'>
        <p>Sabádo / 8:00h - 13:00h</p>
      </div>

      <div className='redes'>
        <p>Redes Sociais:
          <div className='redes-icon'>
          <div className='insta' onClick={() => handleClick("https://www.instagram.com/mercadotica/")}> <AiFillInstagram /> </div>
          <div className='face' onClick={() => handleClick("https://www.facebook.com/mercadotica/?locale=pt_BR")}>< AiFillFacebook /></div>
          <div className='you' onClick={() => handleClick("https://www.youtube.com/channel/UC5lbjH_75yHqeMiFL5PlbcQ")}>< AiFillYoutube /> </div>
          <div className='lin' onClick={() => handleClick("https://br.linkedin.com/company/mercadotica")}>< AiFillLinkedin /> </div>
          </div>
         
        </p>
      </div>







    </div >



  );


}






