import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import ReactModal from 'react-modal';
import Gallery from 'react-images';
import './styles.css'







function FotoContentToggle({ foto }) {
  const [containerVisible, setContainerVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);



  function toggleContainer() {
    setContainerVisible(!containerVisible);
    setSelectedImage('');
  }

  function selectImage(imageUrl, index) {
    setSelectedImage(imageUrl);
    setSelectedImageIndex(index);
    setContainerVisible(true);

    window.scrollTo({
      top: 1 ,
      behavior: 'smooth'
    });
    


  }



  const images = foto.urlArquivo
    .filter((url, index) => /\.(jpg|jpeg|png|gif|bmp)$/i.test(foto.nomeOriginalArquivo[index]))
    .map(url => ({ original: url, thumbnail: url }));

  const closeGallery = () => {
    setContainerVisible(true);
    setSelectedImage('');
  };



  return (
    <div>
      {containerVisible && (

        <div className="album">
          {foto.urlArquivo.map((url, index) => {
            const isImage = /\.(jpg|jpeg|png|gif|bmp)$/i.test(foto.nomeOriginalArquivo[index]);

            if (isImage) {
              return (
                <div key={index} className="image-container">
                  <img
                    className="foto"
                    src={url}
                    alt={`Imagem ${index}`}
                    onClick={() => selectImage(url, index)}
                  />
                </div>
              );
            }

            return null;
          })}
        </div>
      )}


      <ReactModal
        isOpen={containerVisible}
        onRequestClose={toggleContainer}
        contentLabel="Imagem em Destaque"
        className="modal"
        overlayClassName="overlay"
      >
        {selectedImage && (

          <div>
            <div className="background-overlay">
              <AiOutlineCloseCircle onClick={closeGallery} className='close' />
            </div>
            <div className='galeria'>
            <ImageGallery
              items={images}
              showThumbnails={false}
              showBullets={true}
              showIndex={true}
              startIndex={selectedImageIndex - 1}
             
            />
             </div>
          </div>
        )}
      </ReactModal>

      <button id="read" onClick={toggleContainer}>
        {containerVisible ? 'Ocultar fotos' : 'Ver fotos'}
      </button>
    </div>
  );
}

export default FotoContentToggle;