import styled from '@emotion/styled';

export const AdminPageContainer = styled.div<{ display?: string; padding?: number | string }>`
  width: 100%;
  height: 100vh;
  padding: ${({ padding }) => padding || '1rem'};
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
  overflow: none;
  background-color: #f5f7f9;
`;
