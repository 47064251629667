import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const BannerContainer = styled.div<{ ativo: boolean }>`
  width: 80%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  .image {
    
    width: 20%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      margin-top: -7%;
    }
  }
  .row {
    padding: 0 10px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .status {
      height: 50%;
      border-radius: 5px;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ ativo }) => (ativo ? '#00a65a' : '#dd4b39')};
      color: #fff;
    }
    .buttons {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      svg {
        cursor: pointer;
        color: #303b92;
        &:hover {
          transform: scale(1.04);
        transition: all 0.2s ease-in-out;
        &:hover {
          color: #111;
        }
      }
    }
  }
   }
`;
