/* eslint-disable jsx-a11y/label-has-associated-control */

import './styles.css';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ChangeEvent, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useQuery } from 'react-query';

import { AdminPageContainer } from '../../../components/AdminPageContainer';
import { LogoutComponent } from '../../../components/LogoutComponent';
import { getBanners } from '../../../services/api';
import { storage } from '../../../services/firebase';
import { client } from '../../../services/instance';
import { BannerContainer, Container } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores';

export function BannersPage() {
  const [progress, setProgress] = useState(0);
  const [link, setLink] = useState('');
  const [values, setValues] = useState({
    title: '',
    subtitle: '',
    status: 'ativo',
    local: 'iniciar',
  });
  const { notification } = useSelector((state: RootState) => state.notificationReducer);

  const { data: banners, refetch } = useQuery('banners', () => getBanners().then((response) => response?.data), {
    refetchOnWindowFocus: false,
  });



  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
     

      const storageRef = ref(storage, `banners/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          alert(error);
        },
      );
    }
  };

  useEffect(() => {
    document.title = `(${notification}) Intranet - Mercadotica`;
  }, [notification]);



  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as typeof e.target & {
      banner: { files: FileList };
    };
    const file = form.banner.files[0];

    if (!file) return;
    const storageRef = ref(storage, `banners/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          client.post('/banners/create', {
            ...values,

            imageURL: url,
          });
          alert('Banner cadastrado com sucesso!');
          refetch();
        });
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onDelete = (id: number) => {
    client.delete(`/banners/delete/${id}`).then((res) => {
      console.log(res);
      alert('Banner deletado com sucesso!');
      refetch();
    });
  };

  return (
    <AdminPageContainer padding="0px">
      <div className="main2">
        <div className="criar">
          <h1>Incluir</h1>
          <form onSubmit={onSubmit}>
            <div className="cadastro">
              <div className="row">
                <div className="input">
                  <label htmlFor="title">Título:</label>
                  <input
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange} />
                </div>
                <div className="input">
                  <label htmlFor="subtitle">Sub-Título:</label>
                  <input
                    type="text"
                    name="subtitle"
                    value={values.subtitle}
                    onChange={handleInputChange} />
                </div>
              </div>
              <div className="row">
                <div className="input">
                  <label htmlFor="status">Status:</label>
                  <select name="status" value={values.status} onChange={handleInputChange}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </div>

                <div className="input">
                  <label htmlFor="local">Página:</label>
                  <select name="local" value={values.local} onChange={handleInputChange}>
                    <option value="página inicial">página inicial</option>
                    <option value="página de  comunicados">página de comunicados</option>
                    <option value=" página de treinamentos">página de treinamento</option>
                    <option value="página de notícias">página de notícias</option>
                    <option value=" página de arquivos">página de arquivos</option>
                    <option value="página de faq">página de FAQ</option>
                    <option value="página de fotos">página de fotos</option>
                    <option value="página de videos">página de videos</option>

                  </select>
                </div>
              </div>



              <div className="input">
                <label htmlFor="link">Link:</label>
                <input type="text" name="link" value={link} onChange={(e) => setLink(e.target.value)} />
              </div>
              <div className="submit">
                <div className="input">
                  <label htmlFor="banner">Banner: </label>
                 
                </div>
                <input name="banner" type="file" onChange={onFileChange} />
                {progress > 0 && <progress value={progress} max="100" />}
                <button type="submit">Cadastrar </button>
              </div>
            </div>
          </form>
        </div>
        <div className="separator" />
        <div className="gerenciar">
          <h1>Gerenciar Banners</h1>
          <Container>
            {banners?.map((banner) => (
              <BannerContainer key={banner.id} ativo={Boolean(banner.status)} className='banner'>
                <div className="image">
                  <img src={banner.imageURL} alt="logo" />
                </div>
                <div className="row">



                  <h2>{banner.local}</h2>


                  <div className="status">
                    <div className={banner.status ? 'ativo' : 'inativo'}>{banner.status ? 'Ativo' : 'Inativo'}</div>
                  </div>
                  <div className="buttons">
                    <BsFillTrashFill size={20} onClick={() => onDelete(banner.id)} />
                   
                  </div>
                </div>
              </BannerContainer>
            ))}
          </Container>
        </div>
      </div>
    </AdminPageContainer>
  );
}
