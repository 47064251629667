import styled from '@emotion/styled';

export const ImageContainer = styled.div`
  width: 80%;
  height: 13%;
  margin: 0 auto;
  box-shadow: 0 0 10px #000;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  font-size: 1.5rem;

  &:hover {
    transform: scale(1.01);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .title {
    font-size: 1rem;
  }
  .buttons {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    svg {
      cursor: pointer;
      color: #303b92;
      transition: 0.4s;
      &:hover {
        transform: scale(1.02);
        color: #000;
      }
    }
  }
`;

